<template>



    <v-card width="1200">
        <v-toolbar color="grey darken-3" dark height="40">
              <v-spacer></v-spacer>
                <v-toolbar-title>Documento</v-toolbar-title>
              <v-spacer></v-spacer>
          </v-toolbar>
      <v-card-text class="mt-5">
        <v-row>
          <v-col cols="12" sm="6">
              <h2 for="">Frontal</h2>
              <img  v-img src="https://picsum.photos/id/11/500/300" alt="">
          

          </v-col>
          <v-col cols="12" sm="6">
              <h2 for="">Trasera</h2>
            <img  v-img src="https://picsum.photos/id/11/500/300" containt >

          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="red" @click="$emit('cerrar-modal')" dark>Cancelar</v-btn>
        <v-btn color="primary" @click="$emit('cerrar-modal')">Aceptar</v-btn>
      </v-card-actions>
    </v-card>
  

</template>

<script>
// import ModalImagen from "@/components/rtm-dia/ModalImagen"

export default {
  data: () => ({
    modal_imagen:false,
  }),
  components: {
    // ModalImagen
  },
  methods: {
      
  }
};
</script>

<style scoped>

</style>
