<template>
  <div>
    <v-container fluid>
      <v-card>
        <v-toolbar color="red darken-2" dark height="40" class="mt-n2">
          <v-spacer></v-spacer>
          <v-toolbar-title>PQRS</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-card-text style="text-align: center">
          <v-row class="mt-2">
            <v-col cols="1" class="d-flex align-center justify-center mr-n2">
              <div>
                <label>Todas las pqrs?</label>
                <div class="d-flex align-center justify-center mt-n3">
                  <v-switch v-model="todas_pqrs"> </v-switch>
                </div>
              </div>
            </v-col>
            <v-col cols="12" sm="3" md="2" lg="2">
              <label for="">Fecha inicial</label>
              <v-menu
                v-model="menu_fecha_inicial"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :disabled="todas_pqrs"
                    v-model="fecha_inicial"
                    @click:append="menu_fecha_inicial = true"
                    append-icon="mdi-calendar"
                    readonly
                    outlined
                    dense
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  locale="es"
                  v-model="fecha_inicial"
                  @input="menu_fecha_inicial = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" sm="3" lg="2">
              <label for="">Fecha final</label>
              <v-menu
                v-model="menu_fecha_final"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="fecha_final"
                    :disabled="todas_pqrs"
                    dense
                    outlined
                    append-icon="mdi-calendar"
                    @click:append="menu_fecha_final = true"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    :rules="[(v) => !!v || 'Campo requerido']"
                  ></v-text-field>
                </template>
                <v-date-picker
                  locale="es"
                  v-model="fecha_final"
                  :min="fecha_inicial"
                  @input="menu_fecha_final = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" sm="2" md="2" lg="2" class="mb-n8">
              <label>Tipo de trámite</label>
              <v-select
                :items="tipos_tramites"
                item-text="tipo"
                item-value="id"
                v-model="tipo_tramite"
                outlined
                dense
              ></v-select>
            </v-col>
            <v-col cols="12" sm="2" md="2" lg="2">
              <label>Medio de Respuesta</label>
              <v-select
                :items="medios_respuesta"
                item-text="medio_respuesta"
                item-value="id"
                v-model="medio_respuesta"
                outlined
                dense
              ></v-select>
            </v-col>
            <v-col cols="12" sm="1" md="1" lg="1">
              <label>N° Radicado</label>
              <v-text-field
                type="number"
                @keyup.enter="consultarPqrs"
                v-model="numero_radicado"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="1" class="d-flex align-center justify-center mb-2">
              <v-btn color="primary" @click="consultarPqrs">
                Buscar
                <v-icon class="ml-1"> mdi-magnify </v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-data-table
            v-if="!consultando_pqrs"
            class="mt-3"
            :headers="headers"
            no-data-text="No se encontraron resultados"
            :items="info_datatable"
            dense
          >
            <template v-slot:item.estado="{ item }">
              <div class="mt-1 mb-1">
                <v-chip :color="item.estado_pqrs_color">{{
                  item.estado_pqrs_texto
                }}</v-chip>
              </div>
            </template>

            <template v-slot:item.acciones="{ item }">
              <v-tooltip bottom v-if="item.estado_pqrs != 2">
                <!-- BOTON EXPORTAR DATOS EXCEL -->
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    @click="abrirModalSolucionPqrs(item)"
                    small
                    color="primary"
                    v-bind="attrs"
                    v-on="on"
                    ><v-icon>{{
                      $store.state.roles_hermes.includes("recepcion")
                        ? "mdi-account-check"
                        : "mdi-email-newsletter"
                    }}</v-icon>
                  </v-btn>
                </template>
                <span>{{
                  $store.state.roles_hermes.includes("recepcion")
                    ? "Asignar Funcionario"
                    : "Responder"
                }}</span>
              </v-tooltip>
            </template>
          </v-data-table>
          <v-col
            cols="12"
            v-else
            style="
              display: flex;
              align-items: center;
              justify-content: center;
              height: 200px;
            "
          >
            <v-spacer></v-spacer>
            <v-progress-circular
              :size="100"
              color="primary"
              indeterminate
            ></v-progress-circular>
            <v-spacer></v-spacer>
          </v-col>
        </v-card-text>
      </v-card>
      <v-dialog
        v-model="modal_formulario_solucion_pqrs"
        :width="$store.state.roles_hermes.includes('recepcion') ? 600 : 1200"
        persistent
      >
        <formularioSolucionPqrsVue
          v-if="modal_formulario_solucion_pqrs"
          :datos_pqrs="datos_pqrs_prop"
          @finalizar-pqrs="finalizarPqrs"
          @actualizar-datos-pqrs="actualizarDatosPqrs"
          @designar-datos-funcionario="designarDatosFuncionario"
          @cerrar-modal="modal_formulario_solucion_pqrs = false"
        />
      </v-dialog>
    </v-container>
  </div>
</template>
<script>
import reportePqrsService from "../services/reportePqrsService";
import formularioSolucionPqrsVue from "./formularioSolucionPqrs.vue";

export default {
  data: () => ({
    consultando_pqrs: false,

    todas_pqrs: true,

    fecha_inicial: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    menu_fecha_inicial: false,

    fecha_final: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),

    menu_fecha_final: false,

    tipos_tramites: [],
    tipo_tramite: 0,

    medios_respuesta: [],
    medio_respuesta: 0,

    numero_radicado: null,
    headers: [
      { text: "N° Radicado", value: "id" },
      { text: "Tipo de Trámite", value: "tipo_tramite_texto" },
      { text: "Medio de Respuesta ", value: "medio_respuesta_texto" },
      { text: "Documento Cliente", value: "documento" },
      { text: "Nombre Cliente", value: "nombre_completo" },
      { text: "Ciudad", value: "ciudad" },
      { text: "Telefono", value: "telefono" },
      { text: "Correo", value: "email" },
      { text: "Estado Pqrs", value: "estado" },
      { text: "Acciones", value: "acciones" },
    ],
    info_datatable: [],

    modal_formulario_solucion_pqrs: false,
    datos_pqrs_prop: {},
  }),
  components: {
    formularioSolucionPqrsVue,
  },
  async created() {
    this.$store.state.stateLoad = true;

    await reportePqrsService.getTiposPqrs().then((response) => {
      this.tipos_tramites = response.data;
      this.tipos_tramites.unshift({ tipo: "Todos", id: 0 });
    });

    await reportePqrsService.getMediosRespuesta().then((response) => {
      this.medios_respuesta = response.data;
      this.medios_respuesta.unshift({ medio_respuesta: "Todos", id: 0 });
    });

    this.consultarPqrs();

    this.$store.state.stateLoad = false;
  },
  methods: {
    async consultarPqrs() {
      this.consultando_pqrs = true;
      await reportePqrsService
        .consultarPqrs({
          todas_pqrs: this.todas_pqrs,
          fecha_inicial: this.fecha_inicial,
          fecha_final: this.fecha_final,
          tipo_tramite: this.tipo_tramite,
          medio_respuesta: this.medio_respuesta,
          numero_radicado: this.numero_radicado,
        })
        .then((response) => {
          this.info_datatable = response.data;
        });

      this.consultando_pqrs = false;
    },

    finalizarPqrs(data_formulario) {
      if (this.info_datatable.length > 0) {
        for (let index = 0; index < this.info_datatable.length; index++) {
          if (this.info_datatable[index].id == this.datos_pqrs_prop.id) {
            this.info_datatable[index].fecha_maxima_recibida =
              data_formulario.fecha_max_recibida;

            this.info_datatable[index].fecha_maxima_respuesta =
              data_formulario.fecha_max_respuesta;

            this.info_datatable[index].investigacion_realizada =
              data_formulario.investigacion_realizada;

            this.info_datatable[index].acciones_tomar =
              data_formulario.acciones_tomar;

            this.info_datatable[index].conclusiones =
              data_formulario.conclusiones;

            this.info_datatable[index].requiere_accion_correctiva_preventiva =
              data_formulario.requiere_accion_correctiva_preventiva;

            this.info_datatable[index].estado_pqrs_color = "#F44336";

            this.info_datatable[index].estado_pqrs = 2;

            this.info_datatable[index].estado_pqrs_texto = "Cerrada";
          }
        }
      }
    },

    actualizarDatosPqrs(data_formulario) {
      if (this.info_datatable.length > 0) {
        for (let index = 0; index < this.info_datatable.length; index++) {
          if (this.info_datatable[index].id == this.datos_pqrs_prop.id) {
            this.info_datatable[index].fecha_maxima_recibida =
              data_formulario.fecha_max_recibida;

            this.info_datatable[index].fecha_maxima_respuesta =
              data_formulario.fecha_max_respuesta;

            this.info_datatable[index].investigacion_realizada =
              data_formulario.investigacion_realizada;

            this.info_datatable[index].acciones_tomar =
              data_formulario.acciones_tomar;

            this.info_datatable[index].conclusiones =
              data_formulario.conclusiones;

            this.info_datatable[index].requiere_accion_correctiva_preventiva =
              data_formulario.requiere_accion_correctiva_preventiva;

            this.info_datatable[index].estado_pqrs_color = "#2196F3";

            this.info_datatable[index].estado_pqrs = 1;

            this.info_datatable[index].estado_pqrs_texto = "En Proceso";
          }
        }
      }
    },

    designarDatosFuncionario(data_formulario) {
      if (this.info_datatable.length > 0) {
        for (let index = 0; index < this.info_datatable.length; index++) {
          if (this.info_datatable[index].id == this.datos_pqrs_prop.id) {
            this.info_datatable[index].id_funcionario_designado =
              data_formulario.funcionario_designado_tratamiento;

            this.info_datatable[index].documento_funcionario_designado =
              data_formulario.documento_funcionario_designado;

            this.info_datatable[index].tipo_proceso_vinculado =
              data_formulario.tipo_proceso_vinculado;

            this.info_datatable[index].estado_pqrs_color = "#FFEB3B";

            this.info_datatable[index].estado_pqrs = 4;

            this.info_datatable[index].estado_pqrs_texto = "Usuario Designado";

            console.log(data_formulario);
          }
        }
      }
    },
    abrirModalSolucionPqrs(datos_pqrs) {
      this.datos_pqrs_prop = JSON.parse(JSON.stringify(datos_pqrs));
      this.modal_formulario_solucion_pqrs = true;
    },
  },
};
</script>
