<template>
  <div>
    <v-toolbar color="grey darken-3" dark height="40">
      <v-spacer></v-spacer>
      <v-toolbar-title>Datos de Compra</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
    <div class="pl-10 pr-10 mt-7 mb-5">
      <v-form ref="createPusharse">
        <v-row>
          <!-- MARCA -->
          <v-col cols="12" sm="3">
            <label>Provedor</label>
            <v-text-field
              outlined
              disabled
              dense
              v-model="nombreProveedor"
            ></v-text-field>
          </v-col>

          <!-- FECHA DE COMPRA -->
          <v-col cols="12" sm="3">
            <label>Fecha de compra</label>
            <v-menu
              v-model="menu_fecha_compra"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="date_compra"
                  outlined
                  dense
                  append-icon="mdi-calendar"
                  @click:append="menu_fecha_compra = true"
                  readonly
                  :rules="basicRules"
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="date_compra"
                @input="menu_fecha_compra = false"
                locale="es-CO"
              ></v-date-picker>
            </v-menu>
          </v-col>

          <!-- INSTALACION Y ENTREGA -->
          <v-col cols="12" sm="3">
            <label>fecha de instalacion</label>
            <v-menu
              v-model="menu_fecha_instalacion"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="date_instalacion"
                  outlined
                  dense
                  append-icon="mdi-calendar"
                  @click:append="menu_fecha_instalacion = true"
                  readonly
                  :rules="basicRules"
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="date_instalacion"
                @input="menu_fecha_instalacion = false"
                locale="es-CO"
              ></v-date-picker>
            </v-menu>
          </v-col>

          <!-- GARANTIA -->
          <v-col cols="12" sm="3">
            <label>Garantia</label>
            <v-menu
              v-model="menu_fecha_garantia"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="date_garantia"
                  outlined
                  dense
                  append-icon="mdi-calendar"
                  readonly
                  @click:append="menu_fecha_garantia = true"
                  :rules="basicRules"
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="date_garantia"
                @input="menu_fecha_garantia = false"
                locale="es-CO"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
      </v-form>

      <v-row>
        <!-- IMAGEN -->
        <v-col cols="12" sm="3">
          <label>Archivo Adicional</label>
          <v-file-input
            placeholder="Subir archivo"
            v-model="aditionalFile"
            prepend-inner-icon="mdi-upload"
            ref="addFile"
            @click:prepend-inner="open"
            accept="image/png, image/jpeg, application/pdf"
            prepend-icon=""
            outlined
            dense
          ></v-file-input>
        </v-col>
      
      </v-row>

      <v-row>
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>
        <v-col v-if="!edit">
          <v-btn
            class="ml-15 mt-n15"
            color="error"
            large
            :loading="loader"
            :disabled="loader"
            @click="crearCompra"
          >
            Finalizar hoja de vida
            <template v-slot:loader>
              <span class="custom-loader">
                <v-icon light>mdi-cached</v-icon>
              </span>
            </template>
          </v-btn>
        </v-col>

        <v-col v-else>
          <v-btn
            class="ml-15 mt-n15"
            color="error"
            large
            :loading="loader"
            :disabled="loader"
            @click="editarHoja"
          >
            Actualizar hoja de vida
            <template v-slot:loader>
              <span class="custom-loader">
                <v-icon light>mdi-cached</v-icon>
              </span>
            </template>
          </v-btn>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { mapFields } from "vuex-map-fields";
export default {
  name: "createPusharse",
  props: ["finishForm","editForm"],
  data: () => ({
    loader: null,
    menu_fecha_elaboracion: false,
    menu_fecha_compra: false,
    menu_fecha_instalacion: false,
    menu_fecha_garantia: false,
    basicRules: [(value) => !!value || "El campo es requerido."],
    edit:false,
  }),
  computed: {
    ...mapFields([
      "provider.nombreProveedor",
      "pusharse.date_compra",
      "pusharse.date_instalacion",
      "pusharse.date_garantia",
      "pusharse.aditionalFile",
      "date",
    ]),
  },
  methods: {
    /**Funcion que abre la carpeta de archivos al dar clic en el icono del input file */
    open() {
      this.$refs.addFile.$el.querySelector("input").click();
    },
    /**Funcion que finaliza la creacion de una hoja de vida */
    crearCompra() {
      this.finishForm();
    },
    /**Funcion que finaliza la edicion del segundo form editar datos equipo */
    editarHoja(){
      this.editForm();
    },
    /**Funcion que valida los campos del formulario */
    validateForm() {
      if (this.$refs.createPusharse.validate()) {
        return true;
      } else {
        return false;
      }
    },
    /**
     * Funcion que asigna los valores de los datos de compra en los inputs
     * @param {number} id id del equipo a editar
     * @param {object} pucharse datos de compra del equipo
     */
    editar(id,pucharse){
      this.idEditar = id;
      this.date_compra = pucharse.equipo_fecha_compra;
      this.date_instalacion = pucharse.equipo_fecha_instalacion
      this.date_garantia = pucharse.equipo_fecha_garantia

    },
    /**Funcion que limpia los campos del form */
    resetForm() {
      this.$refs.createPusharse.reset();
    },
  },
};
</script>

<style>
.v-file-input__text:hover {
  cursor: pointer;
}
</style>
