<template>
<v-card width="440">
    <v-toolbar class color="grey darken-3" dark height="40">
        <v-spacer></v-spacer>
        <v-toolbar-title>Enviar Mensaje</v-toolbar-title>
        <v-spacer></v-spacer>
    </v-toolbar>
    <v-card-text class="mt-4">
        <label for="">Seleccione el mensaje</label>
        <v-col cols="12">
            <v-select @change="selectMessage"  :items="mensajes" v-model="mensaje_seleccionado" item-text="descrip_sms" item-value="Id_sms"  outlined dense></v-select>
        </v-col>
        <label for="">Descripción del mensaje</label>
        <v-col cols="12">
            <v-textarea v-model="mensaje_seleccionado_descripcion" dense disabled filled outlined auto-grow></v-textarea>
        </v-col>
        <label for="">¿Estado del RTM?</label> SMS {{ programar_sms }}
        <v-col cols="12" class="mt-2">
            <v-radio-group v-model="radioGroup">
                <v-row class="mt-n10">
                    <v-radio class="ml-0" @click="habilitarProgramarSMS()" label="RTM Aprobado" color="blue" value="aprobado"></v-radio>
                    <v-radio @click="habilitarProgramarSMS()" class="ml-5 mt-n2" label="RTM Rechazado" color="blue" value="rechazado"></v-radio>
                </v-row>
            </v-radio-group>
        </v-col>
        <v-col cols="12" class="mt-n14 ml-n3" v-if="radioGroup == 'aprobado'">
            <v-checkbox label="Programar SMS" v-model="programar_sms"> </v-checkbox>
        </v-col>
    </v-card-text>
    <v-card-actions>
        <v-card-actions class="mt-n10">
            <v-btn @click="$emit(s)" class="ml-4" color="red" dark>Cancelar</v-btn>
            <v-btn @click="$emit('cerrar-modal')" color="primary">Enviar Mensaje</v-btn>
        </v-card-actions>
    </v-card-actions>
</v-card>
</template>

<script>
import GeneralService from '@/services/GeneralService.js'

export default {
    data: () => ({
        programar_sms: true,
        radioGroup: "aprobado",
        mostrar_programar_sms: false,
        mensajes: [],
        mensaje_seleccionado: null,
        mensaje_seleccionado_descripcion: null
    }),
    async created() {
        this.habilitarProgramarSMS();
        let sms = await GeneralService.getSms();
        this.mensajes = sms.data

    },
    methods: {
        habilitarProgramarSMS() {
            if (this.radioGroup === "rechazado") {
                this.programar_sms = false;
                this.mostrar_programar_sms = false;
            } else {
                this.programar_sms = true;
            }
        },
        selectMessage(){
          this.mensajes.forEach(element => {
            if(element.Id_sms == this.mensaje_seleccionado){
              this.mensaje_seleccionado_descripcion = element.mensaje
              return;
            }
          })
        }
    },
};
</script>

<style></style>
