<template>
       <v-card>
          <v-toolbar
            container
            color="grey darken-3"
            dark
            height="40"
          >
            <v-spacer></v-spacer>
            <v-toolbar-title>Editar Número</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-card-text class="mt-6">

            <h2>Cambiar número el celular de</h2> 
            <h3 class="mt-2"> {{nombre}}</h3>
                 
         
            <v-text-field type="number" dense outlined></v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="cyan" dark>OK</v-btn>
             <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
</template>

<script>

export default {
    props: ['nombre','celular'],
    data: () => ({

    }),

}
</script>

<style>

</style>