<template>
  <v-container>
    <v-row class="mt-n8" >
      <v-col cols="12">
        <h3>{{ id }} - {{ pregunta }}</h3>
      </v-col>
      <v-radio-group ref="radio" @change="$emit('agregar-valor',[$event,id])"  row class="mt-2 mb-4 ml-10 icono">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-radio
              color="red"
              value="1"
              class="icono"
              v-bind="attrs"
              v-on="on"     
              on-icon="mdi-emoticon-sad-outline"
              off-icon="mdi-emoticon-sad-outline"
            ></v-radio
          ></template>
          <span>Malo</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-radio
              color="deep-orange lighten-2"
              value="2"
              class="ml-lg-6"
              v-bind="attrs"
              v-on="on"
              on-icon="mdi-emoticon-neutral-outline"
              off-icon="mdi-emoticon-neutral-outline"
            ></v-radio>
          </template>
          <span>Regular</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-radio
              color="light-green lighten-1"
              value="3"
              class="ml-lg-6"
              v-bind="attrs"
              v-on="on"
              on-icon="mdi-emoticon-happy-outline"
              off-icon="mdi-emoticon-happy-outline"
            ></v-radio>
          </template>
          <span>Bueno</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-radio
              v-bind="attrs"
              v-on="on"
              style="font-size: 140px !important"
              color="green"
              class="ml-lg-6"
              value="4"
              on-icon="mdi-emoticon-excited-outline"
              off-icon="mdi-emoticon-excited-outline"
            ></v-radio>
          </template>
          <span>Excelente</span>
        </v-tooltip>
      </v-radio-group>
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: ["pregunta", "id", "valor"],
  data: () => ({}),
  created() {
  
  },
  methods: {
    reset(){
      this.$refs.radio.lazyValue = undefined
    }
  }

};
</script>

<style scoped></style>
