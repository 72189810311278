import axios from "axios";

// Creamos la base de las peticiones, con los datos necesarios
const apiClient = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL + "general",
  headers: {
      
  }
});



// Exportamos los métodos para las peticiones
export default {
  getTablesClient() {
    return apiClient.get("/tables-client");
  },
  getCitiesByDepartment(param) {
    return apiClient.get(`/citiesByDepartment${param? '/'+param: ''}`);
  },
  getReferidos(){
    return apiClient.get("/referidos");
  },
  getSms(){
    return apiClient.get("/sms");
  },
  
};