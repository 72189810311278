<template>
  <v-form ref="createEquip" lazy-validation>
    <v-row>
      <v-col cols="4" sm="3">
        <v-row>
          <v-col>
            <!-- <v-img contain max-height="240" max-width="300" :src="fuenteImagen">
            </v-img> -->
            <img :src="fuenteImagen" style="max-height:240px;max-width:300px;">
          </v-col>
          <!-- IMAGEN -->
          <v-col cols="11" class="ml-2">
            <v-file-input
              @change="cargarImagen()"
              placeholder="Subir imagen"
              v-model="imagenEquipo"
              prepend-inner-icon="mdi-upload"
              accept="image/png, image/jpeg"
              prepend-icon=""
              ref="file"
              @click:prepend-inner="open"
              outlined
              dense
            ></v-file-input>
          </v-col>
        </v-row>

        <v-row> </v-row>
      </v-col>
      <v-divider vertical></v-divider>
      <v-col cols="8" sm="9" class="mt-5">
        <v-row>
          <!-- NOMBRE -->
          <v-col cols="12" sm="4" class="mb-n6">
            <label>Nombre <span style="color: red">*</span></label>
            <v-text-field
              outlined
              :disabled="edit"
              dense
              :rules="basicRules"
              v-model="nombreEquipo"
            ></v-text-field>
          </v-col>

          <!-- UBICACION -->
          <v-col cols="12" sm="4" class="mb-n6">
            <label>Ubicación <span style="color: red">*</span></label>
            <v-autocomplete
              v-model="ubicacionEquipo"
              :items="ubicaciones"
              placeholder="Seleccione una opcion"
              item-text="ubicacion"
              :rules="selectRules"
              item-value="id_ubicacion"
              disable-lookup
              outlined
              dense
            >
            </v-autocomplete>
          </v-col>

          <!-- MARCA -->
          <v-col cols="12" sm="4" class="mb-n6">
            <label
              >Marca <span style="color: red">*</span
              ><v-btn
                v-show="crearMarca == false"
                class="mt-n1"
                x-small
                text
                color="success"
                @click="crearMarca = true"
                >[+ AGREGAR ]</v-btn
              >
              <v-btn
                v-show="crearMarca"
                class="mt-n1"
                x-small
                text
                color="success"
                @click="crearNuevaMarca()"
                >[+ GUARDAR ]</v-btn
              >
              <v-btn
                v-show="crearMarca"
                class="mt-n1"
                x-small
                text
                color="red"
                @click="crearMarca = false"
                >[ CANCELAR ]</v-btn
              >
            </label>
            <v-autocomplete
              v-model="marcaEquipo"
              v-show="crearMarca == false"
              :items="marcas"
              placeholder="Seleccione una opcion"
              item-text="marca"
              :rules="selectRules"
              item-value="id_marca"
              disable-lookup
              outlined
              dense
            >
            </v-autocomplete>
            <v-text-field
              outlined
              placeholder="Marca nueva"
              v-show="crearMarca == true"
              dense
              v-model="marcaNew"
            ></v-text-field>
          </v-col>
        </v-row>

        <!-- FUNCION -->
        <v-row>
          <v-col class="mb-n6">
            <label>Función</label>
            <v-textarea
              outlined
              height="100"
              v-model="funcionEquipo"
            ></v-textarea>
          </v-col>
        </v-row>

        <v-row>
          <!-- MODELO -->
          <v-col cols="12" sm="4" class="mb-n6">
            <label>Modelo <span style="color: red">*</span></label>
            <v-text-field
              outlined
              dense
              v-model="modeloEquipo"
              :rules="basicRules"
            ></v-text-field>
          </v-col>

          <!-- SERIAL -->
          <v-col cols="12" sm="4" class="mb-n6">
            <label>Serial <span style="color: red">*</span></label>
            <v-text-field
              outlined
              :disabled="edit"
              dense
              v-model="serialEquipo"
              :rules="basicRules"
            ></v-text-field>
          </v-col>

          <!-- ID -->
          <v-col cols="12" sm="4" class="mb-n6">
            <label>ID <span style="color: red">*</span></label>
            <v-text-field
              outlined
              dense
              v-model="codigoEquipo"
              :rules="basicRules"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <!-- CALIBRACION -->
          <v-col cols="12" sm="4" class="mb-n6">
            <label>Calibracion <span style="color: red">*</span></label>
            <v-select
              :items="calibracion"
              v-model="calibracionEquipo"
              :menu-props="{ top: true, offsetY: true }"
              placeholder="Seleccione una opcion"
              :rules="selectRules"
              dense
              outlined
            ></v-select>
          </v-col>

          <!-- TIPO EQUIPO -->
          <v-col cols="12" sm="4" class="mb-n6">
            <label>Tipo de Equipo <span style="color: red">*</span></label>
            <v-autocomplete
              v-model="tipoEquipo"
              :items="tiposE"
              placeholder="Seleccione una opcion"
              item-text="tipo"
              :rules="selectRules"
              item-value="id_tipo"
              disable-lookup
              outlined
              dense
            >
            </v-autocomplete>
          </v-col>

          <v-col cols="12" sm="4" class="mb-n6">
            <label>Codigo Hoja<span style="color: red">*</span></label>
            <v-autocomplete
              v-model="codigoDocumentoEquipo"
              :items="file_codes"
              placeholder="Seleccione una opcion"
              item-text="codigo_documento"
              :rules="selectRules"
              @change="selectFileCode"
              item-value="id_codigo_documento"
              disable-lookup
              outlined
              dense
            >
            </v-autocomplete>
          </v-col>

          <v-col cols="12" sm="4" class="mb-n6">
            <label>Version Hoja <span style="color: red">*</span></label>
            <v-autocomplete
              v-model="versionDocumentoEquipo"
              :items="file_versions"
              :disabled="version_active"
              placeholder="Seleccione una opcion"
              item-text="version"
              :rules="selectRules"
              item-value="id_version_documento"
              disable-lookup
              outlined
              dense
            >
            </v-autocomplete>
          </v-col>
          <v-spacer></v-spacer>
          <v-col sm="4" class="mt-5" v-if="!edit">
            <v-btn
              block
              class="success"
              :loading="myloader"
              :disabled="myloader"
              large
              color="info"
              @click="crearEquipo"
            >
              Crear Equipo
              <template v-slot:loader>
                <span class="custom-loader">
                  <v-icon light>mdi-cached</v-icon>
                </span>
              </template>
            </v-btn>
          </v-col>

          <v-col sm="4" class="mt-5" v-if="edit">
            <v-btn
              block
              class="success"
              :loading="myloader"
              :disabled="myloader"
              large
              color="info"
              @click="editarEquipo"
            >
              Actualizar
              <template v-slot:loader>
                <span class="custom-loader">
                  <v-icon light>mdi-cached</v-icon>
                </span>
              </template>
            </v-btn>
          </v-col>
        </v-row>

        <v-row>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
        </v-row>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import { mapFields } from "vuex-map-fields";

export default {
  name: "CreateDevice",
  props: ["creado", "equipoEditado"],
  data: () => ({
    myloader: null,
    calibracion: ["SI", "NO"],
    crearMarca: false,
    marcaNew: "",
    edit: false,
    version_active: true,
    codigoDocumentoEquipo: "",
    file_versions: [],
    versionDocumentoEquipo: "",
    nombreEquipo: "",
    ubicacionEquipo: "",
    imagenEquipo: [],
    funcionEquipo: "",
    idEditar: "",
    equipoEditar: "",
    marcaEquipo: "",
    modeloEquipo: "",
    serialEquipo: "",
    codigoEquipo: "",
    calibracionEquipo: "",
    tipoEquipo: "",
    dafaultImg: require("@/assets/default.jpg"),
    fuenteImagen: require("@/assets/default.jpg"),
    basicRules: [(value) => !!value || "El campo es requerido."],
    selectRules: [(value) => !!value || "Debe seleccionar una opcion."],
    
  }),
  computed: {
    ...mapState({
      ubicaciones: "ubicaciones",
      marcas: "marcas",
      tiposE: "tiposE",
      file_codes: "file_codes",
      versions: "versions",
    }),

    ...mapFields({
      device_id: "device_id",
    }),
  },
  methods: {
    /** Funcion que abre la carpeta de archivos al dar click en el icono */
    open() {
      this.$refs.file.$el.querySelector("input").click();
    },
    /**
     * Funcion que recibe y asigna los valores a los campos de un equipo a editar
     * 
     * @param {object} equipo datos del equipo a editar
     */
    editar(equipo) {
   
      this.equipoEditar = equipo;
      this.imagenEquipo = [];
      this.idEditar = equipo.id_equipo;
      this.nombreEquipo = equipo.equipo;
      this.ubicacionEquipo = equipo.id_ubicacion;
      this.marcaEquipo = equipo.id_marca;
      this.funcionEquipo = equipo.equipo_funcion == "null" ? "" : equipo.equipo_funcion;
      this.modeloEquipo = equipo.equipo_modelo;
      this.serialEquipo = equipo.equipo_serial;
      this.codigoEquipo = equipo.equipo_codigo;
      this.calibracionEquipo = equipo.equipo_calibracion;
      this.tipoEquipo = equipo.id_tipo;
      this.codigoDocumentoEquipo = equipo.id_codigo_documento;
      this.version_active = false;
      this.selectFileCode();
      this.versionDocumentoEquipo = equipo.id_version_documento;
      if(equipo.src != null){
        this.fuenteImagen = this.fuenteImagen =
        "data: " + equipo.src.extension + ";base64," + equipo.src.file;
      }else{
        this.fuenteImagen = this.dafaultImg;
      }
      
    },
    /**Funciones que actualizan el id en el store y agregan una marca nueva al store */
    ...mapMutations(["updateIdDevice", "agregarMarca"]),

    /**Funcion que permite el autocompletado de el select versiones_documento */
    selectFileCode() {
      if (this.codigoDocumentoEquipo != "") {
        this.version_active = false;
        var resultado = this.versions.find(
          (code) => code.id_codigo_documento === this.codigoDocumentoEquipo
        );
        this.file_versions.push(resultado);
      } else {
        this.version_active = true;
      }
    },

    /**Funcion que carga la vista previa de la imagen seleccionada */
    cargarImagen() {
      if ((this.imagenEquipo == null) | Array.isArray(this.imagenEquipo)) {
        this.fuenteImagen = this.dafaultImg;
      } else {
        let reader = new FileReader();

        reader.onload = (e) => {
          this.fuenteImagen = e.target.result;
        };
        reader.readAsDataURL(this.imagenEquipo);
      }
    },

    /** Funcion que permite agregar una nueva marca al select marcas */
    crearNuevaMarca() {
      services.createBrand({ brand: this.marcaNew }).then((response) => {
        this.crearMarca = false;
        this.agregarMarca({ id_marca: response.data, marca: this.marcaNew });
        this.marcaEquipo = response.data;
        this.marcaNew = "";
      });
    },

    /**Funcion que permite la creacion de un nuevo equipo */
    crearEquipo() {
      if (this.$refs.createEquip.validate()) {
        this.myloader = true;
        const data = new FormData();
        data.append("name", this.nombreEquipo);
        data.append("ubication_id", this.ubicacionEquipo);
        data.append("image", this.imagenEquipo);
        data.append("function", this.funcionEquipo);
        data.append("brand", this.marcaEquipo);
        data.append("model", this.modeloEquipo);
        data.append("serial", this.serialEquipo);
        data.append("code", this.codigoEquipo);
        data.append("calibration", this.calibracionEquipo);
        data.append("device_type", this.tipoEquipo);
        data.append("file_code_id", this.codigoDocumentoEquipo);
        data.append("file_version_id", this.versionDocumentoEquipo);

        services
          .createDevice(data)
          .then((response) => {
            console.log(response.data);
            this.myloader = false;
            if (Number.isInteger(response.data)) {
              this.$swal
                .fire({
                  title: "Equipo creado",
                  icon: "success",
                  timer: 2000,
                  allowEscapeKey: false,
                  allowOutsideClick: false,
                  allowEnterKey: false,
                  position: "center",
                  showConfirmButton: false,
                  confirmButtonText: "Yes, delete it!",
                })
                .then(() => {
                  this.updateIdDevice(response.data);
                  this.creado("2");
                });
            } else {
              this.loader = false;
              this.$swal.fire({
                title: "Error en DB vuelva e intente",
                icon: "error",
                timer: 2000,
                allowEscapeKey: false,
                allowOutsideClick: false,
                allowEnterKey: false,
                position: "center",
                showConfirmButton: false,
                confirmButtonText: "Yes, delete it!",
              });
            }
          })
          .catch((e) => {
            console.log("Error al BD" + e);
          });
      } else {
        this.$swal.fire({
          title: "Tienes que llenar todos los campos marcados",
          icon: "error",
          timer: 2000,
          allowEscapeKey: false,
          allowOutsideClick: false,
          allowEnterKey: false,
          position: "center",
          showConfirmButton: false,
          confirmButtonText: "Yes, delete it!",
        });
      }
    },

    /**Funcion que permite actualizar los datos de un equipo */
    editarEquipo() {
      if (this.$refs.createEquip.validate()) {
        this.myloader = true;

        const data = new FormData();
        data.append("id", this.idEditar);
        data.append("name", this.nombreEquipo);
        data.append("ubication_id", this.ubicacionEquipo);
        if (Array.isArray(this.imagenEquipo) ) {
          if(this.equipoEditar["equipo_imagen"] == null){
             data.append("image", '');
          }else{
            var nombreImagen = this.equipoEditar["equipo_imagen"];
             data.append("image", nombreImagen);
          }
      
        }else if(this.imagenEquipo == null){
          data.append("image", '');
        }
         else {
          var imagen = this.imagenEquipo;
          var nombreImagen = "images/" + this.imagenEquipo["name"];
          data.append("image", imagen);
        }
        data.append("function", this.funcionEquipo);
        data.append("brand", this.marcaEquipo);
        data.append("model", this.modeloEquipo);
        data.append("serial", this.serialEquipo);
        data.append("code", this.codigoEquipo);
        data.append("calibration", this.calibracionEquipo);
        data.append("device_type", this.tipoEquipo);
        data.append("file_code_id", this.codigoDocumentoEquipo);
        data.append("file_version_id", this.versionDocumentoEquipo);
        services.editDevice(data).then((response) => {
          this.myloader = false;
          if (response.data['respuesta'] == true) {
            this.$swal.fire({
              title: "Equipo Actualizado",
              icon: "success",
              timer: 2000,
              allowEscapeKey: false,
              allowOutsideClick: false,
              allowEnterKey: false,
              position: "center",
              showConfirmButton: false,
              confirmButtonText: "Yes, delete it!",
            });
            for (var i = 0; i < this.ubicaciones.length; i++) {
              if (this.ubicacionEquipo == this.ubicaciones[i]["id_ubicacion"]) {
                var ubicacion = this.ubicaciones[i]["ubicacion"];
              }
            }
            this.equipoEditado({
              id_equipo: this.idEditar,
              equipo: this.nombreEquipo,
              equipo_imagen: nombreImagen,
              ubicacion: ubicacion,
              equipo_funcion: this.funcionEquipo,
              id_ubicacion: this.ubicacionEquipo,
              id_marca: this.marcaEquipo,
              equipo_modelo: this.modeloEquipo,
              equipo_serial: this.serialEquipo,
              equipo_codigo: this.codigoEquipo,
              id_tipo: this.tipoEquipo,
              src:response.data.src,
              equipo_calibracion: this.calibracionEquipo,
              id_codigo_documento: this.codigoDocumentoEquipo,
              id_version_documento: this.versionDocumentoEquipo,
            });
          }
        });
      } else {
        console.log("le falta");
      }
    },
    /**Funcion que limpia los campos del formulario y carga la imagen por default */
    resetForm() {
      this.$refs.createEquip.reset();
      this.fuenteImagen = this.dafaultImg;
    },
  },
};
</script>

<style>
.v-file-input__text:hover {
  cursor: pointer;
}
.v-image__image {
  max-width: 100%;
  background-size: cover;
  height: auto;
}
</style>
