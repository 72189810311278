<template>
  <v-card>
    <v-toolbar color="blue darken-4" dark height="40">
      <v-spacer></v-spacer>
      <v-toolbar-title class="mr-n16"
        >Solucion {{ datos_pqrs.tipo_tramite_texto }} - Radicado N°{{
          datos_pqrs.id
        }}</v-toolbar-title
      >
      <v-spacer></v-spacer>
      <v-btn
        :disabled="guardando_solucion_pqrs || cerrando_pqrs"
        small
        color="red"
        dark
        @click="$emit('cerrar-modal')"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text v-if="!cargando_datos_vista">
      <v-form ref="formularioSolucionPqrs">
        <v-row class="mt-5">
          <v-col
            cols="12"
            sm="4"
            md="4"
            lg="4"
            class="mb-n4"
            v-if="$store.state.roles_hermes.includes('recepcion')"
          >
            <label>Proceso vinculado</label>
            <v-select
              :rules="[(v) => !!v || 'Campo requerido']"
              v-model="data_formulario.tipo_proceso_vinculado"
              :items="procesos_vinculados"
              item-text="tipo_proceso_vinculado"
              item-value="id"
              outlined
              dense
            ></v-select>
          </v-col>

          <v-col
            cols="12"
            sm="8"
            md="8"
            lg="8"
            class="mb-n4"
            v-if="$store.state.roles_hermes.includes('recepcion')"
          >
            <label>Funcionario designado para dar tratamiento</label>
            <v-autocomplete
              v-model="data_formulario.funcionario_designado_tratamiento"
              :items="resultados_busqueda_usuarios"
              :search-input.sync="search_usuario"
              :loading="cargando_resultados_usuarios"
              no-data-text="No se encontraron resultados"
              @keyup="timeOutBusqueda"
              outlined
              dense
            ></v-autocomplete>
          </v-col>

          <v-col
            cols="12"
            sm="3"
            md="3"
            lg="3"
            v-if="!$store.state.roles_hermes.includes('recepcion')"
          >
            <label for="">Fecha maxima excuso recibida</label>
            <v-menu
              v-model="menu_fecha_max_recibida"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="data_formulario.fecha_max_recibida"
                  :rules="[(v) => !!v || 'Campo requerido']"
                  @click:append="menu_fecha_max_recibida = true"
                  append-icon="mdi-calendar"
                  readonly
                  outlined
                  dense
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                locale="es"
                v-model="data_formulario.fecha_max_recibida"
                :min="fecha_actual"
                @input="menu_fecha_max_recibida = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col
            cols="12"
            sm="3"
            md="3"
            lg="3"
            v-if="!$store.state.roles_hermes.includes('recepcion')"
          >
            <label for="">Fecha maxima dar respuesta</label>
            <v-menu
              v-model="menu_fecha_max_respuesta"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="data_formulario.fecha_max_respuesta"
                  @click:append="menu_fecha_max_respuesta = true"
                  :rules="[(v) => !!v || 'Campo requerido']"
                  append-icon="mdi-calendar"
                  readonly
                  outlined
                  dense
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                locale="es"
                v-model="data_formulario.fecha_max_respuesta"
                :min="fecha_actual"
                @input="menu_fecha_max_respuesta = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>

        <div v-if="!$store.state.roles_hermes.includes('recepcion')">
          <h3>Descripcion</h3>
          <v-textarea
            readonly
            :rules="[(v) => !!v || 'Campo requerido']"
            v-model="data_formulario.descripcion"
            class="mt-3"
            height="100"
            outlined
          ></v-textarea>
          <h3>Investigación realizada</h3>
          <v-textarea
            :rules="[(v) => !!v || 'Campo requerido']"
            v-model="data_formulario.investigacion_realizada"
            class="mt-3"
            height="100"
            outlined
          ></v-textarea>
          <h3>Acciones a tomar</h3>
          <v-textarea
            v-model="data_formulario.acciones_tomar"
            :rules="[(v) => !!v || 'Campo requerido']"
            class="mt-3"
            height="100"
            outlined
          ></v-textarea>
          <h3>Conclusiones</h3>
          <v-textarea
            v-model="data_formulario.conclusiones"
            :rules="[(v) => !!v || 'Campo requerido']"
            class="mt-3"
            height="100"
            outlined
          ></v-textarea>
          <v-row
            ><v-col cols="12" sm="3" md="3" lg="3">
              <label>Evidencias (Si aplica)</label>
              <v-file-input
                v-model="data_formulario.evidencias"
                prepend-icon=""
                prepend-inner-icon="mdi-upload"
                ref="file"
                placeholder="Seleccione un archivo"
                @click:prepend-inner="
                  $refs.file.$el.querySelector('input').click()
                "
                multiple
                outlined
                chips
                dense
              ></v-file-input>
            </v-col>
            <v-col cols="12" sm="4" md="4" lg="4">
              <label>¿Amerita una acción correctiva o preventiva?</label>
              <div class="d-flex align-center justify-center mt-n3 ml-n16">
                <v-switch
                  label="si"
                  v-model="
                    data_formulario.requiere_accion_correctiva_preventiva
                  "
                ></v-switch>
              </div>
            </v-col>
          </v-row>
        </div>
      </v-form>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          :disabled="cerrando_pqrs"
          @click="guardarSolucionPqrs"
          :loading="guardando_solucion_pqrs"
          >Guardar</v-btn
        >
        <v-btn
          color="red"
          class="white--text"
          :disabled="guardando_solucion_pqrs"
          :loading="cerrando_pqrs"
    
          v-if="!$store.state.roles_hermes.includes('recepcion')"
          @click="finalizarPqrs"
          >Finalizar Pqrs</v-btn
        >
      </v-card-actions>
    </v-card-text>
    <v-card-text v-else class="d-flex justify-center align-center">
      <v-progress-circular
        class="mt-4"
        :size="100"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </v-card-text>
  </v-card>
</template>

<script>
import reportePqrsService from "../services/reportePqrsService";
export default {
  props: ["datos_pqrs"],
  data: () => ({
    cerrando_pqrs: false,
    guardando_solucion_pqrs: false,
    cargando_datos_vista: false,

    data_formulario: {
      tipo_proceso_vinculado: null,
      funcionario_designado_tratamiento: null,
      descripcion: null,
      investigacion_realizada: null,
      acciones_tomar: null,
      conclusiones: null,
      evidencias: [],
      requiere_accion_correctiva_preventiva: false,
      fecha_max_recibida: null,
      fecha_max_respuesta: null,
      documento_funcionario_designado: null,
    },

    fecha_actual: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),

    procesos_vinculados: [],

    search_usuario: "",

    resultados_busqueda_usuarios: [],
    cargando_resultados_usuarios: false,

    menu_fecha_max_recibida: false,

    menu_fecha_max_respuesta: false,
  }),
  async created() {
    this.cargando_datos_vista = true;
    await reportePqrsService.getTiposProcesosVinculados().then((response) => {
      this.procesos_vinculados = response.data;
    });

    await this.asignarDatosPqrs();
    this.cargando_datos_vista = false;
  },
  watch: {
    data_formulario: {
      handler(newVal) {
        if (this.resultados_busqueda_usuarios.length > 0) {
          if (this.data_formulario.funcionario_designado_tratamiento != null) {
            if (this.resultados_busqueda_usuarios.length > 0) {
              this.resultados_busqueda_usuarios.forEach((element) => {
                if (
                  element.value ==
                  this.data_formulario.funcionario_designado_tratamiento
                ) {
                  this.data_formulario.documento_funcionario_designado =
                    element.documento;
                }
              });
            }
          }
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    /**Funcion que llama a la funcion de buscaqueda de usuaios despues de un segundo */
    timeOutBusqueda() {
      this.cargando_resultados_usuarios = true;

      let setTimeoutBuscador = "";
      clearTimeout(setTimeoutBuscador);

      setTimeoutBuscador = setTimeout(() => {
        this.buscarUsuario();
      }, 1000);
    },
    /**Funcion que llama a la funcion de buscaqueda de usuaios despues de un segundo */
    timeOutBusqueda() {
      this.cargando_resultados_usuarios = true;

      let setTimeoutBuscador = "";
      clearTimeout(setTimeoutBuscador);

      setTimeoutBuscador = setTimeout(() => {
        this.buscarUsuario();
      }, 1000);
    },
    /**Funcion que busca un usuario por su documento o nombre para el select de funcionario designado */
    async buscarUsuario(direct_search = null) {
      this.resultados_busqueda_usuarios = [];
      if (direct_search != null) {
        this.search_usuario = direct_search;
      }
      if (this.search_usuario.length > 0) {
        await reportePqrsService
          .buscarUsuario(this.search_usuario)
          .then((response) => {
            if (response.data.length > 0) {
              for (let index = 0; index < response.data.length; index++) {
                this.resultados_busqueda_usuarios.push({
                  text:
                    response.data[index].document +
                    " - " +
                    response.data[index].name.toUpperCase(),
                  value: response.data[index].id,
                  documento: response.data[index].document,
                });
              }
            }
            this.cargando_resultados_usuarios = false;
          });
      }
    },

    async finalizarPqrs() {
      if (this.$refs.formularioSolucionPqrs.validate()) {
        this.$swal
          .fire({
            title: "Estas seguro de finalizar la PQRS?",
            text: "Se enviara un correo al emisor con todos los datos y evidencias que fueron anexados durante el proceso y no se podra modificar ninguno de los campos despues de esto",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            cancelButtonText: "Cancelar",
            confirmButtonText: "Confirmar",
          })
          .then(async (result) => {
            if (result.isConfirmed) {
              this.cerrando_pqrs = true;

              let data = new FormData();

              data.append(
                "fecha_maxima_recibida",
                this.data_formulario.fecha_max_recibida
              );
              data.append(
                "fecha_maxima_respuesta",
                this.data_formulario.fecha_max_respuesta
              );
              data.append(
                "descripcion",
                this.data_formulario.descripcion == null
                  ? ""
                  : this.data_formulario.descripcion
              );
              data.append(
                "investigacion_realizada",
                this.data_formulario.investigacion_realizada == null
                  ? ""
                  : this.data_formulario.investigacion_realizada
              );
              data.append(
                "acciones_tomar",
                this.data_formulario.acciones_tomar == null
                  ? ""
                  : this.data_formulario.acciones_tomar
              );
              data.append(
                "conclusiones",
                this.data_formulario.conclusiones == null
                  ? ""
                  : this.data_formulario.conclusiones
              );

              data.append(
                "require_accion_correctiva_preventiva",
                this.data_formulario.requiere_accion_correctiva_preventiva ==
                  false
                  ? 0
                  : 1
              );
              data.append(
                "numero_evidencias",
                this.data_formulario.evidencias.length
              );

              //Se valida e itera sobre estas referencias para agregarlas al objeto formdata
              if (this.data_formulario.evidencias.length > 0) {
                for (
                  let index = 0;
                  index < this.data_formulario.evidencias.length;
                  index++
                ) {
                  //Se agregan los archivos con el prefijo de evidencia_numeroIndex para ser identificadas en el backend de manera mas facil
                  data.append(
                    "evidencia_" + index,
                    this.data_formulario.evidencias[index]
                  );
                  //
                }
              }
              data.append("id_pqrs", this.datos_pqrs.id);
              data.append("documento", this.datos_pqrs.documento);
              data.append("correo", this.datos_pqrs.email);
              data.append("nombre_cliente", this.datos_pqrs.nombre_completo);
              data.append("medio_respuesta", this.datos_pqrs.medio_respuesta);

              await reportePqrsService
                .finalizarPqrs(data)
                .then((response) => {
                  if (response.data == 1) {
                    this.$swal
                      .fire({
                        title: "Pqrs Finalizada",
                        icon: "success",
                        allowEscapeKey: false,
                        allowOutsideClick: false,
                        allowEnterKey: false,
                        position: "center",
                        showConfirmButton: true,
                        confirmButtonText: "Ok",
                      })
                      .then(() => {
                        this.$emit("finalizar-pqrs", this.data_formulario);
                        this.$emit("cerrar-modal");
                      });
                  } else {
                    this.$swal.fire({
                      title: "Error al finalizar la pqrs",
                      icon: "error",
                      allowEscapeKey: false,
                      allowOutsideClick: false,
                      allowEnterKey: false,
                      position: "center",
                      showConfirmButton: true,
                      confirmButtonText: "Ok",
                    });
                  }
                })
                .catch((error) => {
                  console.log(error);
                  this.$swal.fire({
                    title: "Error al finalizar la pqrs",
                    icon: "error",
                    allowEscapeKey: false,
                    allowOutsideClick: false,
                    allowEnterKey: false,
                    position: "center",
                    showConfirmButton: true,
                    confirmButtonText: "Ok",
                  });
                });

              this.cerrando_pqrs = false;
            }
          });
      }
    },

    guardarSolucionPqrs() {
      if (
        this.data_formulario.funcionario_designado_tratamiento != null &&
        this.search_usuario != null
      ) {
        if (!this.$store.state.roles_hermes.includes("recepcion")) {
          if (
            this.data_formulario.fecha_max_recibida != null &&
            this.data_formulario.fecha_max_respuesta != null
          ) {
            this.actualizarInvestigacionPqrs();
          } else {
            this.$swal.fire({
              title:
                "Debe digilenciar al menos las fechas de max.recibida y max.respuesta",
              icon: "info",
              allowEscapeKey: false,
              allowOutsideClick: false,
              allowEnterKey: false,
              position: "center",
              showConfirmButton: true,
              confirmButtonText: "Ok",
            });
          }
        } else {
          if (this.$refs.formularioSolucionPqrs.validate()) {
            this.designarFuncionario();
          }
        }
      }
    },

    async actualizarInvestigacionPqrs() {
      this.guardando_solucion_pqrs = true;

      let data = new FormData();

      data.append(
        "fecha_maxima_recibida",
        this.data_formulario.fecha_max_recibida
      );
      data.append(
        "fecha_maxima_respuesta",
        this.data_formulario.fecha_max_respuesta
      );
      data.append(
        "descripcion",
        this.data_formulario.descripcion == null
          ? ""
          : this.data_formulario.descripcion
      );
      data.append(
        "investigacion_realizada",
        this.data_formulario.investigacion_realizada == null
          ? ""
          : this.data_formulario.investigacion_realizada
      );
      data.append(
        "acciones_tomar",
        this.data_formulario.acciones_tomar == null
          ? ""
          : this.data_formulario.acciones_tomar
      );
      data.append(
        "conclusiones",
        this.data_formulario.conclusiones == null
          ? ""
          : this.data_formulario.conclusiones
      );

      data.append(
        "require_accion_correctiva_preventiva",
        this.data_formulario.requiere_accion_correctiva_preventiva == false
          ? 0
          : 1
      );
      data.append("numero_evidencias", this.data_formulario.evidencias.length);

      //Se valida e itera sobre estas referencias para agregarlas al objeto formdata
      if (this.data_formulario.evidencias.length > 0) {
        for (
          let index = 0;
          index < this.data_formulario.evidencias.length;
          index++
        ) {
          //Se agregan los archivos con el prefijo de evidencia_numeroIndex para ser identificadas en el backend de manera mas facil
          data.append(
            "evidencia_" + index,
            this.data_formulario.evidencias[index]
          );
        }
      }
      data.append("id_pqrs", this.datos_pqrs.id);
      data.append("documento", this.datos_pqrs.documento);

      await reportePqrsService
        .actualizarInvestigacionPqrs(data)
        .then((response) => {
          if (response.data == 1) {
            this.$swal
              .fire({
                title: "Datos pqrs actualizados",
                icon: "success",
                allowEscapeKey: false,
                allowOutsideClick: false,
                allowEnterKey: false,
                position: "center",
                showConfirmButton: true,
                confirmButtonText: "Ok",
              })
              .then(() => {
                this.$emit("actualizar-datos-pqrs", this.data_formulario);
                this.$emit("cerrar-modal");
              });
          } else {
            this.$swal.fire({
              title: "Error actualizar los datos de la pqrs",
              icon: "error",
              allowEscapeKey: false,
              allowOutsideClick: false,
              allowEnterKey: false,
              position: "center",
              showConfirmButton: true,
              confirmButtonText: "Ok",
            });
          }
        })
        .catch((error) => {
          console.log(error);
          //Se retorna un mensaje con el numero de radicado (id de la pqrs que se acabo de crear )
          this.$swal.fire({
            title: "Error actualizar los datos de la pqrs",
            icon: "error",
            allowEscapeKey: false,
            allowOutsideClick: false,
            allowEnterKey: false,
            position: "center",
            showConfirmButton: true,
            confirmButtonText: "Ok",
          });
        });
      this.guardando_solucion_pqrs = false;
    },
    async designarFuncionario() {
      this.guardando_solucion_pqrs = true;

      await reportePqrsService
        .designarFuncionarioPqrs({
          id_funcionario_designado:
            this.data_formulario.funcionario_designado_tratamiento,
          tipo_proceso_vinculado: this.data_formulario.tipo_proceso_vinculado,
          datos_pqrs: this.datos_pqrs,
        })
        .then((response) => {
          if (response.data == 1) {
            //Se retorna un mensaje con el numero de radicado (id de la pqrs que se acabo de crear )
            this.$swal
              .fire({
                title: "Usuario Designado",
                icon: "success",
                allowEscapeKey: false,
                allowOutsideClick: false,
                allowEnterKey: false,
                position: "center",
                showConfirmButton: true,
                confirmButtonText: "Ok",
              })
              .then(() => {
                this.$emit("designar-datos-funcionario", this.data_formulario);
                this.$emit("cerrar-modal");
              });
          }
        })
        .catch((error) => {
          console.log(error);
          //Se retorna un mensaje con el numero de radicado (id de la pqrs que se acabo de crear )
          this.$swal.fire({
            title: "Error al designar el usuario",
            icon: "error",
            allowEscapeKey: false,
            allowOutsideClick: false,
            allowEnterKey: false,
            position: "center",
            showConfirmButton: true,
            confirmButtonText: "Ok",
          });
        });
      this.guardando_solucion_pqrs = false;
    },
    async asignarDatosPqrs() {
      console.log(this.datos_pqrs);
      this.data_formulario.tipo_proceso_vinculado =
        this.datos_pqrs.tipo_proceso_vinculado;

      this.data_formulario.funcionario_designado_tratamiento =
        this.datos_pqrs.id_funcionario_designado;

      this.data_formulario.documento_funcionario_designado =
        this.datos_pqrs.documento_funcionario_designado;

      this.data_formulario.fecha_max_recibida =
        this.datos_pqrs.fecha_maxima_recibida;

      this.data_formulario.fecha_max_respuesta =
        this.datos_pqrs.fecha_maxima_respuesta;

      this.data_formulario.descripcion = this.datos_pqrs.detalle_pqrs;

      this.data_formulario.investigacion_realizada =
        this.datos_pqrs.investigacion_realizada;

      this.data_formulario.acciones_tomar = this.datos_pqrs.acciones_tomar;

      this.data_formulario.conclusiones = this.datos_pqrs.conclusiones;

      this.data_formulario.requiere_accion_correctiva_preventiva =
        this.datos_pqrs.requiere_accion_correctiva_preventiva;

      await this.buscarUsuario(
        this.datos_pqrs.documento_funcionario_designado == null
          ? ""
          : this.datos_pqrs.documento_funcionario_designado
      );
    },
  },
};
</script>

<style></style>
