<template>
  <v-container fluid>
    <v-card>
      <v-toolbar color="grey darken-3" dark height="40">
        <v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-title>Documento</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar-title>
      </v-toolbar>
      <v-card-text class="mt-4">
        <v-row>
          <v-col cols="12" sm="6" lg="3">
            <label> Primer Nombre</label>
            <v-text-field outlined dense v-model="nombre1"></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" lg="3">
            <label> Segundo Nombre</label>
            <v-text-field outlined dense v-model="nombre2"></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" lg="3">
            <label> Primer Apellido</label>
            <v-text-field outlined dense v-model="apellido1"></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" lg="3">
            <label> Segundo Apellido</label>
            <v-text-field outlined dense v-model="apellido2"></v-text-field>
          </v-col>

    
          <v-col cols="12" sm="6" lg="3">
            <label> Documento</label>
            <v-text-field
              type="number"
              v-model="documento"
              outlined
              dense
            ></v-text-field>
          </v-col>

          <v-col cols="12" sm="6" lg="3">
            <label> Correo</label>
            <v-text-field outlined dense v-model="correo"></v-text-field>
          </v-col>

          <v-col cols="12" sm="6" lg="6">
            <label> Dirección</label>
            <v-text-field outlined dense v-model="direccion"></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" lg="3">
            <label>Celular del dueño</label>
            <v-text-field
              type="number"
              outlined
              dense
              v-model="celular1"
            ></v-text-field>
          </v-col>

          <!-- <v-col cols="6" class="mt-n5 mb-n2"> </v-col> -->

          <v-col cols="12" sm="6" lg="3" v-if="checkbox_habilitar_campos">
            <label> Tipo de persona</label>
            <v-select
              item-text="name"
              item-value="id"
              v-model="tipoPersona"
              :items="tipoPersonas"
              outlined
              dense
            ></v-select>
          </v-col>
          <v-col cols="12" sm="6" lg="3" v-if="checkbox_habilitar_campos">
            <label> Tipo de identificación</label>
            <v-select
              item-text="name"
              item-value="id"
              v-model="tipoIdentificacion"
              :items="tipoIdentificaciones"
              outlined
              dense
            ></v-select>
          </v-col>
          <v-col cols="12" sm="6" lg="3" v-if="checkbox_habilitar_campos">
            <label> Departamento </label>
            <v-autocomplete
              @change="getCitiesByDepartment"
              item-text="name"
              item-value="id"
              v-model="departamento"
              :items="departamentos"
              outlined
              dense
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="6" lg="3" v-if="checkbox_habilitar_campos">
            <label> Ciudad </label>
            <v-autocomplete
              item-text="name"
              item-value="id"
              v-model="ciudad"
              :items="ciudades"
              outlined
              dense
            ></v-autocomplete>
          </v-col>

          <v-col cols="12" sm="6" lg="3">
            <label> Celular de quien maneja el vehiculo</label>
            <v-text-field
              type="number"
              v-model="celular2"
              append-icon="mdi-file"
              outlined
              dense
            ></v-text-field>
          </v-col>

          <v-col cols="12" sm="6" lg="3">
            <label>Placa</label>
            <v-text-field v-model="placa" outlined dense></v-text-field>
          </v-col>


          
        </v-row>

        <v-row>
          <v-col cols="6" class="mt-n5 mb-n6">
            <v-checkbox
              label="Ver más información del usuario"
              v-model="checkbox_habilitar_campos"
            ></v-checkbox>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" sm="4">
            <v-card elevation="0">
              <v-card-title>Tipo RTM</v-card-title>
              <v-card-text>
                <v-radio-group v-model="tipoRTM" row>
                  <v-radio
                    :key="1"
                    label="Oficial"
                    color="blue"
                    :value="1"
                  ></v-radio>
                  <v-radio
                    :key="2"
                    label="Preventiva"
                    color="blue"
                    :value="2"
                  ></v-radio>
                </v-radio-group>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" sm="4">
            <v-card elevation="0">
              <v-card-title>Tipo RTM Oficial</v-card-title>
              <v-card-text>
                <v-radio-group v-model="tipoRTMOficial" row>
                  <v-radio
                    :key="1"
                    label="Nueva"
                    color="blue"
                    :value="1"
                  ></v-radio>
                  <v-radio
                    :key="2"
                    label="Reinspección"
                    color="blue"
                    :value="2"
                  ></v-radio>
                </v-radio-group>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" sm="4">
            <v-card elevation="0">
              <v-card-title>Tipo de vehiculo</v-card-title>
              <v-card-text>
                <v-radio-group v-model="tipoVehiculo" row>
                  <v-radio
                    :key="1"
                    label="Carro"
                    color="blue"
                    :value="1"
                  ></v-radio>
                  <v-radio
                    :key="2"
                    label="Moto"
                    color="blue"
                    :value="2"
                  ></v-radio>
                </v-radio-group>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

        <h2 class="ml-3 mt-3">Tarjeta de propiedad</h2>

        <v-row class="mt-5 ml-3">
          <v-btn @click="$refs.tp_frontal.click()" :class="file_frontal != '' ? 'archivo-subido' : 'archivo-no-subido' " 
            >TP Frontal <v-icon>mdi-upload</v-icon> </v-btn
          >
          <input
            style="display: none"
            ref="tp_frontal"
            id="tp_frontal"
            type="file"
            @change="previewFiles()"
            accept="image/*; capture=camera"
          />

          <v-btn @click="$refs.tp_trasero.click()" :class="file_trasero != '' ? 'archivo-subido' : 'archivo-no-subido' " class="ml-2"  
            >TP Trasera <v-icon>mdi-upload</v-icon> </v-btn
          >
          <input
            style="display: none"
            ref="tp_trasero"
            id="tp_trasero"
            type="file"
            accept="image/*; capture=camera"
            @change="previewFiles()"
          />

          <v-col cols="12" class="mt-4">
            <label for="">Notas del cliente</label>
            <v-textarea
              v-model="notas"
              dense
              outlined
              color="black"
            ></v-textarea>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <label for="">Referido por:</label>
            <v-select
              item-text="nombre"
              item-value="id"
              v-model="referido"
              :items="referidos"
              dense
              outlined
            ></v-select>
          </v-col>
        </v-row>

        <v-row>
          <v-spacer></v-spacer>
          <h2 class="mr-6">Subir Documentos</h2>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn class="mb-4" color="red" dark>Cancelar</v-btn>
        <v-btn class="mr-6 mb-4" @click="subir" color="primary">Subir</v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import GeneralService from "@/services/GeneralService.js";
import RegisterService from "@/services/RegisterService.js";
export default {
  data: () => ({
    documento: "",
    tipoRTM: 1,
    tipoRTMOficial: 1,
    tipoVehiculo: null,
    checkbox_habilitar_campos: false,
    nombre1: "",
    nombre2: "",
    apellido1: "",
    apellido2: "",
    correo: "",
    direccion: "",
    celular1: "",
    celular2: "",
    tipoPersona: null,
    tipoPersonas: [],
    tipoIdentificacion: null,
    tipoIdentificaciones: [],
    departamento: null,
    departamentos: [],
    ciudad: null,
    ciudades: [],
    placa: null,
    referido: null,
    referidos: [],
    notas: null,
    file_trasero: [],
    file_frontal: [],
    file: []
  }),
  methods: {

    async  previewFiles() {
       
        this.file_trasero = this.$refs.tp_trasero.files[0]
  
    },

    async getCitiesByDepartment() {
      let { data } = await GeneralService.getCitiesByDepartment(
        this.departamento
      );
      this.ciudades = data;
      this.ciudad = null;
    },
    test() {
      // console.log(this.$refs.tp_frontal.files[0])
      // console.log(this.$refs.tp_trasero.files[0])
    },
    async subir() {
      const data = new FormData();
      data.append("documento", this.documento);
      data.append("rtm", 1);
      data.append("placa", this.placa);
      data.append("celular", this.celular1);
      data.append("celular2", this.celular2);
      data.append("name1", this.nombre1);
      data.append("name2", this.nombre2);
      data.append("lastname1", this.apellido1);
      data.append("lastname2", this.apellido2);
      data.append(
        "nombre",
        this.nombre1 +
          " " +
          this.nombre2 +
          " " +
          this.apellido1 +
          " " +
          this.apellido2
      );
      data.append("tipo_persona", this.tipoPersona);
      data.append("tipo_identificacion", this.tipoIdentificacion);
      data.append("departamento", this.departamento);
      data.append("ciudad", this.ciudad);
      data.append("correo", this.correo);
      data.append("oficial", this.tipoRTM);
      data.append("tipoOficial", 1);
      data.append("tipoVehiculo", this.tipoVehiculo);
      data.append("id_usuario", 1);
      data.append("notas", this.notas);
      data.append("referido", this.referido);
      data.append("direccion", this.direccion);
      data.append("delantera", this.$refs.tp_frontal.files[0]);
      data.append("trasera", this.$refs.tp_trasero.files[0]);
      let answer = await RegisterService.postRegisterOrUpdateUser(data);
      console.log(answer);
      
    },
  },
  async created() {
      
    let { data } = await GeneralService.getTablesClient();
    this.tipoPersonas = data.typePeoples;
    this.tipoIdentificaciones = data.typeDocuments;
    this.departamentos = data.departments;
    let { configs } = data;
    await this.getCitiesByDepartment();
    console.log(configs);
    this.ciudad = configs[0].Ciudad;
    this.departamento = configs[0].Departamento;
    this.correo = configs[0].correoDefault;
    this.tipoIdentificacion = configs[0].tipo_identificacion;
    this.tipoPersona = configs[0].tipo_persona;
    this.tipoVehiculo = configs[0].tipoVehiculo;

    let referidos = await GeneralService.getReferidos();
    this.referidos = referidos.data;

    this.documento = this.$route.query.documento;
  },
};
</script>

<style scoped>
/* PERMITE REDUCIR EL ESPACIO ENTRE INPUTS */
.col-12 {
  padding-top: 0;
  padding-bottom: 0;
}

.theme--light.v-input {
  padding-top: 0;
}

.v-input__slot {
  margin-bottom: 0;
}
label.cameraButton {
  display: inline-block;
  margin: 1em 0;
  /* Styles to make it look like a button */
  padding: 0.5em;
  border: none;
  background-color: none;
}
/* Look like a clicked/depressed button */
/* This is the part that actually hides the 'Choose file' text box for camera inputs */
label.cameraButton input[accept*="camera"] {
  display: none;
}

.archivo-no-subido{
    background: rgb(192, 194, 194) !important;
    color: rgb(75, 74, 74);
}

.archivo-subido{
    background: rgb(46, 16, 219) !important;
    color: #fff;
}



</style>
