import apiClient from './index.js'

const ruta = 'mantenimientos';

let api = apiClient.api();

export default {
  buscarActividades(parametrosBusqueda) {
    return api.post(ruta + '/buscar-actividades',parametrosBusqueda);
  },
  llenarSelectsMantenimientos(){
    return api.get(ruta + '/llenar-selects');
  },
  crearActividad(actividad){
    return api.put(ruta + '/crear-actividad',actividad);
  },
  verifyMaintenance(idEquipo) {
    return api.post(ruta + "/verificar-mantenimiento", idEquipo);
  },
  guardarMantenimiento(data){
    return api.put(ruta + '/crear-mantenimiento',data);
  },
  guardarCalibracion(calibracion){
    return api.post(ruta + '/crear-calibracion',calibracion);
  },
  guardarVerificacion(verificacion){
    return api.post(ruta + '/crear-verificacion',verificacion);
  },
  crearNuevaFrecuencia(nuevaFrecuencia){
    return api.put(ruta + '/crear-nueva-frecuencia',nuevaFrecuencia);
  }
  

}