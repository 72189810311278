import axios from "axios";

// Creamos la base de las peticiones, con los datos necesarios
const apiClient = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL + "rtm-x-dia",
  headers: {
      
  }
});



// Exportamos los métodos para las peticiones
export default {
  getUsuariosDirectorTecnico() {
    return apiClient.get("/usuarios-director-tecnico");
  },
  getEncuestasHechas() {
    return apiClient.get("/encuestas-hechas");
  },
  getRTMDia() {
    return apiClient.get("");
  },

};