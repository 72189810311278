import axios from "axios";

// Creamos la base de las peticiones, con los datos necesarios
const apiClient = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL + "encuesta",
  headers: {
      
  }
});



// Exportamos los métodos para las peticiones
export default {
  postRegistroEncuesta(params) {
    return apiClient.post("/insertar-encuesta",params);
  },
};