<template>
<v-card width="500">
    <v-toolbar class color="grey darken-3" dark height="40">
        <v-spacer></v-spacer>
        <v-toolbar-title>Subir Certificados</v-toolbar-title>
        <v-spacer></v-spacer>
    </v-toolbar>
    <v-card-text>
        <v-row class="mt-2">
            <v-col cols="12" sm="6">
                <v-file-input dense outlined accept="image/*" label="Subir Certificado" append-icon="mdi-upload" prepend-icon=''></v-file-input>
            </v-col>
            <v-col cols="12" sm="6">
                <v-file-input dense outlined accept="image/*" append-icon="mdi-upload" label="Seleccionar FUR" prepend-icon=''></v-file-input>
            </v-col>
            <v-col cols="12">
                <v-select label="Tecnico" item-text="nombre" item-value="cedula" v-model="tecnico" :items="tecnicos" dense outlined></v-select>
            </v-col>
        </v-row>
    </v-card-text>
    <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="red" dark @click="$emit('cerrar-modal')">Cancelar</v-btn>
        <v-btn color="primary" @click="$emit('cerrar-modal')">Aceptar</v-btn>
    </v-card-actions>
</v-card>
</template>

<script>
import RTMDiaService from '@/services/RTMDiaService.js'

export default {
    data: () => ({
      tecnico: null,
      tecnicos: []
    }),
    async created(){
        let {
            data
        } = await RTMDiaService.getUsuariosDirectorTecnico();
        this.tecnicos = data;
        console.log(data);
    },
};
</script>

<style></style>
