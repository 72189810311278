<template>
  <div>
    <v-toolbar color="grey darken-3" dark height="40">
      <v-spacer></v-spacer>
      <v-toolbar-title class="mr-n16"
        >Especificaciones Tecnicas</v-toolbar-title
      >
      <v-spacer></v-spacer>
      <v-btn
        small
        color="success"
        class="ml-n6"
        @click="(dialogVar = true), resetForm"
      >
        <v-icon small>mdi-plus</v-icon>variable
      </v-btn>
    </v-toolbar>
    <v-dialog
      v-model="dialogVar"
      origin="top center"
      persistent
      max-width="1000px"
    >
      <v-card>
        <v-card-title class="grey darken-3 white--text">
          <v-spacer></v-spacer>
          <span class="text-h5">Agregar Variable</span>
          <v-spacer></v-spacer>
          <v-btn class="mr-n5 mt-n2 white--text" @click="cancelar" text icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-form ref="formVariables" class="pl-10 pr-10 mt-7 mb-5">
          <v-row>
            <!-- VARIABLE -->
            <v-col cols="12" sm="4" class="mb-n5">
              <label
                >Variable
                <v-btn
                  v-show="crearVariable == false"
                  class="mt-n1"
                  x-small
                  text
                  color="success"
                  @click="crearVariable = true"
                  >[+ AGREGAR ]</v-btn
                >
                <v-btn
                  v-show="crearVariable"
                  class="mt-n1"
                  x-small
                  text
                  color="success"
                  @click="crearNuevaVariable()"
                  >[+ GUARDAR ]</v-btn
                >
                <v-btn
                  v-show="crearVariable"
                  class="mt-n1"
                  x-small
                  text
                  color="red"
                  @click="crearVariable = false"
                  >[ CANCELAR ]</v-btn
                ></label
              >
              <v-autocomplete
                v-model="variableE"
                :items="variablesE"
                v-if="crearVariable == false"
                placeholder="Seleccione una opcion"
                item-text="variable"
                :rules="selectRules"
                item-value="id_variable"
                @change="llenarCamposRangos(variableE)"
                disable-lookup
                outlined
                dense
              >
              </v-autocomplete>
              <v-text-field
                v-if="crearVariable == true"
                placeholder="Nueva Variable"
                outlined
                dense
                v-model="newVariable"
                :rules="basicRules"
              ></v-text-field>
            </v-col>

            <!-- RANGO DE MEDICION -->
            <v-col cols="12" sm="4" class="mb-n5">
              <label
                >Rango de medicion
                <v-btn
                  v-show="crearRangoMed == false"
                  class="mt-n1"
                  x-small
                  :disabled="rangeDisabled"
                  text
                  color="success"
                  @click="crearRangoMed = true"
                  >[+ AGREGAR ]</v-btn
                >
                <v-btn
                  v-show="crearRangoMed"
                  class="mt-n1"
                  x-small
                  text
                  color="success"
                  @click="crearRangoMedicion()"
                  >[+ GUARDAR ]</v-btn
                >
                <v-btn
                  v-show="crearRangoMed"
                  class="mt-n1"
                  x-small
                  text
                  color="red"
                  @click="crearRangoMed = false"
                  >[ CANCELAR ]</v-btn
                >
              </label>
              <v-autocomplete
                v-model="rangoMedicionEquipo"
                v-if="crearRangoMed == false"
                :items="rangosMedicionVar"
                :disabled="rangeDisabled"
                placeholder="Seleccione una opcion"
                item-text="rango_medicion"
                :rules="selectRules"
                item-value="id_rango_medicion"
                disable-lookup
                outlined
                dense
              >
              </v-autocomplete>
              <v-text-field
                v-if="crearRangoMed == true"
                placeholder="Nuevo Rango medicion"
                outlined
                dense
                v-model="newRangoMedicion"
                :rules="basicRules"
              ></v-text-field>
            </v-col>

            <!-- PRECISION Y EXACTITUD -->
            <v-col cols="12" sm="4" class="mb-n5">
              <label
                >Precision y Exactitud<v-btn
                  v-show="crearRangoPreci == false"
                  class="mt-n1"
                  x-small
                  :disabled="rangeDisabled"
                  text
                  color="success"
                  @click="crearRangoPreci = true"
                  >[+ AGREGAR ]</v-btn
                >
                <v-btn
                  v-show="crearRangoPreci"
                  class="mt-n1"
                  x-small
                  text
                  color="success"
                  @click="crearRangoPrecision()"
                  >[+ GUARDAR ]</v-btn
                >
                <v-btn
                  v-show="crearRangoPreci"
                  class="mt-n1"
                  x-small
                  text
                  color="red"
                  @click="crearRangoPreci = false"
                  >[ CANCELAR ]</v-btn
                ></label
              >
              <v-autocomplete
                v-model="precisionEquipo"
                :items="precisionesVar"
                :disabled="rangeDisabled"
                v-if="crearRangoPreci == false"
                placeholder="Seleccione una opcion"
                item-text="precision_exactitud"
                :rules="selectRules"
                item-value="id_precision_exactitud"
                disable-lookup
                outlined
                dense
              >
              </v-autocomplete>
              <v-text-field
                v-if="crearRangoPreci == true"
                placeholder="Nueva Precision y Exactitud"
                outlined
                dense
                v-model="newRangoPrecision"
                :rules="basicRules"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <!-- DIVISION DE ESCALA -->
            <v-col cols="12" sm="4">
              <label
                >Division de Escala<v-btn
                  v-show="crearRangoDivi == false"
                  class="mt-n1"
                  x-small
                  :disabled="rangeDisabled"
                  text
                  color="success"
                  @click="crearRangoDivi = true"
                  >[+ AGREGAR ]</v-btn
                >
                <v-btn
                  v-show="crearRangoDivi"
                  class="mt-n1"
                  x-small
                  text
                  color="success"
                  @click="crearRangoDivision()"
                  >[+ GUARDAR ]</v-btn
                >
                <v-btn
                  v-show="crearRangoDivi"
                  class="mt-n1"
                  x-small
                  text
                  color="red"
                  @click="crearRangoDivi = false"
                  >[ CANCELAR ]</v-btn
                ></label
              >
              <v-autocomplete
                v-model="divisionEquipo"
                :items="divisionesVar"
                v-if="crearRangoDivi == false"
                :disabled="rangeDisabled"
                placeholder="Seleccione una opcion"
                item-text="division_escala"
                :rules="selectRules"
                item-value="id_division_escala"
                disable-lookup
                outlined
                dense
              >
              </v-autocomplete>
              <v-text-field
                v-if="crearRangoDivi == true"
                placeholder="Nueva Division Escala"
                outlined
                dense
                v-model="newRangoDivision"
                :rules="basicRules"
              ></v-text-field>
            </v-col>

            <!-- ERROR MAX PERMITIDO -->
            <v-col cols="12" sm="4">
              <label
                >Error max. Permitido
                <v-btn
                  v-show="crearRangoErr == false"
                  class="mt-n1"
                  x-small
                  :disabled="rangeDisabled"
                  text
                  color="success"
                  @click="crearRangoErr = true"
                  >[+ AGREGAR ]</v-btn
                >
                <v-btn
                  v-show="crearRangoErr"
                  class="mt-n1"
                  x-small
                  text
                  color="success"
                  @click="crearRangoError()"
                  >[+ GUARDAR ]</v-btn
                >
                <v-btn
                  v-show="crearRangoErr"
                  class="mt-n1"
                  x-small
                  text
                  color="red"
                  @click="crearRangoErr = false"
                  >[ CANCELAR ]</v-btn
                >
              </label>
              <v-autocomplete
                v-model="errorMaxEquipo"
                :items="erroresMaxPVar"
                v-if="crearRangoErr == false"
                :disabled="rangeDisabled"
                placeholder="Seleccione una opcion"
                item-text="error_max_permitido"
                :rules="selectRules"
                item-value="id_error_max_permitido"
                disable-lookup
                outlined
                dense
              >
              </v-autocomplete>

              <v-text-field
                v-if="crearRangoErr == true"
                placeholder="Nueva Error max.Permitido"
                outlined
                dense
                v-model="newRangoError"
                :rules="basicRules"
              ></v-text-field>
            </v-col>

            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>

            <v-col v-if="!editarVar">
              <v-btn
                class="ml-12 mt-5"
                large
                :loading="loader"
                :disabled="loader"
                color="success"
                @click="agregarVariable"
              >
                <v-icon left> mdi-plus </v-icon>
                Agregar
                <template v-slot:loader>
                  <span class="custom-loader">
                    <v-icon light>mdi-cached</v-icon>
                  </span>
                </template>
              </v-btn>
            </v-col>

            <v-col v-else>
              <v-btn
                class="ml-12 mt-5"
                large
                :loading="loader"
                :disabled="loader"
                color="success"
                @click="actualizarVarible"
              >
                Actualizar
                <template v-slot:loader>
                  <span class="custom-loader">
                    <v-icon light>mdi-cached</v-icon>
                  </span>
                </template>
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card>
    </v-dialog>

    <!-- DATA TABLE CREAR -->
    <v-data-table
      :headers="headers"
      :items="variablesShow"
      :items-per-page="5"
      v-if="!edit"
      class="elevation-1"
      :footer-props="{
        itemsPerPageText: 'N° Items',
        itemsPerPageAllText: 'Todos',
      }"
    >
      <template slot="no-data"> No hay Variables Agregadas </template>

      <template v-slot:item.id_variable="variable">
        <div v-for="vari in variablesE" :key="vari.id">
          <span v-if="variable.item.id_variable == vari.id_variable">{{
            vari.variable
          }}</span>
        </div>
      </template>

      <template v-slot:item.id_rango_medicion="measuring">
        <div v-for="measu in rangosMedicion" :key="measu.id">
          <span
            v-if="measuring.item.id_rango_medicion == measu.id_rango_medicion"
            >{{ measu.rango_medicion }}</span
          >
        </div>
      </template>

      <template v-slot:item.id_precision_exactitud="precision">
        <div v-for="precis in precisiones" :key="precis.id">
          <span
            v-if="
              precision.item.id_precision_exactitud ==
              precis.id_precision_exactitud
            "
            >{{ precis.precision_exactitud }}</span
          >
        </div>
      </template>

      <template v-slot:item.id_division_escala="scale_div">
        <div v-for="scale in divisiones" :key="scale.id">
          <span
            v-if="scale_div.item.id_division_escala == scale.id_division_escala"
            >{{ scale.division_escala }}</span
          >
        </div>
      </template>

      <template v-slot:item.id_error_max_permitido="error_max_permitido">
        <div v-for="error in erroresMaxP" :key="error.id">
          <span
            v-if="error_max_permitido.item.id_error_max_permitido == error.id_error_max_permitido"
            >{{ error.error_max_permitido }}</span
          >
        </div>
      </template>
    </v-data-table>

    <!-- DATA TABLE EDITAR -->
    <v-data-table
      :headers="headersEdit"
      :items="variablesEdit"
      :items-per-page="5"
      v-if="edit"
      class="elevation-1"
      :footer-props="{
        itemsPerPageText: 'N° Items',
        itemsPerPageAllText: 'Todos',
      }"
    >
      <template slot="no-data"> No hay Variables Agregadas </template>

      <template v-slot:item.id_variable="variable">
        <div v-for="vari in variablesE" :key="vari.id">
          <span v-if="variable.item.id_variable == vari.id_variable">{{
            vari.variable
          }}</span>
        </div>
      </template>

      <template v-slot:item.id_rango_medicion="measuring">
        <div v-for="measu in rangosMedicion" :key="measu.id">
          <span
            v-if="measuring.item.id_rango_medicion == measu.id_rango_medicion"
            >{{ measu.rango_medicion }}</span
          >
        </div>
      </template>

      <template v-slot:item.id_precision_exactitud="precision">
        <div v-for="precis in precisiones" :key="precis.id">
          <span
            v-if="
              precision.item.id_precision_exactitud ==
              precis.id_precision_exactitud
            "
            >{{ precis.precision_exactitud }}</span
          >
        </div>
      </template>

      <template v-slot:item.id_division_escala="scale_div">
        <div v-for="scale in divisiones" :key="scale.id">
          <span
            v-if="scale_div.item.id_division_escala == scale.id_division_escala"
            >{{ scale.division_escala }}</span
          >
        </div>
      </template>

      <template v-slot:item.id_error_max_permitido="error_max_permitido">
        <div v-for="error in erroresMaxP" :key="error.id">
          <span
            v-if="error_max_permitido.item.id_error_max_permitido == error.id_error_max_permitido"
            >{{ error.error_max_permitido }}</span
          >
        </div>
      </template>

      <template v-slot:item.opciones="opcion">
        <v-icon @click="editarVariable(opcion.item)"> mdi-pencil </v-icon>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import services from "@/services";
import { mapState, mapMutations } from "vuex";
import { mapFields } from "vuex-map-fields";

export default {
  name: "CreateVariable",
  data: () => ({
    headers: [
      { text: "Variable", value: "id_variable", align: "center" },
      {
        text: "Rango de Medicion",
        value: "id_rango_medicion",
        align: "center",
      },
      {
        text: "Precision y Exactitud",
        value: "id_precision_exactitud",
        align: "center",
      },
      {
        text: "Division de escala",
        value: "id_division_escala",
        align: "center",
      },
      { text: "Error max.Permitido", value: "id_error_max_permitido", align: "center" },
    ],
    headersEdit: [
      { text: "Variable", value: "id_variable", align: "center" },
      {
        text: "Rango de Medicion",
        value: "id_rango_medicion",
        align: "center",
      },
      {
        text: "Precision y Exactitud",
        value: "id_precision_exactitud",
        align: "center",
      },
      {
        text: "Division de escala",
        value: "id_division_escala",
        align: "center",
      },
      { text: "Error max.Permitido", value: "id_error_max_permitido", align: "center" },
      { text: "Accion", value: "opciones", align: "center" },
    ],
    loader: null,
    crearVariable: false,
    crearRangoMed: false,
    crearRangoPreci: false,
    crearRangoDivi: false,
    crearRangoErr: false,
    dialogVar: false,
    edit: false,
    editarVar: false,
    idEditar: "",
    pusharseId: "",
    variablesE: [],
    variablesEdit: "",
    rangosMedicionVar: [],
    precisionesVar: [],
    divisionesVar: [],
    erroresMaxPVar: [],
    rangeDisabled: true,
    newVariable: "",
    newRangoMedicion: "",
    newRangoPrecision: "",
    newRangoDivision: "",
    newRangoError: "",
    variableE: "",
    rangoMedicionEquipo: "",
    rangosMedicion: [],
    precisionEquipo: "",
    precisiones: [],
    divisionEquipo: "",
    divisiones: [],
    errorMaxEquipo: "",
    erroresMaxP: [],
    selectRules: [(value) => !!value || "Debe seleccionar una opcion."],
    basicRules: [(value) => !!value || "El campo es requerido."],
  }),
  computed: {
    ...mapState({}),
    ...mapFields({ variablesShow: "variablesShow", device_id: "device_id" }),
  },
  mounted() {
    services.datosFormVariables().then((response) => {
      this.variablesE = response.data["variables"];
      this.rangosMedicion = response.data["measuring"];
      this.precisiones = response.data["precision"];
      this.divisiones = response.data["scale"];
      this.erroresMaxP = response.data["error"];
    });
  },
  methods: {
    ...mapMutations([
      /**Funcion que permite agregar una varible a la lista de variables para mostrar*/
      "insertVariable",
      ]),
    //
    /**Funcion que permite rellenar los rangos dependiendo de la variable 
     * 
     * @param {number} id id de la variable
     * @returns {void}
     */
    llenarCamposRangos(id) {
      if (id == null) {
        this.rangeDisabled = true;
        this.resetForm();
      } else {
        this.rangosMedicionVar = [];
        this.precisionesVar = [];
        this.divisionesVar = [];
        this.erroresMaxPVar = [];

        this.rangoMedicionEquipo = "";
        this.precisionEquipo = "";
        this.divisionEquipo = "";
        this.errorMaxEquipo = "";

        if (this.editarVar != true) {
          this.$refs.formVariables.resetValidation();
        } else {
          this.$nextTick(() => {
            this.$refs.formVariables.resetValidation();
          });
        }

        for (var i = 0; i < this.rangosMedicion.length; i++) {
          if (this.rangosMedicion[i]["id_variable"] == id) {
            this.rangosMedicionVar.push(this.rangosMedicion[i]);
          }
        }

        for (var i = 0; i < this.precisiones.length; i++) {
          if (this.precisiones[i]["id_variable"] == id) {
            this.precisionesVar.push(this.precisiones[i]);
          }
        }

        for (var i = 0; i < this.divisiones.length; i++) {
          if (this.divisiones[i]["id_variable"] == id) {
            this.divisionesVar.push(this.divisiones[i]);
          }
        }

        for (var i = 0; i < this.erroresMaxP.length; i++) {
          if (this.erroresMaxP[i]["id_variable"] == id) {
            this.erroresMaxPVar.push(this.erroresMaxP[i]);
          }
        }

        this.rangeDisabled = false;
      }
    },
    /** Funcion que recibe asigna el id del equipo a editar y las variables que le pertenecen a este
     *
     * @param {number} id  id del equipo a editar
     * @param {array} variables variables que pertenenecen a dicho equipo
     */
    editar(id, variables) {
      this.idEditar = id;
      this.variablesEdit = variables;
    },

    /**Funcion que permite agregar la variable al equipo creado en la tabla technical specs*/
    agregarVariable() {
      if (this.$refs.formVariables.validate()) {
        this.loader = true;
        if (this.edit == true) {
          var idEquipo = this.idEditar;
        } else {
          var idEquipo = this.device_id;
        }
        services
          .createVariable({
            device_id: idEquipo,
            id_variable: this.variableE,
            id_rango_medicion: this.rangoMedicionEquipo,
            id_precision_exactitud: this.precisionEquipo,
            id_division_escala: this.divisionEquipo,
            id_error_max_permitido: this.errorMaxEquipo,
          })
          .then((response) => {
            this.loader = false;
            if (response.data["respuesta"] == true) {
              this.dialogVar = false;
              this.$swal
                .fire({
                  title: "Variable Agregada",
                  icon: "success",
                  timer: 2000,
                  allowEscapeKey: false,
                  allowOutsideClick: false,
                  allowEnterKey: false,
                  position: "center",
                  showConfirmButton: false,
                  confirmButtonText: "Yes, delete it!",
                })
                .then(() => {
                  if (this.edit == true) {
                    this.variablesEdit.push(response.data["variable"][0]);
                  } else {
                    this.insertVariable(response.data["variable"][0]);
                  }

                  this.$refs.formVariables.reset();
                  this.rangeDisabled = true;
                });
            }
          })
          .catch((e) => {
            console.log("Error al BD" + e);
          });
      }
    },

    /** Funcion que llena los campos del formulario para editar una variable*/
    editarVariable(item) {
      this.editarVar = true;
      this.dialogVar = true;
      this.llenarCamposRangos(item.id_variable);
      this.pusharseId = item.id;
      this.variableE = item.id_variable;
      this.rangoMedicionEquipo = item.id_rango_medicion;
      this.precisionEquipo = item.id_precision_exactitud;
      this.divisionEquipo = item.id_division_escala;
      this.errorMaxEquipo = item.id_error_max_permitido;
    },

    /** Funcion que actualiza los datos de una variable de un equipo*/
    actualizarVarible() {
      if (this.$refs.formVariables.validate()) {
        this.loader = true;
        services
          .updateVariableDevice({
            id: this.pusharseId,
            id_variable: this.variableE,
            id_rango_medicion: this.rangoMedicionEquipo,
            id_precision_exactitud: this.precisionEquipo,
            id_division_escala: this.divisionEquipo,
            id_error_max_permitido: this.errorMaxEquipo,
          })
          .then((response) => {
            this.loader = false;
            if (response.data == true) {
              this.dialogVar = false;
              this.$swal
                .fire({
                  title: "Variable Actualizada",
                  icon: "success",
                  timer: 2000,
                  allowEscapeKey: false,
                  allowOutsideClick: false,
                  allowEnterKey: false,
                  position: "center",
                  showConfirmButton: false,
                  confirmButtonText: "Yes, delete it!",
                })
                .then(() => {
                  this.editarVar = false;
                  var varEditada = {
                    id: this.pusharseId,
                    id_variable: this.variableE,
                    id_rango_medicion: this.rangoMedicionEquipo,
                    id_precision_exactitud: this.precisionEquipo,
                    id_division_escala: this.divisionEquipo,
                    id_error_max_permitido: this.errorMaxEquipo,
                  };
                  for (var i = 0; i < this.variablesEdit.length; i++) {
                    if (this.variablesEdit[i]["id"] == this.pusharseId) {
                      this.variablesEdit.splice(i, 1, varEditada);
                    }
                  }
                });
            } else {
              console.log("Error");
            }
          });
      } else {
        console.log("No esta validado");
      }
    },
    /** Funcion nos permite llamar a la api y crear una nueva variable para luego insertarlo en la vista */
    crearNuevaVariable() {
      services
        .createNewVariable({ variable: this.newVariable })
        .then((response) => {
          this.crearVariable = false;

          this.variablesE.push({
            id_variable: response.data,
            variable: this.newVariable,
          });

          this.variableE = response.data;
          this.newVariable = "";
          this.rangeDisabled = false;
          this.llenarCamposRangos(response.data);
        });
    },

    /** Funcion nos permite llamar a la api y crear un nuevo rango de medicion para luego insertarlo en la vista */
    crearRangoMedicion() {
      services
        .createNewMedRange({
          id_variable: this.variableE,
          rango_medicion: this.newRangoMedicion,
        })
        .then((response) => {
          if (response.data > 0) {
            this.crearRangoMed = false;

            this.rangosMedicion.push({
              id_rango_medicion: response.data,
              id_variable: this.variableE,
              rango_medicion: this.newRangoMedicion,
            });

            this.rangosMedicionVar.push({
              id_rango_medicion: response.data,
              id_variable: this.variableE,
              rango_medicion: this.newRangoMedicion,
            });

            this.rangoMedicionEquipo = response.data;

            this.newRangoMedicion = "";

            this.rangeDisabled = false;
          } else {
            console.log("No se pudo crear");
          }
        });
    },

    /** Funcion nos permite llamar a la api y crear un nuevo rango de Precision y exactitud para luego
     * insertarlo en la vista */
    crearRangoPrecision() {
      services
        .createNewPrecisionRange({
          id_variable: this.variableE,
          precision_exactitud: this.newRangoPrecision,
        })
        .then((response) => {
          if (response.data > 0) {
            this.crearRangoPreci = false;

            this.precisiones.push({
              id_precision_exactitud: response.data,
              id_variable: this.variableE,
              precision_exactitud: this.newRangoPrecision,
            });

            this.precisionesVar.push({
              id_precision_exactitud: response.data,
              id_variable: this.variableE,
              precision_exactitud: this.newRangoPrecision,
            });

            this.precisionEquipo = response.data;

            this.newRangoPrecision = "";

            this.rangeDisabled = false;
          } else {
            console.log("No se pudo crear");
          }
        });
    },

    /** Funcion nos permite llamar a la api y crear un nuevo rango de division escala para luego
     * insertarlo en la vista */
    crearRangoDivision() {
      services
        .createNewDivisionRange({
          id_variable: this.variableE,
          division_escala: this.newRangoDivision,
        })
        .then((response) => {
          if (response.data > 0) {
            this.crearRangoDivi = false;

            this.divisiones.push({
              id_division_escala: response.data,
              id_variable: this.variableE,
              division_escala: this.newRangoDivision,
            });

            this.divisionesVar.push({
              id_division_escala: response.data,
              id_variable: this.variableE,
              division_escala: this.newRangoDivision,
            });

            this.divisionEquipo = response.data;

            this.newRangoDivision = "";

            this.rangeDisabled = false;
          } else {
            console.log("No se pudo crear");
          }
        });
    },

    /** Funcion nos permite llamar a la api y crear un nuevo rango de error maximo para luego
     * insertarlo en la vista */
    crearRangoError() {
      services
        .createNewErrorRange({
          id_variable: this.variableE,
          error_max_permitido: this.newRangoError,
        })
        .then((response) => {
          if (response.data > 0) {
            this.crearRangoErr = false;

            this.erroresMaxP.push({
              id_error_max_permitido: response.data,
              id_variable: this.variableE,
              error_max_permitido: this.newRangoError,
            });

            this.erroresMaxPVar.push({
              id_error_max_permitido: response.data,
              id_variable: this.variableE,
              error_max_permitido: this.newRangoError,
            });

            this.errorMaxEquipo = response.data;

            this.newRangoError = "";

            this.rangeDisabled = false;
          } else {
            console.log("No se pudo crear");
          }
        });
    },

    /**funcion que cierra el modal y reinicia el formulario*/
    cancelar() {
      this.dialogVar = false;
      this.editarVar = false;
      this.rangeDisabled = true;
      this.resetForm();
    },
    /**Funcion que limpia los campos del formulario */
    resetForm() {
        this.$refs.formVariables.reset();
    },
  },
};
</script>

<style></style>
