var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',{staticClass:"mt-n8"},[_c('v-col',{attrs:{"cols":"12"}},[_c('h3',[_vm._v(_vm._s(_vm.id)+" - "+_vm._s(_vm.pregunta))])]),_c('v-radio-group',{ref:"radio",staticClass:"mt-2 mb-4 ml-10 icono",attrs:{"row":""},on:{"change":function($event){return _vm.$emit('agregar-valor',[$event,_vm.id])}}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-radio',_vm._g(_vm._b({staticClass:"icono",attrs:{"color":"red","value":"1","on-icon":"mdi-emoticon-sad-outline","off-icon":"mdi-emoticon-sad-outline"}},'v-radio',attrs,false),on))]}}])},[_c('span',[_vm._v("Malo")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-radio',_vm._g(_vm._b({staticClass:"ml-lg-6",attrs:{"color":"deep-orange lighten-2","value":"2","on-icon":"mdi-emoticon-neutral-outline","off-icon":"mdi-emoticon-neutral-outline"}},'v-radio',attrs,false),on))]}}])},[_c('span',[_vm._v("Regular")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-radio',_vm._g(_vm._b({staticClass:"ml-lg-6",attrs:{"color":"light-green lighten-1","value":"3","on-icon":"mdi-emoticon-happy-outline","off-icon":"mdi-emoticon-happy-outline"}},'v-radio',attrs,false),on))]}}])},[_c('span',[_vm._v("Bueno")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-radio',_vm._g(_vm._b({staticClass:"ml-lg-6",staticStyle:{"font-size":"140px !important"},attrs:{"color":"green","value":"4","on-icon":"mdi-emoticon-excited-outline","off-icon":"mdi-emoticon-excited-outline"}},'v-radio',attrs,false),on))]}}])},[_c('span',[_vm._v("Excelente")])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }