<template>
  <v-container fluid>
    <v-card>
      <v-card-text style="text-align: center">
        <v-row>
          <v-col cols="12" sm="4">
            <v-img
              src="@/assets/logo-certi-express.png"
              contain
              width="170"
              max-width="200"
              height="60"
            ></v-img>
          </v-col>
          <v-col cols="12" sm="8" class="ml-sm-n5">
            <strong style="display: block"
              >CDA CERTI EXPRESS ESTADIO S.A.S</strong
            >
            <label
              class="ml-n6"
              style="
                display: block;
                font-size: 14px !important;
                font-weight: '900';
              "
              >REGISTRAR MANTENIMIENTO</label
            >
          </v-col>
        </v-row>
      </v-card-text>
      <v-toolbar color="red darken-2" dark height="40" class="mt-n2">
        <v-spacer></v-spacer>
        <v-toolbar-title
          >REGISTRAR MANTENIMIENTO</v-toolbar-title
        >
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-card-text>
        <v-row class="mb-n7" cols="12">
          <v-col> <label>2022-02-04</label> {{ tipo_mantenimiento }} </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="3" md="3" lg="3">
            <label for="">Tipo Gestión</label>
            <v-autocomplete
              :items="tipos_gestiones"
              v-model="tipo_gestion"
              item-text="tipo_gestion"
              item-value="id_tipo_gestion"
              outlined
              dense
            >
            </v-autocomplete>
          </v-col>
          <v-col cols="12" sm="3" md="3" lg="3" v-if="tipo_gestion == 1">
            <label for="">Tipo Mantenimiento</label>
            <v-autocomplete
              :items="tipos_mantenimientos"
              v-model="tipo_mantenimiento_search"
              item-text="tipo_mantenimiento"
              item-value="id_tipo_mantenimiento"
              @change="buscarActividades"
              outlined
              dense
            >
            </v-autocomplete>
          </v-col>
          <v-col cols="12" sm="3" md="3" lg="3" v-if="tipo_gestion == 1">
            <label for="">Frecuencia</label>
            <v-autocomplete
              :items="frecuencias_mantenimientos"
              v-model="frecuencia_mantenimiento_search"
              item-text="frecuencia_mantenimiento"
              @change="buscarActividades"
              item-value="id_frecuencia_mantenimiento"
              outlined
              dense
            >
            </v-autocomplete>
          </v-col>
        </v-row>

        <!-- TABLA MANTENIMIENTOS -->
        <div
          v-if="
            tipo_gestion == 1 &&
            frecuencia_mantenimiento_search != '' &&
            tipo_mantenimiento_search != ''
          "
        >
          <v-toolbar color="grey darken-3" dark height="35">
            <v-spacer></v-spacer>
            <v-toolbar-title
              >OPERACIONES DE MANTENIMIENTO
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <!-- <v-btn color="primary" @click="modal_agregar_registro = true" small
              ><v-icon>mdi-plus</v-icon></v-btn
            > -->
          </v-toolbar>
          <div v-if="!mantemiento_realizado">
            <v-row class="mt-2 mb-2">
              <v-col cols="12" sm="6"> </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  dense
                  append-icon="mdi-magnify"
                  v-model="search_mantenimiento"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-data-table
              :headers="headers_mantenimiento"
              :items="actividades"
              :search="search_mantenimiento"
              dense
            >
              <template v-slot:item.revisado="props">
                <v-checkbox
                  dense
                  @change="cambioCheck(props.item)"
                ></v-checkbox>
              </template>
            </v-data-table>

           

            <v-row>
              <v-col cols="12">
                <label for="">Observaciones</label>
                <v-textarea
                  outlined
                  v-model="observacion_mantenimiento"
                ></v-textarea>
              </v-col>
            </v-row>

            <v-row>
              <v-spacer></v-spacer>
              <v-btn color="red" class="mr-2" @click="cancelarMantenimento" dark
                >Cancelar</v-btn
              >
              <v-btn
                color="primary"
                @click="guardarMantenimiento"
                :loading="loaderMantenimiento"
                >Guardar<template v-slot:loader>
                  <span class="custom-loader">
                    <v-icon light>mdi-cached</v-icon>
                  </span>
                </template></v-btn
              >
            </v-row>
          </div>
          <v-row v-else class="mt-4 mb-2"
            ><v-spacer></v-spacer
            ><span style="font-size: 24px">Mantenimiento ya realizado</span
            ><v-spacer></v-spacer
          ></v-row>
        </div>

        <!-- CALIBRACIONES-->
        <div v-if="tipo_gestion == 2">
          <v-toolbar color="grey darken-3" dark height="35">
            <v-spacer></v-spacer>
            <v-toolbar-title>CALIBRACIONES</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-form ref="formCalibraciones" v-if="!calibracion_realizada">
            <v-row class="mt-2">
              <v-col cols="12" sm="3">
                <label>Numero de certificado</label>
                <v-text-field
                  outlined
                  type="number"
                  dense
                  v-model="numero_certificado_calibracion"
                  :rules="basicRules"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="2" style="margin-top: 22px">
                <v-file-input
                  placeholder="Certificado"
                  v-model="certificado_calibracion"
          
                  accept="image/png, image/jpeg ,application/pdf"
                 
                  ref="certificado"
                  :rules="certificadoRules"
                  @click:prepend-inner="open"
                  outlined
                  dense
                ></v-file-input>
              </v-col>
            </v-row>
            <v-row class="mb-2 mr-2">
              <v-spacer></v-spacer>
              <v-btn color="red" class="mr-2" @click="cancelarMantenimento" dark
                >Cancelar</v-btn
              >
              <v-btn
                color="primary"
                @click="guardarCalibracion"
                :loading="loader_calibracion"
                >Guardar<template v-slot:loader>
                  <span class="custom-loader">
                    <v-icon light>mdi-cached</v-icon>
                  </span>
                </template></v-btn
              >
            </v-row>
          </v-form>
          <v-row v-else class="mt-4 mb-2"
            ><v-spacer></v-spacer
            ><span style="font-size: 24px">Calibracion ya realizada</span
            ><v-spacer></v-spacer
          ></v-row>
        </div>
        <!-- FIN CALIBRACIONES -->

        <!-- VERIFICACIONES -->
        <div v-if="tipo_gestion == 3">
          <v-toolbar color="grey darken-3" dark height="35">
            <v-spacer></v-spacer>
            <v-toolbar-title>VERIFICACIONES</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-form ref="formVerificaciones" v-if="!verificacion_realizada">
            <v-row class="mt-2">
              <v-col cols="12" sm="3">
                <label>Numero de certificado</label>
                <v-text-field
                  outlined
                  type="number"
                  dense
                  v-model="numero_certificado_verificacion"
                  :rules="basicRules"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="2" style="margin-top: 22px">
                <v-file-input
                  placeholder="Certificado"
                  v-model="certificado_verificacion"
              
                  accept="image/png, image/jpeg ,application/pdf"
          
                  ref="certificado"
                  :rules="certificadoRules"
                  @click:prepend-inner="open"
                  outlined
                  dense
                ></v-file-input>
              </v-col>
            </v-row>
            <v-row class="mb-2 mr-2">
              <v-spacer></v-spacer>
              <v-btn color="red" dark class="mr-2" @click="cancelarMantenimento"
                >Cancelar</v-btn
              >
              <v-btn
                color="primary"
                @click="guardarVerificacion"
                :loading="loader_verificacion"
                >Guardar<template v-slot:loader>
                  <span class="custom-loader">
                    <v-icon light>mdi-cached</v-icon>
                  </span>
                </template></v-btn
              >
            </v-row>
          </v-form>
          <v-row v-else class="mt-4 mb-2"
            ><v-spacer></v-spacer
            ><span style="font-size: 24px">Verificacion ya realizada</span
            ><v-spacer></v-spacer
          ></v-row>
        </div>
        <!--FIN  VERIFICACIONES -->
      </v-card-text>
    </v-card>

    
  </v-container>
</template>

<script>
import mantenimientos from "@/services/mantemiento";
export default {
  data: () => ({
    mantemientos_realizados: [],
    mantemiento_realizado: false,
    calibracion_realizada: false,
    verificacion_realizada: false,
    id_equipo: "",
    certificado_calibracion: [],
    modal_agregar_registro: false,
    tipo_mantenimiento_search: "",
    frecuencia_mantenimiento_search: "",
    tipo_gestion: "",
    loader: false,
    loaderMantenimiento:false,
    loader_calibracion: false,
    loader_verificacion: false,
    search_mantenimiento: "",
    actividades: [],
    actividad_mantenimiento: "",
    observacion_mantenimiento: "",
    numero_certificado_calibracion: "",
    numero_certificado_verificacion: "",
    certificado_verificacion: [],
    tipo_mantenimiento: "",
    frecuencia_mantenimiento: "",
    tipos_gestiones: [],
    frecuencias_mantenimientos: [],
    tipos_mantenimientos: [],
    headers_mantenimiento: [
      { text: "Actividad a desarrollar", value: "actividad_mantenimiento" },
      { text: "Chequeo", value: "revisado" },
    ],
    basicRules: [(value) => !!value || "El campo es requerido."],
    certificadoRules: [(value) => value != null || "Debe subir un certificado"],
  }),

  async created() {
    this.id_equipo = this.$route.params.id;
    await mantenimientos.llenarSelectsMantenimientos().then((response) => {
      this.tipos_gestiones = response.data["tiposGestiones"];
      this.tipos_mantenimientos = response.data["TiposMantenimientos"];
      this.frecuencias_mantenimientos =
        response.data["FrecuenciasMantenientos"];
      mantenimientos
        .verifyMaintenance({ id_equipo: this.id_equipo })
        .then((response) => {
          if (response.data["calibracion"].length != 0) {
            this.calibracion_realizada = true;
          }
          if (response.data["verificacion"].length != 0) {
            this.verificacion_realizada = true;
          }
          this.mantemientos_realizados = response.data["mantenimiento"];
        });
    });
  },

  methods: {
    /**Funcion que abre la carpeta de archivos al dar clic en el icono del input file */
    open() {
      this.$refs.certificado.$el.querySelector("input").click();
    },
    cambioCheck(item) {
      if (item.revisada == 1) {
        item.revisada = 0;
      } else {
        item.revisada = 1;
      }
      for (var i = 0; i < this.actividades.length; i++) {
        if (
          this.actividades[i]["id_actividad_mantenimiento"] ==
          item.id_actividad_mantenimiento
        ) {
          this.actividades[i]["revisada"] = item.revisada;
        }
      }
      console.log(this.actividades);
    },
    async buscarActividades() {
      if (
        this.tipo_mantenimiento_search != "" &&
        this.frecuencia_mantenimiento_search != ""
      ) {
        var realizado = false;
        this.mantemiento_realizado = false;

        for (var i = 0; i < this.mantemientos_realizados.length; i++) {
          if (
            this.mantemientos_realizados[i]["id_tipo_mantenimiento"] ==
              this.tipo_mantenimiento_search &&
            this.mantemientos_realizados[i]["id_frecuencia_mantenimiento"] ==
              this.frecuencia_mantenimiento_search
          ) {
            realizado = true;
          }
        }
        if (!realizado) {
          mantenimientos
            .buscarActividades({
              id_equipo: this.id_equipo,
              tipo_mantenimiento: this.tipo_mantenimiento_search,
              frecuencia: this.frecuencia_mantenimiento_search,
            })
            .then((response) => {
              for (var i = 0; i < response.data.length; i++) {
                Object.assign(response.data[i], { revisada: 0 });
              }
              this.actividades = response.data;
            });
        } else {
          this.mantemiento_realizado = true;
        }
      }
    },
    async guardarCalibracion() {
      this.loader_calibracion = true;
      if (Array.isArray(this.certificado_calibracion)) {
        if (this.certificado_calibracion.length == 0) {
          this.certificado_calibracion = null;
        }
      }
      if (this.$refs.formCalibraciones.validate()) {
        const calibracion = new FormData();

        calibracion.append("id_creador", 1);

        calibracion.append("id_equipo", this.id_equipo);
        calibracion.append(
          "calibracion_numero_certificado",
          this.numero_certificado_calibracion
        );
        calibracion.append(
          "certificado_calibracion",
          this.certificado_calibracion
        );

        await mantenimientos
          .guardarCalibracion(calibracion)
          .then((response) => {
            this.loader_calibracion = false;
            if (response.data["respuesta"] == true) {
              this.$swal
                .fire({
                  title: "Calibracion Guardada",
                  icon: "success",
                  timer: 2000,
                  allowEscapeKey: false,
                  allowOutsideClick: false,
                  allowEnterKey: false,
                  position: "center",
                  showConfirmButton: false,
                  confirmButtonText: "Yes, delete it!",
                })
                .then(() => {
                  this.$router.push({ name: "home" });
                });
            } else {
              console.log(response.data["error"]);
            }
          });
      }
    },
    async guardarVerificacion() {
      if (Array.isArray(this.certificado_verificacion)) {
        if (this.certificado_verificacion.length == 0) {
          this.certificado_verificacion = null;
        }
      }
      if (this.$refs.formVerificaciones.validate()) {
        this.loader_verificacion = true;
        const verificacion = new FormData();

        verificacion.append("id_creador", 1);
        verificacion.append("id_equipo", this.id_equipo);
        verificacion.append(
          "verificacion_numero_certificado",
          this.numero_certificado_verificacion
        );
        verificacion.append(
          "certificado_verificacion",
          this.certificado_verificacion
        );
        await mantenimientos
          .guardarVerificacion(verificacion)
          .then((response) => {
            this.loader_verificacion = false;
            if (response.data["respuesta"] == true) {
              this.$swal
                .fire({
                  title: "Verificacion Guardada",
                  icon: "success",
                  timer: 2000,
                  allowEscapeKey: false,
                  allowOutsideClick: false,
                  allowEnterKey: false,
                  position: "center",
                  showConfirmButton: false,
                  confirmButtonText: "Yes, delete it!",
                })
                .then(() => {
                  this.$router.push({ name: "home" });
                });
            } else {
              console.log(response.data["error"]);
            }
          });
      }
    },
    async guardarMantenimiento() {
      
      if (
        this.actividades.length != 0
      ) {
        this.loaderMantenimiento = true;
        await mantenimientos
          .guardarMantenimiento({
            id_equipo: this.id_equipo,
            id_creador: 1,
            actividades: this.actividades,
            observacion: this.observacion_mantenimiento,
          })
          .then((response) => {
             this.loaderMantenimiento = false;
            if (response.data["respuesta"] == true) {
              this.$swal
                .fire({
                  title: "Mantenimiento Guardado",
                  icon: "success",
                  timer: 2000,
                  allowEscapeKey: false,
                  allowOutsideClick: false,
                  allowEnterKey: false,
                  position: "center",
                  showConfirmButton: false,
                  confirmButtonText: "Yes, delete it!",
                })
                .then(() => {
                  this.$router.push({ name: "home" });
                });
            } else {
              console.log(response.data["error"]);
            }
          });

      } else {
        this.loader = false;
        this.$swal.fire({
          title: "Se necesitan actividades y la observacion",
          icon: "error",
          timer: 2000,
          allowEscapeKey: false,
          allowOutsideClick: false,
          allowEnterKey: false,
          position: "center",
          showConfirmButton: false,
          confirmButtonText: "Yes, delete it!",
        });
      }
    },

    cancelarMantenimento() {
      this.$router.push({ name: "home" });
    },

    cancelarCreacionActividad() {
      this.modal_agregar_registro = false;
      this.$refs.formActividades.reset();
    },
  },
};
</script>

<style scoped>
.v-file-input {
  flex-direction: row-reverse;
}
.swal2-popup {
  font-family: Arial, Helvetica, sans-serif;
}
.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}
@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
