<template>
  <div>
    <v-toolbar color="grey darken-3" dark height="40">
      <v-spacer></v-spacer>
      <v-toolbar-title class="mr-n16">Equipo Adicional</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn
        small
        color="success"
        class="ml-n6"
        @click="(dialogAdE = true), resetForm()"
      >
        <v-icon small>mdi-plus</v-icon>Equipo
      </v-btn>
    </v-toolbar>
    <v-dialog
      v-model="dialogAdE"
      origin="top center"
      persistent
      max-width="1000px"
    >
      <v-card>
        <v-card-title class="grey darken-3 white--text">
          <v-spacer></v-spacer>
          <span class="text-h5" v-if="!editEquipoAdd">Agregar Equipo</span>
          <span class="text-h5" v-else>Editar Equipo</span>
          <v-spacer></v-spacer>
          <v-btn
            class="mr-n5 mt-n2 white--text"
            @click="dialogAdE = false"
            text
            icon
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-form ref="formEquiAd" class="pl-10 pr-10 mt-7 mb-5">
          <v-row>
            <!-- NOMBRE -->
            <v-col cols="12" sm="3" class="mb-n5">
              <label>Nombre Periferico</label>
              <v-text-field
                outlined
                dense
                v-model="nombreEquiAd"
                :rules="basicRules"
              ></v-text-field>
            </v-col>

            <!-- MARCA -->
            <v-col cols="12" sm="3" class="mb-n5">
              <label
                >Marca<v-btn
                  v-show="crearMarca == false"
                  class="mt-n1"
                  x-small
                  text
                  color="success"
                  @click="crearMarca = true"
                  >[+ AGREGAR ]</v-btn
                >
                <v-btn
                  v-show="crearMarca"
                  class="mt-n1"
                  x-small
                  text
                  color="success"
                  @click="crearNuevaMarca()"
                  >[+ GUARDAR ]</v-btn
                >
                <v-btn
                  v-show="crearMarca"
                  class="mt-n1"
                  x-small
                  text
                  color="red"
                  @click="crearMarca = false"
                  >[ CANCELAR ]</v-btn
                >
              </label>
              <v-autocomplete
                v-model="marcaEquiAd"
                v-show="crearMarca == false"
                :items="marcas"
                placeholder="Seleccione una opcion"
                item-text="marca"
                :rules="selectRules"
                item-value="id_marca"
                disable-lookup
                outlined
                dense
              >
              </v-autocomplete>
              <v-text-field
                outlined
                placeholder="Marca nueva"
                v-show="crearMarca == true"
                dense
                v-model="marcaNew"
              ></v-text-field>
            </v-col>

            <!-- MODELO -->
            <v-col cols="12" sm="3" class="mb-n5">
              <label>Modelo</label>
              <v-text-field
                outlined
                dense
                v-model="modeloEquiAd"
                :rules="basicRules"
              ></v-text-field>
            </v-col>

            <!-- SERIAL -->
            <v-col cols="12" sm="3" class="mb-n5">
              <label>Serial</label>
              <v-text-field
                outlined
                dense
                v-model="SerialEquiAd"
                :rules="basicRules"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <!-- ID -->
            <v-col cols="12" sm="3">
              <label>ID</label>
              <v-text-field
                outlined
                dense
                v-model="idEquiAd"
                :rules="basicRules"
              ></v-text-field>
            </v-col>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-col v-if="!editEquipoAdd">
              <v-btn
                class="ml-12 mt-5"
                large
                :loading="loader"
                :disabled="loader"
                color="success"
                @click="crearEquipoAdicional"
              >
                <v-icon left> mdi-plus </v-icon>
                Agregar
                <template v-slot:loader>
                  <span class="custom-loader">
                    <v-icon light>mdi-cached</v-icon>
                  </span>
                </template>
              </v-btn>
            </v-col>

            <v-col v-else>
              <v-btn
                class="ml-12 mt-5"
                large
                :loading="loader"
                :disabled="loader"
                color="success"
                @click="actualizarEquipoAd"
              >
                Actualizar
                <template v-slot:loader>
                  <span class="custom-loader">
                    <v-icon light>mdi-cached</v-icon>
                  </span>
                </template>
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card>
    </v-dialog>
    <!--  DATA TABLE CREAR -->
    <v-data-table
      :headers="headers"
      :items="equiposAdShow"
      v-if="!edit"
      :items-per-page="5"
      class="elevation-1"
      :footer-props="{
        itemsPerPageText: 'N° Items',
        itemsPerPageAllText: 'Todos',
      }"
    >
      <template slot="no-data"> No hay Equipos Agregados </template>

      <template v-slot:item.id_marca="marca">
        <div v-for="brand in marcas" :key="brand.id">
          <span v-if="marca.item.id_marca == brand.id_marca">{{
            brand.marca
          }}</span>
        </div>
      </template>
    </v-data-table>

    <!--  DATA TABLE EDITAR -->
    <v-data-table
      :headers="headersEdit"
      :items="equiposAdEditar"
      v-if="edit"
      :items-per-page="5"
      class="elevation-1"
      :footer-props="{
        itemsPerPageText: 'N° Items',
        itemsPerPageAllText: 'Todos',
      }"
    >
      <template slot="no-data"> No hay Equipos Agregados </template>

      <template v-slot:item.id_marca="marca">
        <div v-for="brand in marcas" :key="brand.id">
          <span v-if="marca.item.id_marca == brand.id_marca">{{
            brand.marca
          }}</span>
        </div>
      </template>

      <template v-slot:item.opciones="opcion">
        <v-icon @click="editarEquipoAd(opcion.item)"> mdi-pencil </v-icon>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";

export default {
  name: "createAditionalDevice",
  data: () => ({
    headers: [
      { text: "Periferico", value: "elemento_adicional", align: "center" },
      { text: "Marca", value: "id_marca", align: "center" },
      { text: "Modelo", value: "elemento_adicional_modelo", align: "center" },
      { text: "Serial", value: "elemento_adicional_serial", align: "center" },
      { text: "ID", value: "elemento_adicional_codigo", align: "center" },
    ],
    headersEdit: [
      { text: "Periferico", value: "elemento_adicional", align: "center" },
      { text: "Marca", value: "id_marca", align: "center" },
      { text: "Modelo", value: "elemento_adicional_modelo", align: "center" },
      { text: "Serial", value: "elemento_adicional_serial", align: "center" },
      { text: "ID", value: "elemento_adicional_codigo", align: "center" },
      { text: "Accion", value: "opciones", align: "center" },
    ],
    loader: null,
    marcaNew: "",
    crearMarca: false,
    dialogAdE: false,
    editEquipoAdd: false,
    nombreEquiAd: "",
    equiposAdEditar: [],
    idAditionalItem: "",
    edit: false,
    idEditar: "",
    marcaEquiAd: "",
    modeloEquiAd: "",
    SerialEquiAd: "",
    idEquiAd: "",
    basicRules: [(value) => !!value || "El campo es requerido."],
    selectRules: [(value) => !!value || "Debe seleccionar una opcion."],
  }),
  computed: {
    ...mapState({
      marcas: "marcas",
      id: "device_id",
      equiposAdShow: "equiposAdShow",
    }),
  },
  methods: {
    ...mapMutations([
      /**Funcion que agrega un equipo adicional a las equiposAdShow en el store */
      "insertEquipAd",
      /** Funcion que agrega una nueva marca a la lista de marcas en el store*/ 
       "agregarMarca"
      ]),
    
    /**
     * Funcion que asigna los equipos adicionales que contiene un equipo
     * @param {number} id el id del equipo a editar
     * @param {object} equiposAd datos de todos los equipos adicionales que pertenecen a el equipo en especifico
     * @returns {void}
     */
    editar(id, equiposAd) {
      this.idEditar = id;
      this.equiposAdEditar = equiposAd;
    },
    /** Funcion que permite agregar una nueva marca al select marcas */
    crearNuevaMarca() {
      services.createBrand({ brand: this.marcaNew }).then((response) => {
        this.crearMarca = false;
        this.agregarMarca({ id_marca: response.data, marca: this.marcaNew });
        this.marcaEquiAd = response.data;
        this.marcaNew = "";
      });
    },
    /**
     * Funcion que activa el modal de editar y rellena los campos con los datos 
     * @param {object} item datos del equipo adicional a modificar
     */
    editarEquipoAd(item) {
      this.dialogAdE = true;
      this.editEquipoAdd = true;
      this.idAditionalItem = item.id_elemento_adicional;
      this.nombreEquiAd = item.elemento_adicional;
      this.marcaEquiAd = item.id_marca;
      this.modeloEquiAd = item.elemento_adicional_modelo;
      this.SerialEquiAd = item.elemento_adicional_serial;
      this.idEquiAd = item.elemento_adicional_codigo;
    },
    /**Funcion que permite agregar un equipo adicional a un equipo en especifico */
    crearEquipoAdicional() {
      if (this.$refs.formEquiAd.validate()) {
        this.loader = true;
        if (this.edit == true) {
          var idEquipo = this.idEditar;
        } else {
          var idEquipo = this.id;
        }
        services
          .createAditionalDevice({
            elemento_adicional: this.nombreEquiAd,
            id_marca: this.marcaEquiAd,
            elemento_adicional_modelo: this.modeloEquiAd,
            elemento_adicional_serial: this.SerialEquiAd,
            elemento_adicional_codigo: this.idEquiAd,
            device_id: idEquipo,
          })
          .then((response) => {
            this.loader = false;
            if (Number.isInteger(response.data)) {
              this.dialogAdE = false;
              this.$swal
                .fire({
                  title: "Periferico Agregado",
                  icon: "success",
                  timer: 2000,
                  allowEscapeKey: false,
                  allowOutsideClick: false,
                  allowEnterKey: false,
                  position: "center",
                  showConfirmButton: false,
                  confirmButtonText: "Yes, delete it!",
                })
                .then(() => {
                  var EquipoAdd = {
                    id_elemento_adicional: response.data,
                    elemento_adicional: this.nombreEquiAd,
                    id_marca: this.marcaEquiAd,
                    elemento_adicional_modelo: this.modeloEquiAd,
                    elemento_adicional_serial: this.SerialEquiAd,
                    elemento_adicional_codigo: this.idEquiAd,
                  };

                  if (this.edit == true) {
                    this.equiposAdEditar.push(EquipoAdd);
                  } else {
                    this.insertEquipAd(EquipoAdd);
                  }

                  this.$refs.formEquiAd.reset();
                });
            } else {
              console.log("no se pudo agregar");
            }
          })

          .catch((e) => {
            console.log("Error al BD" + e);
          });
      } else {
        console.log("no esta validado");
      }
    },
    /**Funcion que actualiza los datos de un equipo adicional*/
    actualizarEquipoAd() {
      if (this.$refs.formEquiAd.validate()) {
        var equipoAddEditado = {
          elemento_adicional: this.nombreEquiAd,
          id_marca: this.marcaEquiAd,
          elemento_adicional_modelo: this.modeloEquiAd,
          elemento_adicional_serial: this.SerialEquiAd,
          elemento_adicional_codigo: this.idEquiAd,
          id_elemento_adicional: this.idAditionalItem,
        };
        this.loader = true;
        services.updateAditionalDevice(equipoAddEditado).then((response) => {
          this.loader = false;
          if (response.data == true) {
            this.dialogAdE = false;
            this.$swal
              .fire({
                title: "Periferico Actualizado",
                icon: "success",
                timer: 2000,
                allowEscapeKey: false,
                allowOutsideClick: false,
                allowEnterKey: false,
                position: "center",
                showConfirmButton: false,
                confirmButtonText: "Yes, delete it!",
              })
              .then(() => {
                this.editEquipoAdd = false;
                for (var i = 0; i < this.equiposAdEditar.length; i++) {
                  if (this.equiposAdEditar[i]["id_elemento_adicional"] == this.idAditionalItem) {
                    this.equiposAdEditar.splice(i, 1, equipoAddEditado);
                  }
                }
              });
          } else {
            console.log("no se pudo actualizar");
          }
        });
      } else {
        console.log("no esta validado");
      }
    },
    /**funcion que cierra el modal y reinicia el formulario*/
    cancelar() {
      this.dialogVar = false;
      this.resetForm();
      this.editEquipoAdd = false;
    },
    /**Funcion que limpia los campos del formulario */
    resetForm() {
      this.$nextTick(() => {
        this.$refs.formEquiAd.reset();
      });
    },
  },
};
</script>

<style></style>
