<template>
<section>
    <div>
        <form @submit.prevent="login">
            <h1>Login</h1>

            <p>Bienvenidos a HermesRTM</p>
            <label>Usuario</label>
            <input v-model="loginData.document" type="text" placeholder="Usuario">
            <label>Contraseña</label>
            <input v-model="loginData.password" type="password" placeholder="Contraseña">
            <button type="submit">Ingresar</button>
        </form>
    </div>
</section>
</template>

<script>
import loginService from '@/services/loginService.js'
export default {
    name: 'Login',
    data: () => ({
        loginData: {
            document: '',
            password: ''
        }
    }),
    methods: {
        async login() {
            await loginService.login(this.loginData).then(response => {
                this.$store.commit("setToken", response.data.token);
                this.$store.commit("setPermisos", response.data.permisos);
                this.$store.commit("setRoles", response.data.rol);
                this.$router.push({
                  name: "Home",
                });
            });   
        }
    },
    beforeCreate(){
        if(this.$store.state.token) {
            this.$router.push({
              name: "Home",
            });
        }
    }
}
</script>

<style lang="scss" scoped>
section {
    height: 100%;
    min-height: 100%;
    background-color: #a9b6c3;
    display: flex;
    flex-flow: row-reverse;

    div:nth-child(1) {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        max-width: 100%;

        form {
            background-color: white;
            padding: 6rem;
            border-radius: 6px;
            display: flex;
            flex-flow: column;
            align-items: center;
            justify-content: center;
            width: fit-content;

            h1 {
                color: #74839b;
                font-size: 4rem;
                font-style: italic;
            }

            p {
                color: #7a8ea1;
                font-size: 2rem;
            }

            label {
                color: #a9b6c3;
                text-align: initial;
                width: 100%;
                font-size: 1.5rem;
            }

            input {
                border: none;
                border-bottom: #a4918f solid 1px;
                width: 100%;
                margin-top: 1rem;
                margin-bottom: 1rem;
                font-size: 1.5rem;
            }
            input::placeholder {
                color: #a9b6c3;
            }

            button {
                background-color: #a9b6c3;
                font-size: 1.5rem;
                color: white;
                margin: 1rem;
                padding: 1rem;
                border-radius: 10px;
            }
        }
    }
}
</style>
