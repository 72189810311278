<template>
  <v-container fluid>
    <v-card>
      <v-card-text style="text-align: center">
        <v-row>
          <v-col cols="12" sm="4">
            <!-- <v-img
              src="@/assets/logo-certi-express.png"
              contain
              width="170"
              max-width="200"
              height="60"
            ></v-img> -->
            <img
              src="@/assets/logo-certi-express.png"
              contain
              width="170"
              style="max-width: 200px"
              height="60"
            />
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="12" sm="8" class="ml-sm-n5">
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <strong style="display: block"
              >CDA CERTI EXPRESS ESTADIO S.A.S</strong
            >
            <label
              class="ml-n6"
              style="
                display: block;
                font-size: 14px !important;
                font-weight: '900';
              "
              >REGISTRAR MANTENIMIENTO</label
            >
          </v-col>
        </v-row>
      </v-card-text>
      <v-toolbar color="red darken-2" dark height="40" class="mt-n2">
        <v-spacer></v-spacer>
        <v-toolbar-title>REGISTRAR MANTENIMIENTO</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-card-text>
        <v-row class="mt-1">
          <v-spacer></v-spacer>
          <v-col cols="4">
            <v-text-field
              v-model="search"
              prepend-icon="mdi-magnify"
              dense
            ></v-text-field>
          </v-col>
        </v-row>
        <v-data-table
          :headers="headers"
          :items="equipos"
          :search="search"
          :items-per-page="5"
          class="elevation-0"
          :footer-props="{
            itemsPerPageText: 'N° Items',
            itemsPerPageAllText: 'Todos',
          }"
        >
          <template slot="no-data"> No se encontraron resultados </template>
          <template v-slot:item.acciones="props">
            <v-btn
              v-text="'Manteniniento'"
              color="success"
              @click="
                $router.push({
                  name: 'registrar-mantenimiento',
                  params: { id: props.item.id_equipo },
                })
              "
            ></v-btn>
          </template>
          <template v-slot:item.opciones="props">
            <!-- <div class="text-center">
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on">mdi-dots-vertical</v-icon>
                </template>
                <v-list>
                  <v-list-item
                    class="mx-4"
                    v-for="(item, index) in items_opciones"
                    :key="index"
                    @click="opcionesMenu(props.item, item.value)"
                  >
                    <v-list-item-title class="personalizar">

                   {{ item.title }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div> -->
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import services from "@/services";

export default {
  data: () => ({
    search: "",
    equipos: [],
    headers: [
      { text: "Opciones", value: "opciones", width: "120px", align: "center" },
      { text: "Nombre", value: "equipo" },
      { text: "Ubicacion", value: "ubicacion" },
      { text: "Acciones", value: "acciones", width: "200px", align: "center" },
    ],
    items_opciones: [
      {
        title: "Registrar Mantenimiento",
        value: 1,
      },
    ],
  }),
  async created() {
    await services.searchDevices({ status: 1, type: 3 }).then((response) => {
      for (var i = 0; i < response.data.length; i++) {
        this.equipos.push(response.data[i]);
      }
    });
  },
  methods: {
    opcionesMenu(item, value) {
      switch (value) {
        case 1:
          this.$router.push({
            name: "registrar-mantenimiento",
            params: { id: item.id_equipo },
          });
      }
    },
  },
};
</script>

<style></style>
