<template>
  <v-container fluid>
    <v-card>
      <v-card-text style="text-align: center">
        <v-row>
          <v-col cols="12" sm="4">
            <v-img
              src="@/assets/logo-certi-express.png"
              contain
              width="170"
              max-width="200"
              height="60"
            ></v-img>
          </v-col>

          <v-col cols="12" sm="8" class="ml-sm-n5">
            <strong style="display: block"
              >CDA CERTI EXPRESS ESTADIO S.A.S</strong
            >
            <label
              class="ml-n6"
              style="
                display: block;
                font-size: 14px !important;
                font-weight: '900';
              "
              >REGISTRO DE ACTIVIDADES</label
            >
          </v-col>
        </v-row>
      </v-card-text>
      <v-toolbar color="red darken-2" dark height="40" class="mt-n2">
        <v-spacer></v-spacer>
        <v-toolbar-title>REGISTRO DE ACTIVIDADES</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-card-text>
        <!-- Modal agregar registro -->

        <v-form ref="formActividades">
          <v-row class="mt-2">
            <v-col cols="12" sm="3">
              <label for="">Equipo</label>
              <v-autocomplete
                :items="equipos"
                v-model="equipo"
                item-text="equipo"
                item-value="id_equipo"
                :rules="equipoRules"
                @change="selectEquipo"
                outlined
                dense
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="12" sm="3" v-if="mostrar_selects">
              <label for="">Actividad</label>
              <v-text-field
                dense
                outlined
                v-model="actividad_mantenimiento"
                :rules="actividadRules"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="3" v-if="mostrar_selects">
              <label for="">Tipo de Mantenimiento</label>
              <v-autocomplete
                :items="tipos_mantenimientos"
                v-model="tipo_mantenimiento"
                item-text="tipo_mantenimiento"
                item-value="id_tipo_mantenimiento"
                :rules="tipoMantenimientoRules"
                outlined
                dense
              >
              </v-autocomplete>
            </v-col>

            <v-col cols="12" sm="3" v-if="mostrar_selects">
              <label
                >Frecuencia<v-btn
                  v-show="crearFrecuencia == false"
                  class="mt-n1"
                  x-small
                  text
                  color="success"
                  @click="crearFrecuencia = true"
                  >[+ AGREGAR ]</v-btn
                >
                <v-btn
                  v-show="crearFrecuencia"
                  class="mt-n1"
                  x-small
                  text
                  color="success"
                  @click="crearNuevaFrecuencia()"
                  >[+ GUARDAR ]</v-btn
                >
                <v-btn
                  v-show="crearFrecuencia"
                  class="mt-n1"
                  x-small
                  text
                  color="red"
                  @click="crearFrecuencia = false"
                  >[ CANCELAR ]</v-btn
                ></label
              >

              <v-autocomplete
                :items="frecuencias_mantenimientos"
                v-show="!crearFrecuencia"
                v-model="frecuencia_mantenimiento"
                item-text="frecuencia_mantenimiento"
                item-value="id_frecuencia_mantenimiento"
                :rules="frecuenciaRules"
                outlined
                dense
              >
              </v-autocomplete>
              <v-text-field
                outlined
                placeholder="Frecuencia nueva"
                v-show="crearFrecuencia"
                dense
                v-model="newFrecuencia"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions v-if="mostrar_selects">
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="crearActividad" :loading="loader"
          >Guardar
          <template v-slot:loader>
            <span class="custom-loader">
              <v-icon light>mdi-cached</v-icon>
            </span>
          </template></v-btn
        >
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import mantenimientos from "@/services/mantemiento";
import services from "@/services";

export default {
  data: () => ({
    equipo: "",
    loader: false,
    crearFrecuencia: false,
    mostrar_selects: false,
    equipos: [],
    frecuencia_mantenimiento: "",
    newFrecuencia: "",
    actividad_mantenimiento: "",
    tipo_mantenimiento: "",
    frecuencias_mantenimientos: [],
    tipos_mantenimientos: [],

    equipoRules: [(value) => !!value || "Debe seleccionar un equipo."],
    actividadRules: [(value) => !!value || "Debe ingresar una actividad."],
    tipoMantenimientoRules: [
      (value) => !!value || "Debe seleccionar un tipo de mantenimiento.",
    ],
    frecuenciaRules: [(value) => !!value || "Debe seleccionar una frecuencia."],
  }),
  async created() {
    this.id_equipo = this.$route.params.id;
    await services.searchDevices({ status: 1, type: 3 }).then((response) => {
      for (var i = 0; i < response.data.length; i++) {
        this.equipos.push(response.data[i]);
      }
    });
    await mantenimientos.llenarSelectsMantenimientos().then((response) => {
      this.tipos_mantenimientos = response.data["TiposMantenimientos"];
      this.frecuencias_mantenimientos =
        response.data["FrecuenciasMantenientos"];
    });
  },
  methods: {
    selectEquipo() {
      this.mostrar_selects = this.mostrar_selects == true ? false : true;
    },

    async crearActividad() {
      if (this.$refs.formActividades.validate()) {
        this.loader = true;
        mantenimientos
          .crearActividad({
            id_equipo: this.equipo,
            id_tipo_mantenimiento: this.tipo_mantenimiento,
            id_frecuencia_mantenimiento: this.frecuencia_mantenimiento,
            actividad_mantenimiento: this.actividad_mantenimiento,
          })
          .then((response) => {
            console.log(response.data);
            this.loader = false;
            if (response.data["respuesta"] == true) {
              this.modal_agregar_registro = false;
              this.$swal
                .fire({
                  title: "Actividad Agregada",
                  icon: "success",
                  timer: 2000,
                  allowEscapeKey: false,
                  allowOutsideClick: false,
                  allowEnterKey: false,
                  position: "center",
                  showConfirmButton: false,
                  confirmButtonText: "Yes, delete it!",
                })
                .then(() => {
                  this.mostrar_selects = false;
                  this.$refs.formActividades.reset();
                });
            } else {
              console.log(response.data["error"]);
            }
          });
      }
    },

    crearNuevaFrecuencia() {
      mantenimientos
        .crearNuevaFrecuencia({ frecuencia_mantenimiento: this.newFrecuencia })
        .then((response) => {
          if (response.data["respuesta"] == true) {
            this.crearFrecuencia = false;
            this.frecuencias_mantenimientos.push({
              id_frecuencia_mantenimiento: response.data['id_frecuencia'],
              frecuencia_mantenimiento: this.newFrecuencia,
            });
            this.frecuencia_mantenimiento = response.data['id_frecuencia'];
            this.newFrecuencia = "";
          } else {
            console.log(response.data["error"]);
          }
        });
    },
  },
};
</script>

<style>
.swal2-popup {
  font-family: Arial, Helvetica, sans-serif;
}
.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}
@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
