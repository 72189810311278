<template>
  <div>
    <v-toolbar color="grey darken-3" dark height="40">
      <v-spacer></v-spacer>
      <v-toolbar-title>Datos Proveedor</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-form ref="createProvider" class="pl-10 pr-10 mt-7 mb-5">
      <v-row>
        <!-- NOMBRE -->
        <v-col cols="12" sm="3">
          <v-row>
            <v-col cols="12" sm="6">
                <label>Nombre proveedor</label>
            </v-col>
            <v-col cols="12" sm="2"  class="ml-n6" ><v-btn
              v-show="crearProvedor == false"
              class="mt-n1"
              x-small
              text
              color="success"
              @click="crearProvedor = true,resetForm(),nombreProveedor= '',newNombreP= ''"
              >[+ AGREGAR ]</v-btn
            ></v-col>
            
            <v-col cols="12" sm="2" class="ml-n15" ><v-btn
              v-show="crearProvedor"
              class="mt-n1"
              x-small
              text
              color="success"
              @click="crearNuevoProvedor()"
              >[+GUARDAR]</v-btn
            ></v-col>
            <v-col sm="2" class="mr-n15">
              <v-btn
              v-show="crearProvedor"
              class="mt-n1 ml-6"
              x-small
              text
              color="red"
              @click="crearProvedor = false,resetForm()"
              >[ CANCELAR ]</v-btn>
            </v-col>
          </v-row>

          <v-autocomplete
            v-model="idProveedor"
            v-if="crearProvedor == false"
            :items="proveedores"
            placeholder="Seleccione una opcion"
            item-text="proveedor"
            :rules="selectRules"
            @change="completar(idProveedor)"
            item-value="id_proveedor"
            disable-lookup
            outlined
            dense
          >
          </v-autocomplete>
          <v-text-field
             v-if="crearProvedor == true"
             placeholder="Nuevo Provedor"
            outlined
            dense
            v-model="newNombreP"
            :rules="basicRules"
          ></v-text-field>
        </v-col>

        <!-- TELEFONO -->
        <v-col cols="12" sm="3">
          <label>Telefono</label>
          <v-text-field
            outlined
            type="number"
            dense
            :disabled="!crearProvedor"
            v-model="telefonoProveedor"
            :rules="phoneRules"
            maxlength="8"
          ></v-text-field>
        </v-col>

        <!-- CELULAR -->
        <v-col cols="12" sm="3">
          <label>Celular</label>
          <v-text-field
            outlined
            dense
            :disabled="!crearProvedor"
            :rules="cellphoneRules"
            v-model="celularProveedor"
            maxlength="11"
          ></v-text-field>
        </v-col>

        <!-- DIRECCION -->
        <v-col cols="12" sm="3">
          <label>Direccion</label>
          <v-text-field
            outlined
            dense
            :disabled="!crearProvedor"
            v-model="direccionProveedor"
            :rules="adressRules"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <!-- EMAIL -->
        <v-col cols="12" sm="3">
          <label>Email</label>
          <v-text-field outlined dense :disabled="!crearProvedor" v-model="emailProveedor" :rules="emailRules"></v-text-field>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import services from '@/services';
import { mapMutations } from "vuex";
import { mapFields } from "vuex-map-fields";

export default {
  name: "createProvider",
  data: () => ({
    crearProvedor: false,
    newNombreP: '',
    edit:false,
    basicRules: [(value) => !!value || "El campo es requerido."], 
    selectRules: [(value) => !!value || "Debe seleccionar una opcion."],
    emailRules: [
      (value) => !!value || "El email es requerido.",
      (value) => (value || "").length <= 40 || "Maximo 40 caracteres",
      (value) => {
        const pattern =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "Email debe ser valido.";
      },
    ],
    phoneRules: [
      (value) => !!value || "El telefono es requerido.",
      (value) => (value || "").length <= 7 || "Maximo 7 digitos",
      (value) => {
        const pattern = /^\d+$/;
        return pattern.test(value) || "Deben ser solo numeros.";
      },
    ],
    cellphoneRules: [
      (value) => !!value || "El celular es requerido.",
      (value) => (value || "").length <= 10 || "Maximo 10 digitos",
      (value) => {
        const pattern = /^\d+$/;
        return pattern.test(value) || "Deben ser solo numeros.";
      },
    ],
    adressRules: [
      (value) => !!value || "La direccion es requerida.",
      (value) => {
        const pattern = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]/;
        return (
          pattern.test(value) ||
          "Deben contener al menos una letra y un numero."
        );
      },
    ],
  }),
  computed: {
    ...mapFields([
      "provider.nombreProveedor",
      "provider.idProveedor",
      "provider.telefonoProveedor",
      "provider.celularProveedor",
      "provider.direccionProveedor",
      "provider.emailProveedor",
      "proveedores"
    ]),  
  },
  methods: {
    /**Funcion que permite agregar un nuevo proveedor a la lista de proveedores */
    ...mapMutations(['insertProvider']),
   /**
    * Funcion que autocompleta los datos del proveedor
    * 
    * @param {number} idProveedor id del proveedor
    * @returns {void}
    */
    completar(idProveedor) {
      if (idProveedor == null) {
        this.$refs.createProvider.reset();
        this.nombreProveedor = "";
      } else {
        for (var i = 0; i < this.proveedores.length; i++) {
          if (this.proveedores[i]["id_proveedor"] == idProveedor) {
            this.nombreProveedor = this.proveedores[i]["proveedor"];
            this.telefonoProveedor = this.proveedores[i]["proveedor_telefono"];
            this.celularProveedor = this.proveedores[i]["proveedor_celular"];
            this.direccionProveedor = this.proveedores[i]["proveedor_direccion"];
            this.emailProveedor = this.proveedores[i]["proveedor_email"].trim();
          }
        }
      }
    },
    /**Funcion que crea un nuevo proveedor y lo agrega a la luista de proveedores*/
    crearNuevoProvedor(){
      if(this.$refs.createProvider.validate()){
        services.createNewProvider({
          name:this.newNombreP,
          phone:this.telefonoProveedor,
          cell_phone: this.celularProveedor,
          adress: this.direccionProveedor,
          email:this.emailProveedor
        }).then((response)=>{

          var newProvider = {
            id_proveedor: response.data,
            proveedor : this.newNombreP,
            proveedor_telefono : this.telefonoProveedor,
            proveedor_celular: this.celularProveedor,
            proveedor_direccion: this.direccionProveedor,
            proveedor_email: this.emailProveedor
            
          };

          this.insertProvider(newProvider);

          this.idProveedor = response.data;
          this.crearProvedor = false;
          this.nombreProveedor = this.newNombreP;
        })
      }else{
        console.log('No esta validado');
      }
    },
    /**Funcion que valida que los campos del formulario de proveedor si esten completos */
    validateForm() {
      if (this.$refs.createProvider.validate()) {
        return true;
      } else {
        return false;
      }
    },
    /**Funcion que limpia los campos del formulario */
    resetForm() {
      this.$refs.createProvider.reset();
    },
  },
};
</script>

<style></style>
