import apiClient from "./indexToken.js";

const ruta = "/pqrs/";

export default {
  /**
   * Servicio que trae todos los tipos de tramites o pqrs en db
   * @returns {Array.<Object>} tipos de tramites o pqrs en db
   */
  getTiposPqrs() {
    return apiClient.get(ruta + "tipos-pqrs");
  },

  /**
   * Servicio que trae todos los medios de respuesta en db
   * @returns {Array.<Object>} medios de respuesta en db
   */
  getMediosRespuesta() {
    return apiClient.get(ruta + "medios-respuesta");
  },

  getTiposProcesosVinculados() {
    return apiClient.get(ruta + "tipos-procesos-vinculados");
  },

  buscarUsuario(data_usuario) {
    return apiClient.get(ruta + "buscar-usuario/" + data_usuario);
  },

  /**
   * Servicio que guarda todos los datos de una pqrs en db
   * @param {FormData} pqrs datos de la pqrs a guardar
   * @returns {Number} id de la pqrs que se acabo de crear
   */
  guardarPqrs(pqrs) {
    return apiClient.post(ruta + "guardar-pqrs", pqrs);
  },

  designarFuncionarioPqrs(data_pqrs) {
    return apiClient.post(ruta + "designar-funcionario-pqrs", data_pqrs);
  },

  actualizarInvestigacionPqrs(data_pqrs) {
    return apiClient.post(ruta + "actualizar-investigacion-pqrs", data_pqrs);
  },

  finalizarPqrs(data_pqrs) {
    return apiClient.post(ruta + "finalizar-pqrs", data_pqrs);
  },

  /**
   * Servicio que consulta las pqrs guardadas en db
   * @param {Object} params parametros de la consulta de pqrs
   * @returns {Array.<Object>} pqrs
   */
  consultarPqrs(params) {
    return apiClient.post(ruta + "consultar-pqrs", params);
  },
};
