<template>
<v-container fluid>
    <v-toolbar container color="grey darken-3" class="mt-n3 mb-5" dark height="40">
        <v-spacer></v-spacer>
        <v-toolbar-title>RTM Día</v-toolbar-title>
        <v-spacer></v-spacer>
    </v-toolbar>
    <v-row>
        <v-col cols="12" sm="8"></v-col>
        <v-col cols="12" sm="4">
            <v-text-field dense label="Buscar" append-icon="mdi-magnify"></v-text-field>
        </v-col>
    </v-row>

      

    <v-data-table :items="info_datatable" :headers="headers" dense>
        <!-- OPCIONES DEL MENU -->
        <template v-slot:[`item.opciones`]="props">
            <div class="text-center">
                <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on">mdi-dots-vertical</v-icon>
                    </template>
                    <v-list>
                        <v-list-item class="mx-4" v-for="(item, index) in items_opciones" :key="index" @click="activarOpcionesMenu(props.item, item.value)">
                            <v-list-item-title class="personalizar">
                                {{ item.title }}
                            </v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </div>
        </template>

        <!-- EDITAR CELULAR-->
        <template v-slot:item.celular="props">
            <v-dialog max-width="400px">
                <template v-slot:activator="{ on, attrs }">
                    <p v-bind="attrs" v-on="on">{{props.item.celular}}</p>
                </template>
                <v-card>
                    <ModalEditarCelular :nombre="props.item.nombre" :celular="props.item.celular" />
                </v-card>
            </v-dialog>
        </template>

        <template v-slot:item.placa="props">
            <v-dialog max-width="400px">
                <template v-slot:activator="{ on, attrs }">
                    <p v-bind="attrs" v-on="on">{{props.item.celular}}</p>
                </template>
                <v-card>
                    <ModalEditarCelular  :nombre="props.item.nombre" :placa="props.item.placa" />
                </v-card>
            </v-dialog>
        </template>

              <template v-slot:item.clase="props">
            <v-dialog max-width="250px">
                <template v-slot:activator="{ on, attrs }">
                    <p v-bind="attrs" v-on="on">{{props.item.celular}}</p>
                </template>
                <v-card>    
                    <ModalTipoRTM :nombre="props.item.nombre" :placa="props.item.placa" />
                </v-card>
            </v-dialog>
        </template>
        <template v-slot:item.certificado_digital="props">
            <p style="color:blue; cursor:pointer;">
                {{props.item.certificado_digital == 0? 'No' : 'Si'}}
            </p>
        </template>
   

    </v-data-table>

    <!-- Modales  -->
    <v-dialog v-model="modal_documentos" width="1200">
        <ModalDocumentos @cerrar-modal="modal_documentos = false" />
    </v-dialog>

    <!-- Modal Enviar Mensaje -->
    <v-dialog v-model="modal_enviar_mensaje" width="440" v-if="modal_enviar_mensaje">
        <ModalEnviarMensaje @cerrar-modal="modal_enviar_mensaje = false" />
    </v-dialog>

    <!-- Modal Enviar Certificado -->
    <v-dialog v-model="modal_enviar_certificado" width="340">
        <v-card class="text-center">
            <h2 class="pt-5">Enviar Mensaje</h2>

            <v-card-text> ¿Desea enviar un mensaje de texto? </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red" dark @click="modal_enviar_certificado = false">
                    <v-icon> mdi-cancel </v-icon>
                </v-btn>
                <v-btn color="primary" @click="modal_enviar_certificado = false">
                    <v-icon>mdi-check</v-icon>
                </v-btn>
                <v-spacer></v-spacer>
            </v-card-actions>
        </v-card>
    </v-dialog>

    <!-- Modal Subir certificado -->
    <v-dialog v-model="modal_subir_certificados" width="500">
        <ModalSubirCertificados @cerrar-modal="modal_subir_certificados = false" />
    </v-dialog>

    <v-dialog v-model="modal_eliminar_registro" width="300">
            <v-card>
                <v-toolbar color="grey darken-3" height="40"></v-toolbar>
                <v-card-text class="mt-3" >
                    <v-row>
                        <v-spacer></v-spacer>
                    <v-icon color="red" style="font-size:100px">mdi-cancel</v-icon>  
                    <v-spacer></v-spacer>
                    </v-row>
                    <v-row>
                  
                           <h3 class="ml-10">¿Esta segudo de eliminar?</h3>
                         
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="red" small dark>No</v-btn>
                    <v-btn color="primary" small >Si</v-btn>
                </v-card-actions>
            </v-card>
    </v-dialog>

    
    <v-dialog v-model="modal_finalizar_rtm" width="350">
            <v-card>
                <v-toolbar color="grey darken-3" height="40"></v-toolbar>
                <v-card-text class="mt-3" >
                    <v-row>
                        <v-spacer></v-spacer>
                    <v-icon color="red" style="font-size:100px">mdi-cancel</v-icon>  
                    <v-spacer></v-spacer>
                    </v-row>
                    <v-row>
                  
                           <h3 class="ml-10">¿Estas ceguro de terminar el RTMs?</h3>
                         
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="red" small dark>No</v-btn>
                    <v-btn color="primary" small >Si</v-btn>
                </v-card-actions>
            </v-card>
    </v-dialog>


</v-container>
</template>

<script>
import ModalDocumentos from "@/components/ModalDocumentos";
import ModalSubirCertificados from "@/components/ModalSubirCertificados";
import ModalEnviarMensaje from "@/components/rtm-dia/ModalEnviarMensaje";
import ModalEditarCelular from "@/components/rtm-dia/ModalEditarCelular";
import ModalTipoRTM from "@/components/rtm-dia/ModalTipoRTM"
import RTMDiaService from '@/services/RTMDiaService.js'

export default {
    data: () => ({
        //  Modales
        modal_documentos: false,
        modal_enviar_mensaje: false,
        modal_enviar_certificado: false,
        modal_subir_certificados: false,
        modal_editar_celular: false,
        modal_tipo_rtm: false,
        modal_eliminar_registro: false,
        modal_finalizar_rtm: false,

        headers: [{
                text: "",
                value: "opciones",
                tooltip: "Opciones"
            },
            {
                text: "Nombre",
                value: "nombre",
                tooltip: "Nombre"
            },
            {
                text: "Cedula",
                value: "documento",
                tooltip: "Cedula"
            },
            {
                text: "Celular",
                value: "celular",
                tooltip: "Celular"
            },
            {
                text: "Placa",
                value: "placa",
                tooltip: "Placa"
            },
            {
                text: "Tipo",
                value: "tipo",
                tooltip: "Tipo"
            },
            {
                text: "Clase",
                value: "clase",
                tooltip: "Clase"
            },
            {
                text: "Vehiculo",
                value: "tipo_vehiculo",
                tooltip: "Vehiculo"
            },
            {
                text: "CertDigital",
                value: "certificado_digital",
                tooltip: "Certificado Digital",
            },
            {
                text: "Hora",
                value: "hora",
                tooltip: "Hora"
            },
            {
                text: "Fecha",
                value: "fecha",
                tooltip: "Fecha"
            },
            {
                text: "Notas",
                value: "notas",
                tooltip: "Notas"
            },
        ],

        info_datatable: [
        ],

        items_opciones: [{
                title: "Documentos",
                value: 1,
            },
            {
                title: "Enviar Mensaje",
                value: 2,
            },
            {
                title: "Enviar certificado",
                value: 3,
            },
            {
                title: "Subir certificados",
                value: 4,
            },
            {
                title: "Eliminar Registro",
                value: 5,
            },
            {
                title: "Finalizar RTM",
                value: 6,
            },
        ],
    }),
    components: {
        ModalDocumentos,
        ModalEnviarMensaje,
        ModalSubirCertificados,
        ModalEditarCelular,
        ModalTipoRTM
    },
    async created(){
        let encuestas = await RTMDiaService.getEncuestasHechas();
        
        let rtmDia = await RTMDiaService.getRTMDia();

        this.info_datatable = rtmDia.data;
        
        console.log(encuestas);
    },
    methods: {
        activarOpcionesMenu(item, id) {
            switch (id) {
                case 1:
                    this.modal_documentos = true;
                    break;
                case 2:
                    this.modal_enviar_mensaje = true;
                    break;
                case 3:
                    this.modal_enviar_certificado = true;
                    break;
                case 4:
                    this.modal_subir_certificados = true;
                    break;
                case 5:
                    this.modal_eliminar_registro = true
                    break;
                case 6:
                    this.modal_finalizar_rtm = true
                    break;
            }
        },
   
    },
};
</script>

<style></style>
