<template>
<v-container fluid>
    <v-card>
        <v-toolbar color="grey darken-3" dark height="40">
            <v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-title>Consultar Usuarios</v-toolbar-title>
                <v-spacer></v-spacer>
            </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
            <v-row>
                <v-col>
                    <v-btn block elevation="1" @click="
                activarClase('documento');
                filtro = 'documento';
              " :class="btn_document_active">Documento</v-btn>
                </v-col>
                <v-col>
                    <v-btn block elevation="1" @click="
                activarClase('placa');
                filtro = 'placa';
              " :class="btn_placa_active">Placa</v-btn>
                </v-col>
            </v-row>
            <v-row class="mt-3 mx-1">
                <v-col cols="12" sm="6" lg="4">
                    <v-text-field v-on:keyup.enter="consultar" type="number" outlined v-model="search" dense prepend-icon="mdi-text-box-search"></v-text-field>
                </v-col>
                <v-col cols="12" sm="2">
                    <v-btn :disabled="search.length < 1" color="primary" class="mt-sm-0 mt-n6" block @click="consultar()">Consultar</v-btn>
                </v-col>
            </v-row>
            <v-row v-if="info_datatable.length > 0">
                <v-col cols="10" sm="2" lg="2">
                    <label for="">Añadir Vehiculo al Usuario</label>
                </v-col>
                <v-col cols="2">
                    <v-btn fab small class="mb-2 mt-n2" elevation="1" color="primary" @click="añadirVehiculaAlUsuario()">
                        <v-icon>mdi-plus</v-icon>
                    </v-btn>
                    <v-spacer></v-spacer>
                </v-col>
            </v-row>
            <v-data-table :headers="headers" dense :items="info_datatable">
                <template v-slot:[`item.opciones`]="props">
                    <div class="text-center">
                        <v-menu offset-y>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon v-bind="attrs" v-on="on">mdi-dots-vertical</v-icon>
                            </template>
                            <v-list>
                                <v-list-item class="mx-4" v-for="(item, index) in items_opciones" :key="index" @click="activarItemMenu(props.item, item.value)">
                                    <v-list-item-title class="personalizar">
                                        {{ item.title }}
                                    </v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </div>
                </template>
            </v-data-table>
        </v-card-text>
    </v-card>

    <!-- Modal Documentos -->
    <v-dialog v-model="modal_documentos" width="1200">
        <ModalDocumentos @cerrar-modal="modal_documentos = false" />
    </v-dialog>

    <!-- Modal Formulario Fullscreen -->
    <template>
        <v-row justify="center">
            <v-dialog v-model="modal_registrar_rtm" fullscreen hide-overlay transition="dialog-bottom-transition">
                <ModalFormulario @cerrar-modal="modal_registrar_rtm = false" />
            </v-dialog>
        </v-row>
    </template>

    <v-dialog v-model="alerta_usuario_no_registrado" width="500">
        <v-card class="text-center">

            <v-icon color="red" class="mt-2" style="font-size:100px">mdi-cancel</v-icon>
            <h2 class="pt-5">Usuario no Registrado</h2>

            <v-card-text>
                El usuario consultado no se encuentra en la base de datos <br> <br>
                ¿Desea agregarlo?
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red" dark @click="alerta_usuario_no_registrado = false"> Cancelar

                </v-btn>
                <v-btn color="primary" @click="agregarUsuario()">Agregar

                </v-btn>
                <v-spacer></v-spacer>
            </v-card-actions>
        </v-card>
    </v-dialog>
</v-container>
</template>

<script>
import RegisterService from '@/services/RegisterService.js'
import ModalDocumentos from "@/components/ModalDocumentos";
import ModalFormulario from "@/components/ModalFormulario";
export default {
    data: () => ({
        alerta_usuario_no_registrado: false,
        tabs: "tab-1",
        btn_document_active: "btn-active",
        btn_placa_active: "",
        search: "",
        filtro: "documento",
        modal_documentos: false,
        modal_registrar_rtm: false,
        headers: [{
                text: "",
                value: "opciones"
            },
            {
                text: "Nombre",
                value: "nombre",
                tooltip: "Nombre"
            },
            {
                text: "Cedula",
                value: "documento",
                tooltip: "Cedula"
            },
            {
                text: "Celular",
                value: "celular",
                tooltip: "Celular"
            },
            {
                text: "Placa",
                value: "placa_rtm",
                tooltip: "Placa"
            },
            {
                text: "Tipo RTM",
                value: "rtm_tipo",
                tooltip: "Tipo RTM"
            },
            {
                text: "Fecha",
                value: "fecha",
                tooltip: "Fecha"
            },
        ],
        info_datatable: [],
        items_opciones: [{
                title: "Registrar RTM",
                value: 1,
            },
            {
                title: "Documento",
                value: 2,
            },
        ],
        searchTemp: ''
    }),
    async created(){
      
    },
    methods: {
        activarClase(filtro) {
            // Activa la clase btn-active cuando se da clic en el botón de placa o documento
            switch (filtro) {
                case "documento":
                    this.btn_document_active = "btn-active";
                    this.btn_placa_active = "";
                    break;
                case "placa":
                    this.btn_placa_active = "btn-active";
                    this.btn_document_active = "";
                    break;
            }
        },

        async consultar() {
            // La variable filtro almacena si se va a buscar documento o placa.

            // la variable search contiene el valor a buscar

            this.searchTemp = this.search
            if (this.filtro === "documento") {
                let {data} = await RegisterService.getUsers(this.search);
                console.log(data);
                this.info_datatable = data;
                if(data.length == 0){
                  this.abrirModal();
                }
            } 
        },
        añadirVehiculaAlUsuario() {
            this.$router.push({
                name:'registrar-clientes',
                query: { 
                    documento: this.searchTemp 
                }
            })
        },
        activarItemMenu(item, value) {
            switch (value) {
                case 1:
                    this.modal_registrar_rtm = true;
                    break;
                case 2:
                    this.modal_documentos = true;
                    break;
            }
        },
        agregarUsuario() {
            this.modal_enviar_certificado = false;

            this.$router.push({
                name:'registrar-clientes',
                query: { 
                    documento: this.search 
                }
            })

        },
        abrirModal() {
            if (this.search !== '' && this.search !== null && this.search !== undefined) {
                this.alerta_usuario_no_registrado = true
            }
        }
    },
    components: {
        ModalDocumentos,
        ModalFormulario,
    },
};
</script>

<style scoped>
.btn-active {
    background-color: #0777f8 !important;
    color: #fff !important;
}
</style>
