<template>
<v-container fluid>
    <v-card>
        <v-toolbar color="blue darken-1" dark>
            <v-spacer></v-spacer>
            <v-toolbar-title style="font-size: 20px">Encuesta de satisfacción</v-toolbar-title>
            <v-spacer></v-spacer>
        </v-toolbar>
        <v-card-text class="mt-2">
            <v-row>
                <v-spacer></v-spacer>
                <v-col cols="12" sm="6" md="5" lg="3">
                    <v-card color="green accent-1" elevation="1">
                        <v-card-text justify="center">
                            <v-row class="ml-2 ml-lg-3">
                                <v-spacer></v-spacer>
                                 <v-col cols="6">
                                <h3  style="display: inline">Código:</h3>
                                <p>{{ codigo }} </p>
                                <v-spacer></v-spacer>
                                <h3 style="display: inline">Versión:</h3>
                                 {{ version }}
                                <v-spacer></v-spacer>
                               
                                </v-col>
                                <v-col cols="6">
                                <h3 style="display: inline">Fecha:</h3>
                                 <p>{{ fecha }}</p>
                                <v-spacer></v-spacer>
                                <h3 style="display: inline">Página:</h3>
                                {{ pagina }}
                                <v-spacer></v-spacer>
                                </v-col>
                                <v-spacer></v-spacer>
                            </v-row>
                  
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-spacer></v-spacer>
            </v-row>

            <v-row class="mb-10 mt-4 mx-n2 ">
                <v-spacer></v-spacer>
               
                <!-- <h3>
                  
                </h3> -->

                    <v-alert
                    shaped
                    dark
                    color="info"
                    outlined
                    class="mb-n5"
                    >
                     Señor usuario, lo invitamos cordialmente a diligenciar la siguiente
                    encuesta. Su opinión es muy importante para nosotros.
                    </v-alert>

                <v-spacer></v-spacer>
            </v-row>

            <div v-for="item in preguntas" :key="item.id">
                <PreguntaEncuesta :ref="'pregunta'+item.id" @agregar-valor="agregarValor" :id="item.id" :pregunta="item.pregunta" :valor="item.valor" />
            </div>

            <v-row>
                <h3 for="" class="mt-3 ml-7 ml-lg-14">
                    6. Comentarios y sugerencias
                </h3>

                <v-col cols="12">
                    <v-textarea v-model="comentarios_sugerencias" class="mt-4" dense outlined></v-textarea>
                </v-col>

                <v-row class="mx-4 texto-justificado">
                    <h3 class="ml-5">Terminos y Condidiciones</h3>

                    <div v-if="!verTerminosYCondiciones">
                        <v-btn class="mt-n1" @click="activarTerminosYCondiciones()" text icon>
                            <v-icon style="font-size: 35px">mdi-chevron-down</v-icon>
                        </v-btn>
                    </div>

                    <div v-if="verTerminosYCondiciones">
                        <v-btn class="mt-n1" @click="activarTerminosYCondiciones()" text icon>
                            <v-icon style="font-size: 35px">mdi-chevron-up</v-icon>
                        </v-btn>
                    </div>

                    <v-alert v-if="verTerminosYCondiciones" border="top" colored-border type="info" elevation="2">
                        De conformidad con lo dispuesto por la 1581 de 2012 y el
                        Decreto1377 de 2013 de Protección de datos personales, o las que
                        la modifiquen o sustituyan, el titular presta su consentimiento
                        para que sus datos, consignados en el presente documento, sean
                        incorporados a una base de datos responsabilidad de
                        {{ nombre_empresa }} y tratados con la finalidad de realizar el
                        análisis respecto a la calidad del servicio y/o productos
                        contratados por usted, así como la remisión de información sobre
                        productos, servicios y actividades promovidos por la entidad. Le
                        informamos que sus datos serán tratados con base a los criterios
                        de seguridad definidos en la política de tratamiento de datos
                        desarrollada por {{ nombre_empresa }} y cuya información puede ser
                        consultada en la sala de espera de las instalaciones del CDA.
                        Recuerde que podrá ejercer los derechos de consulta y reclamo
                        sobre sus datos mediante escrito dirigido a {{ nombre_empresa }}
                    </v-alert>
                </v-row>

                <v-col cols="12" class="mt-8">
                    <v-spacer></v-spacer>
                    <v-btn v-if="disabledButon"  @click="enviarError()" color="success" block>Enviar</v-btn>
                    <v-btn v-else @click="enviarEncuesta()" color="success" block>Enviar</v-btn>

                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
    <Toast  />

</v-container>
</template>

<script>
import PreguntaEncuesta from "@/components/PreguntaEncuesta";
import EncuestaService from '@/services/EncuestaService.js'
import Toast from "primevue/toast";
import Swal from "sweetalert2";
export default {
    data: () => ({
        codigo: "FT-RT-02",
        verTerminosYCondiciones: false,
        version: "2",
        fecha: "2021-02-02",
        pagina: 1,
        nombre_empresa: "CERTIEXPRESS ESTADIO S.A.S.",
        comentarios_sugerencias: "",
        preguntas: [{
                id: 1,
                pregunta: "¿Cómo califica la atención y orientación del recepcionista de vehículos?",
                valor: "",
            },
            {
                id: 2,
                pregunta: "¿Cómo califica la atención y recepción de documentos y dinero en caja?",
                valor: "",
            },
            {
                id: 3,
                pregunta: "¿Cómo califica la atención brindada por el ingeniero durante la entrega de los resultados a su vehículo?",
                valor: "",
            },
            {
                id: 4,
                pregunta: "¿ Cómo califica la comodidad y estado de las instalaciones?",
                valor: "",
            },
            {
                id: 5,
                pregunta: " ¿Cómo califica La agilidad y calidad del servicio prestado?",
                valor: "",
            },
        ],
    }),
    components: {
        Toast,
        PreguntaEncuesta,
    },
    methods: {
        agregarValor(valor) {
            this.preguntas.forEach((element) => {
                if (element.id == valor[1]) {
                    element.valor = valor[0];
                    console.log(this.preguntas);
                    return;
                }
            });
        },
        async enviarEncuesta() {
            this.$store.commit("changeLoad");
            await EncuestaService.postRegistroEncuesta({
                pregunta1: this.preguntas[0].valor,
                pregunta2: this.preguntas[1].valor,
                pregunta3: this.preguntas[2].valor,
                pregunta4: this.preguntas[3].valor,
                pregunta5: this.preguntas[4].valor,
                Pregunta6: this.comentarios_sugerencias,
            })
            this.preguntas.forEach(element => {
                element.valor = '';

            })
            this.$refs.pregunta1[0].reset()
            this.$refs.pregunta2[0].reset()
            this.$refs.pregunta3[0].reset()
            this.$refs.pregunta4[0].reset()
            this.$refs.pregunta5[0].reset()
            this.comentarios_sugerencias = '';
            this.$store.commit("changeLoad");
            Swal.fire("Encuesta Finalizada!", "", "success").then(() => {
                window.location.href = 'http://certiexpressestadio.com/'
            });
        },

        activarTerminosYCondiciones() {
            this.verTerminosYCondiciones = !this.verTerminosYCondiciones;
        },
        enviarError() {
            this.preguntas.forEach(element => {
                if (element.valor == '') {
                    this.$toast.add({
                        severity: "info",
                        summary: `Falta la pregunta: ${element.id}` ,
                        life: 1000,
                    });
                }
            })
        }

    },
    computed: {
        disabledButon: function () {
            let bool = false;
            this.preguntas.forEach(element => {
                if (element.valor == '') {
                    bool = true;
                }
            })
            return bool;
        }
    }
};
</script>

<style>
.v-input--selection-controls__input .v-icon {
    font-size: 50px !important;
    width: 113%;
}

.texto-justificado {
    text-align: justify;
}
</style>
