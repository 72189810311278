import axios from "axios";

// Creamos la base de las peticiones, con los datos necesarios
const apiClient = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL + "registro",
  headers: {
      
  }
});



// Exportamos los métodos para las peticiones
export default {
  getUsers(params) {
    return apiClient.get("/obtener-por-documento/" + params);
  },
  postRegisterOrUpdateUser(params) {
    return apiClient.post("/add-client",params);
  },
};