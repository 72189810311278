<template>
  <v-container fluid>
    <v-card class="card-main mt-5" elevation="0">
      <v-row>
        <!-- LOGO -->
        <v-col cols="12" sm="9" class="pl-8">
          <img
            src="@/assets/logo-certi-express.png"
            contain
            width="170"
            style="max-width:200px"
            height="60"
          >
        </v-col>

        <!-- SLOGAN -->
        <v-col cols="12" sm="3" class="pt-4">
          <strong>CDA CERTI EXPRESS ESTADIO S.A.S</strong>
          <label
            style="
              display: block;
              font-size: 14px !important;
              font-weight: '900';
            "
            >HOJA DE VIDA EQUIPOS DE PISTA</label
          >
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-toolbar color="grey darken-3" dark height="40">
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-toolbar-title>Hoja de Vida Equipos de Pista</v-toolbar-title>
            <v-spacer></v-spacer>

            <!-- BOTON AGREAGAR -->
            <v-btn
              class="success"
              small
              @click="ObtenerCamposForm, seguirForm()"
              ><v-icon left>mdi-file-plus</v-icon> nueva hoja de vida
            </v-btn>
          </v-toolbar>
        </v-col>
      </v-row>
      <v-card-text>
        <!-- PARAMETROS -->
        <v-row>
          <!-- SELECCIONAR ESTADO -->
          <v-col cols="4" sm="3">
            <label for="stateQ">Estado</label>
            <v-autocomplete
              v-model="statusE"
              :items="estados"
              placeholder="Seleccione una opcion"
              item-text="status"
              item-value="id"
              @change="buscar"
              disable-lookup
              outlined
              dense
            >
            </v-autocomplete>
          </v-col>

          <!-- SELECCIONAR TIPO -->
          <v-col cols="4" sm="3">
            <label for="stateQ">Tipo</label>
            <v-autocomplete
              v-model="tipoE"
              :items="tiposSearch"
              placeholder="Seleccione una opcion"
              item-text="type"
              item-value="id"
              @change="buscar"
              disable-lookup
              outlined
              dense
            >
            </v-autocomplete>
          </v-col>

        
        </v-row>

        <!-- VENTANA EMERGENTE -->

        <v-dialog
          v-model="dialog"
          fullscreen
          hide-overlay
          persistent
          transition="dialog-bottom-transition"
        >
          <v-card>
            <!-- TITULO DIALOG  -->
            <v-toolbar color="primary white--text" height="60" elevation="1">
              <v-spacer></v-spacer>
              <span class="text-h5" v-if="!editar">Nueva Hoja de Vida</span>
              <span class="text-h5" v-else>Editar Hoja de Vida</span>
              <v-spacer></v-spacer>
              <v-btn
                v-if="!editar"
                class="white--text"
                @click="dialog = false"
                text
                icon
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-btn
                v-else
                class="white--text"
                @click="cerrarFormEditar"
                text
                icon
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>

            <!-- BARRA DE NAVEGACION -->
            <v-stepper v-model="tab" elevation="0" :non-linear="editar">
              <v-stepper-header class="elevation-1">
                <v-spacer></v-spacer>
                <v-stepper-step :complete="tab > 1" :editable="editar" step="1">
                  <span v-if="!editar">Crear Equipo</span>
                  <span v-else>Editar Equipo</span>
                </v-stepper-step>

                <v-divider></v-divider>

                <v-stepper-step :complete="tab > 2" :editable="editar" step="2">
                  <span v-if="!editar">Datos Equipo</span>
                  <span v-else>Editar Datos Equipo</span>
                </v-stepper-step>

                <v-divider v-if="!editar"></v-divider>

                <v-stepper-step v-if="!editar" :complete="tab > 3" step="3">
                  Finalizar
                </v-stepper-step>
                <v-spacer></v-spacer>
              </v-stepper-header>

              <v-stepper-items>
                <v-stepper-content step="1">
                  <Create-device
                    ref="formCreate"
                    :equipoEditado="modificarEquipo"
                    :creado="equiCreado"
                  />
                </v-stepper-content>

                <v-stepper-content style="padding: 0" step="2">
                  <CreateProvider ref="formProvider" />
                  <CreateVariable ref="formVariable" />
                  <CreateAditionalDevice ref="formAddDevice" />
                  <CreatePucharse
                    ref="formPucharse"
                    :editForm="finalizarEditar"
                    :finishForm="finalizarForm"
                  />
                </v-stepper-content>
              </v-stepper-items>
            </v-stepper>
          </v-card>
        </v-dialog>

        <!-- TABLA DATOS EQUIPO -->
        <v-row class="mt-n8">
          <v-spacer></v-spacer>
          <v-col cols="4">
            <v-text-field
              v-model="search"
              prepend-icon="mdi-magnify"
              dense
            ></v-text-field>
          </v-col>
        </v-row>
        <v-data-table
          :headers="headers"
          :items="equipos"
          :search="search"
          :items-per-page="5"
          class="elevation-0"
          :footer-props="{
            itemsPerPageText: 'N° Items',
            itemsPerPageAllText: 'Todos',
          }"
        >
          <template slot="no-data"> No se encontraron resultados </template>
          <template v-slot:item.opciones="props">
            <div class="text-center">
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on">mdi-dots-vertical</v-icon>
                </template>
                <v-list>
                  <v-list-item
                    class="mx-4"
                    v-for="(item, index) in items_opciones"
                    :key="index"
                    @click="opcionesMenu(props.item, item.value)"
                  >
                    <v-list-item-title class="personalizar">
                      <span v-if="(props.item.estado == 0) & (item.value == 2)"
                        >Activar</span
                      >
                      <span v-else>{{ item.title }}</span>
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </template>

          <template v-slot:item.estado="equipo_estado">
            <span justify-center v-if="equipo_estado.value == 1">Activo</span>
            <span justify-center v-else>Inactivo</span>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <v-dialog v-model="modal_pdf">
      <embed
        :src="rutaPdf"
        v-if="modal_pdf"
        type="application/pdf"
        width="400px"
        height="500px"
      />
    </v-dialog>
  </v-container>
</template>

<script>
import CreateDevice from "@/components/CreateDevice";
import CreateVariable from "@/components/CreateVariable";
import CreateAditionalDevice from "@/components/CreateAditionalDevice";
import CreatePucharse from "@/components/CreatePusharse";
import CreateProvider from "@/components/CreateProvider";

import { mapActions, mapMutations, mapState } from "vuex";

import { mapFields } from "vuex-map-fields";
import services from "@/services";

export default {
  name: "HomeCerti",
  data: () => ({
    search: "",
    modal_pdf: "",
    headers: [
      { text: "Nombre", value: "equipo" },
      { text: "Ubicacion", value: "ubicacion" },
      { text: "Estado", value: "estado" },
      { text: "Acciones", value: "opciones", align: "center" },
    ],
    items_opciones: [
      {
        title: "Visualizar Hoja",
        value: 1,
      },
      {
        title: "Editar",
        value: 2,
      },
      {
        title: "Desactivar",
        value: 3,
      },
      {
        title: "Registrar Mantenimiento",
        value: 4,
      },
    ],
    /**
     * Esta variable se encarga de mover las pestañas del stepper
     * @type {number}
     */
    tab: 1,
    dialog: false,
    editar: false,
    idEditar: "",
    mostrar: false,
    equipos: [],
    hideTable: false,
    tipoE: 3,
    tiposSearch: [
      { id: 3, type: "TODOS" },
      { id: 1, type: "PISTA" },
      { id: 2, type: "COMPUTO" },
    ],
    statusE: 3,
    estados: [
      { id: 3, status: "TODOS" },
      { id: 1, status: "ACTIVO" },
      { id: 0, status: "INACTIVO" },
    ],
  }),

  components: {
    CreateDevice,
    CreateVariable,
    CreateAditionalDevice,
    CreatePucharse,
    CreateProvider,
  },
  computed: {
    ...mapState({
      provider: "provider",
      pusharse: "pusharse",
      tiposE: "tiposE",
    }),
    ...mapFields({
      id: "device_id",
      variablesShow: "variablesShow",
      equiposAdShow: "equiposAdShow",
      date: "date",
      idProvedor: "provider.idProveedor",
    }),
    ...mapActions(["ObtenerCamposForm", "terminarHoja"]),
  },
  /** Funcion que trae todos los datos de los equipos */
  async mounted() {
    services.searchDevices({ status: 3, type: 3 }).then((response) => {
      for (var i = 0; i < response.data.length; i++) {
        this.equipos.push(response.data[i]);
      }
    });
  },
  methods: {
    ...mapMutations(["insertVariable"]),
    /**Funcion que se encarga de desactivar el estado de edicion en los componentes */
    cerrarFormEditar() {
      this.dialog = false;
      this.idEditar = "";
      this.tab = 1;
      this.editar = false;
      this.$refs.formCreate.edit = false;
      this.$refs.formVariable.edit = false;
      this.$refs.formAddDevice.edit = false;
      this.$refs.formPucharse.edit = false;
    },
    /** Funcion que trae los equipos filtrados por el usuario */
    buscar() {
      services
        .searchDevices({ status: this.statusE, type: this.tipoE })
        .then((response) => {
          this.equipos = [];
          for (var i = 0; i < response.data.length; i++) {
            this.equipos.push(response.data[i]);
          }
        });
    },

    /**
     * Funcion que evalua la opcion seleccionada y hace una accion
     *
     * @param {Object} item los datos del registro seleccionado
     * @param {number} value la opcion seleccionada
     * @returns {void}
     */
    opcionesMenu(item, value) {
      switch (value) {
        case 1:
          services.generarPdfEquipo({ id: item.id_equipo }).then((response) => {
            this.rutaPdf =
              "data:application/" +
              response.data.extension +
              ";base64," +
              response.data.file;
            this.modal_pdf = true;
          });
          break;
        case 2:
          this.dialog = true;
          this.editar = true;
          this.idEditar = item.id_equipo;
          /**
           * @type {array} contendra las variables que le pertenecen a dicho equipo
           */
          var variables = [];
          /**
           * @type {array} contendra los equipos adicionales que le pertenecen a dicho equipo
           */
          var addItems = [];

          services.dataEquip({ device_id: item.id_equipo }).then((response) => {
            this.$nextTick(() => {
              this.$refs.formPucharse.edit = true;
              this.$refs.formPucharse.editar(
                item.id_equipo,
                response.data["pucharse"][0]
              );

              this.idProvedor = response.data["provider"][0]["id_proveedor"];
              this.$refs.formProvider.completar(this.idProvedor);

              for (var i = 0; i < response.data["addItems"].length; i++) {
                addItems.push(response.data["addItems"][i]);
              }

              for (var i = 0; i < response.data["variables"].length; i++) {
                variables.push(response.data["variables"][i]);
              }
            });
          });
          this.$nextTick(() => {
            this.$refs.formCreate.edit = true;
            this.$refs.formCreate.editar(item);

            this.$refs.formVariable.edit = true;
            this.$refs.formVariable.editar(item.id_equipo, variables);

            this.$refs.formAddDevice.edit = true;
            this.$refs.formAddDevice.editar(item.id_equipo, addItems);
          });

          break;
        case 3:
          services
            .desactiveEquip({ id: item.id_equipo, status: item.estado })
            .then((response) => {
              if (!Number.isInteger(response.data)) {
                console.log("No se pudo habilitar o desactivar");
              }
            });
          for (var i = 0; i < this.equipos.length; i++) {
            if (this.equipos[i]["id_equipo"] == item.id_equipo) {
              if (this.equipos[i]["estado"] == 1) {
                this.equipos[i]["estado"] = 0;
              } else {
                this.equipos[i]["estado"] = 1;
              }
            }
          }
          break;

        case 4:
          this.$router.push({
            name: "mantenimiento",
            params: { id: item.id_equipo },
          });

        default:
          break;
      }
    },
    /**Funcion que pregunta al usario si desea continuar con el formulario cerrado*/
    seguirForm() {
      if (this.id != "") {
        this.$swal
          .fire({
            title: "¿Quieres seguir con el formulario anterior? ",
            icon: "warning",
            showCancelButton: true,
            allowEscapeKey: false,
            allowOutsideClick: false,
            allowEnterKey: false,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            cancelButtonText: "NO",
            confirmButtonText: "SI",
          })
          .then((result) => {
            if (result.isConfirmed) {
              this.tab = 2;
              this.dialog = true;
            } else {
              this.tab = 1;
              services.deleteEquip(this.id).then((response) => {
                if (response.data == true) {
                  this.id = "";
                  this.dialog = true;
                  this.variablesShow = [];
                  this.equiposAdShow = [];
                  this.LimpiarCampos();
                }
              });
            }
          });
      } else {
        this.dialog = true;
        this.$nextTick(() => {
          this.$refs.formProvider.resetForm();
          this.$refs.formPucharse.resetForm();
          this.$refs.formCreate.resetForm();
        });
      }
    },
    //Funcion que confirma que el equipo haya sido creado
    equiCreado(tab) {
      this.tab = tab;
    },
    modificarEquipo(equipoEditado) {
      for (var i = 0; i < this.equipos.length; i++) {
        if (equipoEditado["id_equipo"] == this.equipos[i]["id_equipo"]) {
          this.equipos.splice(i, 1, equipoEditado);
        }
      }
    },
    //Funcion que termina de crear el Equipo
    finalizarForm() {
      if (
        this.$refs.formProvider.validateForm() &&
        this.$refs.formPucharse.validateForm()
      ) {
        const dataPusharse = new FormData();

        dataPusharse.append("device_id", this.id);
        dataPusharse.append("date_compra", this.pusharse.date_compra);
        dataPusharse.append("date_instalacion", this.pusharse.date_instalacion);
        dataPusharse.append("date_garantia", this.pusharse.date_garantia);
        dataPusharse.append("aditionalFile", this.pusharse.aditionalFile);
        dataPusharse.append("idProvedor", this.provider.idProveedor);
        dataPusharse.append("nombreP", this.provider.nombreProveedor);

        services
          .finishFormDevice(dataPusharse)
          .then((response) => {
            if (response.data["resultado"] == true) {
              this.dialog = false;
              this.LimpiarCampos();
              this.id = "";
              this.tab = 1;
              this.variablesShow = [];
              this.equiposAdShow = [];
              this.equipos.push(response.data["equipo"][0]);
            } else {
              console.log("no se pudo crear");
            }
          })
          .then(() => {
            this.$swal.fire({
              title: "Hoja de Vida Creada",
              icon: "success",
              allowEscapeKey: false,
              allowOutsideClick: false,
              allowEnterKey: false,
              timer: 2000,
              position: "center",
              showConfirmButton: false,
              confirmButtonText: "Yes, delete it!",
            });
          });
      } else {
        this.$swal.fire({
          title: "Tienes que llenar todos los campos",
          icon: "error",
          timer: 2000,
          allowEscapeKey: false,
          allowOutsideClick: false,
          allowEnterKey: false,
          position: "center",
          showConfirmButton: false,
          confirmButtonText: "Yes, delete it!",
        });
      }
    },
    //Funcion que limpia todos los campos del formulario
    finalizarEditar() {
      if (
        this.$refs.formProvider.validateForm() &&
        this.$refs.formPucharse.validateForm()
      ) {
        const data = new FormData();

        data.append("device_id", this.idEditar);
        data.append("date_compra", this.pusharse.date_compra);
        data.append("date_instalacion", this.pusharse.date_instalacion);
        data.append("date_garantia", this.pusharse.date_garantia);
        data.append("aditionalFile", this.pusharse.aditionalFile);
        data.append("idProvedor", this.idProvedor);
        data.append("nombreP", this.provider.nombreProveedor);
        services.finishEdit(data).then((response) => {
          if (response.data == true) {
            this.$swal.fire({
              title: "Datos Actualizados",
              icon: "success",
              allowEscapeKey: false,
              allowOutsideClick: false,
              allowEnterKey: false,
              timer: 2000,
              position: "center",
              showConfirmButton: false,
              confirmButtonText: "Yes, delete it!",
            });
          } else {
            console.log("nada");
          }
        });
      } else {
        this.$swal.fire({
          title: "Tienes que llenar todos los campos",
          icon: "error",
          timer: 2000,
          allowEscapeKey: false,
          allowOutsideClick: false,
          allowEnterKey: false,
          position: "center",
          showConfirmButton: false,
          confirmButtonText: "Yes, delete it!",
        });
      }
    },
    LimpiarCampos() {
      this.$nextTick(() => {
        this.$refs.formProvider.resetForm();
        this.$refs.formPucharse.resetForm();
        this.$refs.formCreate.resetForm();
      });
    },
  },
};
</script>

<style>
.swal2-popup {
  font-family: Arial, Helvetica, sans-serif;
}
.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}
@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
