<template >

    <v-card class="mx-auto mt-4 pa-1" width="300" style=" text-align: center;" >
      <v-card-text class="text-center" :style="{'color': color} ">
        <p class="valor">{{ valor }} </p>   
      </v-card-text>
        <p class="parrafo-card">{{ titulo | capitalizeAll }} </p>
    </v-card>
    
</template>

<script>
export default {
  name: "Card",
  props: ["titulo", "valor", "data", "color"],
  data: () => ({
   
  }),
  watch:{
      
  }

};
</script>

<style scoped>

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100&display=swap');

.valor {
  font-size: 4.5rem;
  padding-top: 10px;
}

.parrafo-card{
    font-family: 'Roboto';
    font-size: 0.9rem;
}

.v-card__actions {
    padding: 0px;
    
}

</style>