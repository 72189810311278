<template>
       <v-card>
            <v-toolbar dark color="grey darken-3">
              <v-btn  icon dark @click="$emit('cerrar-modal')" >
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title>Settings</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-toolbar-items>
                <v-btn dark text @click="$emit('cerrar-modal')">
                  Guardar
                </v-btn>
              </v-toolbar-items>
            </v-toolbar>
                 <v-card-text class="mt-4">
                <v-row>
                    <v-col cols="12" sm="6"  lg="3">
                        <label> Primer Nombre</label>
                        <v-text-field outlined dense></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" lg="3">
                        <label> Segundo Nombre</label>
                        <v-text-field outlined dense></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" lg="3">
                           <label> Primer Apellido</label>
                        <v-text-field outlined dense></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" lg="3">
                        <label> Segundo Apellido</label>
                        <v-text-field outlined dense></v-text-field>
                    </v-col>
                      <v-col cols="12" sm="6" lg="3">
                        <label> Topo de persona</label>
                        <v-select outlined dense></v-select>
                    </v-col>
                      <v-col cols="12" sm="6" lg="3">
                        <label> Tipo de identificación</label>
                        <v-text-field outlined dense></v-text-field>
                    </v-col>
                      <v-col cols="12" sm="6" lg="3">
                        <label> Departamento </label>
                        <v-autocomplete outlined dense></v-autocomplete>
                    </v-col>
                         <v-col cols="12" sm="6" lg="3">
                        <label> Ciudad </label>
                        <v-autocomplete outlined dense></v-autocomplete>
                    </v-col>
                         <v-col cols="12" sm="6" lg="3">
                        <label> Correo</label>
                        <v-text-field outlined dense></v-text-field>
                    </v-col>
                         <v-col cols="12" sm="6" lg="3">
                        <label> Documento</label>
                        <v-text-field outlined dense></v-text-field>
                    </v-col>
                         <v-col cols="12" sm="6" lg="6">
                        <label> Dirección</label>
                        <v-text-field outlined dense></v-text-field>
                    </v-col>
                         <v-col cols="12" sm="6" lg="3">
                        <label>Celular del dueño</label>
                        <v-text-field outlined dense></v-text-field>
                    </v-col>
              
                      <v-col cols="12" sm="6" lg="3" >
                        <label> Celular de quien maneja el vehiculo</label>
                        <v-text-field append-icon="mdi-file" outlined dense></v-text-field>
                    </v-col>

                               <v-col cols="12" sm="6" lg="3">
                        <label>Placa</label>
                        <v-text-field outlined dense></v-text-field>
                    </v-col>                   
                </v-row>
                  <h2 class="ml-3">Tipo RTM</h2>
             
                <v-row class="mt-3">
                    <v-col cols="12" sm="3">
                        <v-radio-group v-model="radio_group_tipo_rtm">
                            <v-row class="mt-n10">
                                <v-radio
                                    class="ml-5"
                                    label="Oficial"
                                    color="blue"
                                    value="Oficial"
                                ></v-radio>
                                <v-radio
                                class="ml-5 mt-n2"
                                    label="Preventiva"
                                    color="blue"
                                    value="Preventiva"
                                ></v-radio>
                            </v-row>
            
                        </v-radio-group>
                    </v-col>
                </v-row>
                    <v-divider class=" mb-2"></v-divider>
                <h2 class="ml-3">Tipo RTM Oficial</h2>
            
                <v-row class="mt-3">
                    <v-col>
                        <v-radio-group v-model="radio_group_rtm_oficial">
                            <v-row class="mt-n10">
                                <v-radio
                                    class="ml-5"
                                    label="Nueva"
                                    color="blue"
                                    value="Nueva"
                                ></v-radio>
                                <v-radio
                                class="ml-5 mt-n2"
                                    label="Reinspección"
                                    color="blue"
                                    value="reinspeccion"
                                ></v-radio>
                            </v-row>
            
                        </v-radio-group>
                    </v-col>
                </v-row>
            
                 <v-divider class=" mb-2"></v-divider>
             <h2 class="ml-3">Tipo de vehiculo</h2>
           

                   <v-row class="mt-3">
                    <v-col>
                        <v-radio-group v-model="radio_group_tipo_vehiculo">
                            <v-row class="mt-n10">
                                <v-radio
                                    class="ml-5"
                                    label="Carro"
                                    color="blue"
                                    value="carro"
                                ></v-radio>
                                <v-radio
                                class="ml-5 mt-n2"
                                    label="Moto"
                                    color="blue"
                                    value="moto"
                                ></v-radio>
                            </v-row>
            
                        </v-radio-group>
                    </v-col>
                </v-row>
                
             <v-divider class=" mb-2"></v-divider>
               <h2 class="ml-3">Tarjeta de propiedad</h2>
             
            <v-row class="mt-5 ml-3">
                <v-btn color="primary" dark>TP Frontal</v-btn> <v-btn class="ml-2" color="red" dark>TP Trasera</v-btn>
                <v-col cols="12" class="mt-4">
                    <label for="">Notas del cliente</label>
                     <v-textarea
                        dense
                        outlined
                        color="black"
                   
                        ></v-textarea>
                </v-col>
            </v-row>

            <v-row>
                <v-col>
                    <label for="">Referido por:</label>
                    <v-select dense outlined></v-select>
                </v-col>
            </v-row>

       
            <h2 class="ml-3">Subir Documentos</h2>
       

            </v-card-text>
                <v-card-actions>
                           <v-btn @click="$emit('cerrar-modal')" class="ml-4" color="red" dark>Cancelar</v-btn>
                           <v-btn @click="$emit('cerrar-modal')" color="primary">Subir</v-btn>
            </v-card-actions>
          </v-card>
</template>

<script>
export default {
    data: () => ({
        radio_group_tipo_rtm: null,
        radio_group_tipo_vehiculo: null,
        radio_group_rtm_oficial: null,

    }),
}
</script>

<style>

</style>