<template>
  <div style="height: 100vh; background-color: #f4f4f4">
    <v-container class="d-flex justify-center align-center">
      <v-col class="mt-16">
        <v-card>
          <v-toolbar color="indigo darken-1" dark height="50">
            <v-spacer></v-spacer>
            <v-toolbar-title>Reportar PQRS Certiexpress</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-card-text>
            <h3 class="mb-n5">Información del trámite</h3>
            <v-form ref="formularioPqrs">
              <v-row class="mt-5">
                <!-- NOMBRES Y APELLIDOS -->
                <v-col cols="12" sm="6" md="6" lg="6" class="mb-n8">
                  <label
                    >Nombres y Apellidos<span class="campo-obligatorio"
                      >*</span
                    ></label
                  >
                  <v-text-field
                    :rules="[
                      (v) => !!v || 'Campo requerido',
                      (v) =>
                        /^[a-zA-Z ]*$/.test(v) ||
                        'Debe ser solo texto sin caracteres especiales',
                    ]"
                    v-model="nombre_completo"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <!-- FIN NOMBRES Y APELLIDOS -->

                <!-- DOCUMENTO -->
                <v-col cols="12" sm="3" md="3" lg="3" class="mb-n8">
                  <label
                    >Documento<span class="campo-obligatorio">*</span></label
                  >
                  <v-text-field
                    :rules="[
                      (v) => !!v || 'Campo requerido',
                      (v) =>
                        v >= 100000 || 'Tiene que tener al menos 6 digitos',
                    ]"
                    type="number"
                    v-model="documento"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <!-- FIN DOCUMENTO -->

                <!-- EXPEDIDO EN   -->
                <v-col cols="12" sm="3" md="3" lg="3" class="mb-n8">
                  <label
                    >Expedido en<span class="campo-obligatorio">*</span></label
                  >
                  <v-text-field
                    :rules="[
                      (v) => !!v || 'Campo requerido',
                      (v) =>
                        /^[a-zA-Z ]*$/.test(v) ||
                        'Debe ser solo texto sin caracteres especiales',
                    ]"
                    v-model="expedido_en"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <!-- FIN EXPEDIDO EN   -->
                
                <!-- DIRECCION -->
                <v-col cols="12" sm="6" md="6" lg="6" class="mb-n8">
                  <label
                    >Dirección<span class="campo-obligatorio">*</span></label
                  >
                  <v-text-field
                    :rules="[(v) => !!v || 'Campo requerido']"
                    v-model="direccion"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <!-- FIN DIRECCION -->

                <!-- CIUDAD -->
                <v-col cols="12" sm="3" md="3" lg="3" class="mb-n8">
                  <label>Ciudad<span class="campo-obligatorio">*</span></label>
                  <v-text-field
                    :rules="[
                      (v) => !!v || 'Campo requerido',
                      (v) =>
                        /^[a-zA-Z ]*$/.test(v) ||
                        'Debe ser solo texto sin caracteres especiales',
                    ]"
                    v-model="ciudad"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <!-- FIN CIUDAD -->

                <!-- TELEFONO -->
                <v-col cols="12" sm="3" md="3" lg="3" class="mb-n8">
                  <label
                    >Telefono<span class="campo-obligatorio">*</span></label
                  >
                  <v-text-field
                    v-model="telefono"
                    type="number"
                    :rules="[
                      (v) => !!v || 'Campo requerido',
                      (v) =>
                        v >= 1000000000 ||
                        'Tiene que tener al menos 10 digitos',
                    ]"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <!-- FIN TELEFONO -->

                <!-- EMAIL -->
                <v-col cols="12" sm="3" md="3" lg="3" class="mb-n8">
                  <label
                    >E-mail<span
                      v-if="medio_respuesta == 2"
                      class="campo-obligatorio"
                      >*</span
                    ></label
                  >
                  <v-text-field
                    v-model="correo"
                    :rules="
                      medio_respuesta == 2
                        ? [
                            (v) =>
                              /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(
                                v
                              ) || 'E-mail debe ser valido',
                          ]
                        : []
                    "
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <!-- FIN EMAIL -->

                <!-- TIPO DE TRAMITE -->
                <v-col cols="12" sm="3" md="3" lg="3" class="mb-n8">
                  <label
                    >Tipo de trámite<span class="campo-obligatorio"
                      >*</span
                    ></label
                  >
                  <v-select
                    :items="tipos_tramites"
                    :rules="[(v) => !!v || 'Campo requerido']"
                    item-text="tipo"
                    item-value="id"
                    v-model="tipo_tramite"
                    outlined
                    dense
                  ></v-select>
                </v-col>
                <!-- FIN TIPO DE TRAMITE -->

                <!-- MEDIO DE RESPUESTA -->
                <v-col cols="12" sm="3" md="3" lg="3">
                  <label
                    >Medio de Respuesta<span class="campo-obligatorio"
                      >*</span
                    ></label
                  >
                  <v-select
                    :items="medios_respuesta"
                    :rules="[(v) => !!v || 'Campo requerido']"
                    item-text="medio_respuesta"
                    item-value="id"
                    v-model="medio_respuesta"
                    outlined
                    dense
                  ></v-select>
                </v-col>
                <!-- FIN DE MEDIO DE RESPUESTA -->
              </v-row>

              <!-- DETALLES -->
              <h3>Detalles<span class="campo-obligatorio">*</span></h3>
              <v-textarea
                :rules="[(v) => !!v || 'Campo requerido']"
                class="mt-3 custom-placeholder-color"
                v-model="detalles"
                height="100"
                placeholder="En el presente campo describa detalladamente su solicitud, respondiendo las siguientes preguntas, ¿Cuál es el objeto de su petición, queja/reclamo o apelación? ¿Cuáles son los hechos en que se fundamenta la queja o apelación?"
                outlined
              >
              </v-textarea>
              <!-- FIN DE DETALLES -->

              <!-- EVIDENCIAS -->
              <h3 class="mb-1">Evidencias</h3>
              <v-row>
                <v-col cols="12" sm="3" md="3" lg="3">
                  <v-file-input
                    prepend-icon=""
                    prepend-inner-icon="mdi-upload"
                    v-model="evidencias"
                    ref="file"
                    placeholder="Seleccione un archivo"
                    @click:prepend-inner="
                      $refs.file.$el.querySelector('input').click()
                    "
                    multiple
                    outlined
                    chips
                    dense
                  ></v-file-input>
                </v-col>
                <!-- FIN EVIDENCIAS -->
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions class="mt-n4">
            <v-spacer></v-spacer>
            <!-- BOTON ENVIAR -->
            <v-btn
              color="success"
              width="100px"
              @click="enviarPqrs"
              :loading="enviando_reporte"
              :disabled="enviando_reporte"
              >Enviar</v-btn
            >
            <!-- FIN BOTON ENVIAR -->
          </v-card-actions>
        </v-card>
      </v-col>
    </v-container>
  </div>
</template>
<script>
import reportePqrsService from "../services/reportePqrsService";
export default {
  data: () => ({
    nombre_completo: "",
    documento: "",
    expedido_en: "",
    direccion: "",
    ciudad: null,
    telefono: null,
    correo: "",

    tipos_tramites: [],
    tipo_tramite: null,
    tipo_tramite_texto: "",

    medios_respuesta: [],
    medio_respuesta: null,
    medio_respuesta_texto: "",

    detalles: "",
    evidencias: [],

    /**Variable que activa el icono de carga del boton */
    enviando_reporte: false,
  }),
  watch: {
    /**Funcion que permite obtener el texto del tipo de tramite cada vez que se cambia*/
    tipo_tramite(newVal) {
      if (this.tipos_tramites.length > 0) {
        this.tipos_tramites.forEach((tipo_tramite) => {
          if (tipo_tramite.id == newVal) {
            this.tipo_tramite_texto = tipo_tramite.tipo;
          }
        });
      }
    },

    /**Funcion que permite obtener el texto del medio de respuesta cada vez que se cambia*/
    medio_respuesta(newVal) {
      if (this.medios_respuesta.length > 0) {
        this.medios_respuesta.forEach((medio_respuesta) => {
          if (medio_respuesta.id == newVal) {
            this.medio_respuesta_texto = medio_respuesta.medio_respuesta;
          }
        });
      }
    },
  },
  async created() {
    this.$store.state.stateLoad = true;

    //Se obtienen los tipos de pqrs o tipos de tramites
    await reportePqrsService.getTiposPqrs().then((response) => {
      this.tipos_tramites = response.data;
    });

    //Se obtienen los tipos medios de respuesta 
    await reportePqrsService.getMediosRespuesta().then((response) => {
      this.medios_respuesta = response.data;
    });

    this.$store.state.stateLoad = false;
  },
  methods: {
    /**Funcion que guarda la pqrs y reinica el formulario */
    async enviarPqrs() {
      if (this.$refs.formularioPqrs.validate()) {

        //Se crea un objeto tipo formdata ya que se requiere enviar(si es necesario) archivos al backend
        let data = new FormData();

        data.append("nombre_completo", this.nombre_completo);
        data.append("documento", this.documento);
        data.append("expedido_en", this.expedido_en);
        data.append("direccion", this.direccion);
        data.append("ciudad", this.ciudad);
        data.append("telefono", this.telefono);
        data.append("correo", this.correo.trim());
        data.append("tipo_tramite", this.tipo_tramite);
        data.append("tipo_tramite_texto", this.tipo_tramite_texto);
        data.append("medio_respuesta", this.medio_respuesta);
        data.append("medio_respuesta_texto", this.medio_respuesta_texto);
        data.append("detalles", this.detalles);

        //Se obtiene el numero de evidencias que anexo el usuario
        data.append("numero_evidencias", this.evidencias.length);

        //Se valida e itera sobre estas referencias para agregarlas al objeto formdata
        if (this.evidencias.length > 0) {
          for (let index = 0; index < this.evidencias.length; index++) {
            //Se agregan los archivos con el prefijo de evidencia_numeroIndex para ser identificadas en el backend de manera mas facil
            data.append("evidencia_" + index, this.evidencias[index]);
          }
        }

        //Se activa el icono de carga del boton
        this.enviando_reporte = true;
        await reportePqrsService
          .guardarPqrs(data)
          .then((response) => {
            //El backend retorna el id de la pqrs si se logro guardar todos los datos del formulario con exito
            if (response.data) {
              //Se reinician los datos del formulario
              this.reiniciarFormulario();

              //Se retorna un mensaje con el numero de radicado (id de la pqrs que se acabo de crear )
              this.$swal.fire({
                title: "Pqrs Enviada",
                text: "Su radicado es el N°" + response.data,
                icon: "success",
                allowEscapeKey: false,
                allowOutsideClick: false,
                allowEnterKey: false,
                position: "center",
                showConfirmButton: true,
                confirmButtonText: "Ok",
              });
            }
          })
          .catch((error) => {
            console.log(error);
            this.$swal.fire({
              title: "Error al enviar Pqrs",
              icon: "error",
              timer: 2000,
              allowEscapeKey: false,
              allowOutsideClick: false,
              allowEnterKey: false,
              position: "center",
              showConfirmButton: false,
              confirmButtonText: "Yes, delete it!",
            });
          });

        this.enviando_reporte = false;
      }
    },

    /**Funcion que reinicia los datos y validaciones del formulario */
    reiniciarFormulario() {
      this.nombre_completo = "";
      this.documento = "";
      this.expedido_en = "";
      this.direccion = "";
      this.ciudad = null;
      this.telefono = null;
      this.correo = "";

      this.tipo_tramite = null;
      this.tipo_tramite_texto = "";

      this.medio_respuesta = null;
      this.medio_respuesta_texto = "";

      this.detalles = "";
      this.evidencias = [];
      this.$refs.formularioPqrs.reset();
    },
  },
};
</script>
<style scoped>
.campo-obligatorio {
  color: red;
  margin-left: 2px;
}
</style>
