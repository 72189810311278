<template>
  <v-card>
    <v-toolbar container color="grey darken-3" dark height="40">
      <v-spacer></v-spacer>
      <v-toolbar-title>Correción</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-card-text >
      <v-row class="ml-6 mt-1">
        <h3>Tipo RTM</h3>
      </v-row>
      <v-row class="ml-6">
          
        <v-radio-group  class="mt-3 ">
          <v-radio value="malo" label="Oficial"></v-radio>
          <v-radio value="regular"  label="Preventiva"></v-radio>
          <v-radio value="bueno"  label="Convenio"></v-radio>
        </v-radio-group>
        
      </v-row >
      <v-row class="ml-6">
        <h3>Tipo de Vehiculo</h3>
      </v-row>
      <v-row class="ml-6">
          
        <v-radio-group  class="mt-6 ">
          <v-radio value="Carro" label="Carro"></v-radio>
          <v-radio value="Moto" label="Moto"></v-radio>
        </v-radio-group>
        
      </v-row>
      <v-row class="ml-6">
          
        <h3>Clase RTM</h3>
  
      </v-row>
      <v-row class="ml-6">
      
        <v-radio-group  class="mt-3 ">
          <v-radio value="malo" label="Nueva"></v-radio>
          <v-radio value="regular" label="Reinspección"></v-radio>
        </v-radio-group>

      </v-row>
    </v-card-text>
    <v-card-actions>
            <v-btn color="green" block dark>OK</v-btn>
         </v-card-actions>
  </v-card>
</template>

<script>
export default {};
</script>

<style></style>
